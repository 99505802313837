import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {TemplateType} from '@builder/sberlead-sbuild-templates';

import {TemplatesState, TemplatesResult} from './interface';
import {getTemplatesImagePreviewPath} from './utils';
import {useDomainStoreBase} from '@entities/domain';
import {DomainInfo} from '@shared/api';

export const useTemplatesStoreBase = create<TemplatesState>()(
  devtools(set => ({
    loading: false,
    templates: [],
    actions: {
      setTemplates: (templates: {[index: string]: TemplateType}) => {
        let res: TemplatesResult[] = [];
        Object.keys(templates).forEach(key => {
          const templateId = templates[key].id;
          if (templateId) {
            res.push({
              preview: getTemplatesImagePreviewPath(templateId),
              ...templates[key],
            });
          }
        });

        set(() => ({
          templates: res,
        }));
      },
    },
    effects: {
      create: async templateId => {
        let result: DomainInfo | null = null;
        set({loading: true});

        const {createDomain, createFromTemplate} = useDomainStoreBase.getState().effects;
        if (templateId) {
          const domain = await createFromTemplate(templateId);
          if (domain._tag === 'Right') {
            result = domain.right;
          }
        } else {
          const domain = await createDomain();
          if (domain._tag === 'Right') {
            result = domain.right;
          }
        }

        set({loading: false});
        return result;
      },
    },
  }))
);

export const useTemplatesStore = createSelectorHooks(useTemplatesStoreBase);
