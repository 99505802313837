import * as yup from 'yup';
import {
  DigitAndLetterAndSymbolsRegExp,
  DigitAndLetterAndSymbolsRegExpMessage,
  removeNbsp,
} from '@shared/utils/yup';

export const menuItemTitleSchema = yup
  .string()
  .transform(removeNbsp)
  .textValueMax(16, 'Максимальная длина 16 символов')
  .matchStripHtmlValue(DigitAndLetterAndSymbolsRegExp, DigitAndLetterAndSymbolsRegExpMessage);
