import {FC, useState} from 'react';
import {ImageBase} from '@shared/api/api';
import {GalleryItem} from './ui/galleryItem';
import tw from 'tailwind-styled-components';
import {PlusLgIcon} from '@shared/images/icons';
import {useToggle} from '@shared/hooks';
import {BlockEditorPortal} from '../blockEditorPortal';
import {SettingsContainer} from '../../ui/settingsContainer';
import {Image} from '../image';
import {DraggableImages} from '@shared/draggable/draggableImages';
import {httpClient} from '@shared/apiClient/lib/axios';

interface GalleryProps {
  images: ImageBase[];
  onChange: (images: ImageBase[]) => void;
}

const StyledPlaceholder = tw.div`
    cursor-pointer
    rounded
    flex
    items-center
    justify-center
    bg-gray-50
    w-[108px]
    h-[108px]
`;

const addImageIndex = -1;

export const Gallery: FC<GalleryProps> = ({images, onChange}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isSettingsOpen, toggleSettings] = useToggle();

  const onAddImage = () => {
    setActiveImageIndex(addImageIndex);
    toggleSettings();
  };

  const activeImage: ImageBase | undefined = images[activeImageIndex] ?? undefined;
  const imageHandler = (value: ImageBase | undefined) => {
    if (activeImageIndex === addImageIndex) {
      onChange([...images, value as ImageBase]);
      setActiveImageIndex(images.length);
      return;
    }
    if (!value) {
      onChange(images.filter((item, index) => activeImageIndex !== index));
      toggleSettings();
      return;
    }
    onChange(
      images.map((image, index) => {
        if (index === activeImageIndex) {
          return value;
        }
        return image;
      })
    );
  };
  const onRemoveGalleryItem = (index: number, imageId: string | undefined) => async () => {
    if (imageId) {
      const result = await httpClient.image.deleteImage(imageId).then(res => {
        const isSuccess = res._tag === 'Right';
        if (!isSuccess && 'status' in res.left) {
          return isSuccess || res.left.status === 404;
        }
        return isSuccess;
      });
      if (!result) return;
    }

    onChange(images.filter((item, i) => i !== index));
  };
  const onEditGalleryItem = (index: number) => () => {
    setActiveImageIndex(index);
    toggleSettings();
  };
  const onImageReorder = (items: unknown[]) => {
    onChange(items as ImageBase[]);
  };
  return (
    <>
      <div>
        <DraggableImages items={images} onChange={onImageReorder}>
          {[
            ...images.map((image, index) => (
              <GalleryItem
                key={image?.id ?? image?.url}
                image={image}
                onRemove={onRemoveGalleryItem(index, image?.id)}
                onEdit={onEditGalleryItem(index)}
              />
            )),
            <StyledPlaceholder key="no-key-item" onClick={onAddImage}>
              <PlusLgIcon className="text-indigo-500" />
            </StyledPlaceholder>,
          ]}
        </DraggableImages>
      </div>
      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer backHandler={toggleSettings} backLabel="Обратно к фотогалерее">
          <Image onChange={imageHandler} imageBase={activeImage} hasAltText />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
