import React, {FC} from 'react';
import {ImageBase} from '@shared/api';
import {useLoadImage} from '../../image/hooks';
import {FoldILED} from 'iled';
import {Spinner, SpinnerContainer} from '@shared/ui/spinner';
import placeholder from '@shared/images/placeholder.png';
import {fromOption} from '@shared/lib/utils';
import {GalleryImagePreview} from './galleryImagePreview';

interface GalleryItemProps {
  image: ImageBase;
  onEdit: () => void;
  onRemove: () => void;
}

export const GalleryItem: FC<GalleryItemProps> = ({image, onEdit, onRemove}) => {
  const loadedImage = useLoadImage(image);
  return (
    <FoldILED
      state={loadedImage}
      onInitial={() => null}
      onLoading={() => (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      onError={() => (
        <GalleryImagePreview image={placeholder} onRemove={onRemove} onEdit={onEdit} />
      )}
      onData={data => {
        const imageObject = fromOption(data);
        const imageStr = imageObject?.image?.url ?? placeholder;
        return <GalleryImagePreview image={imageStr} onRemove={onRemove} onEdit={onEdit} />;
      }}
    />
  );
};
