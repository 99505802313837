import React, {FC} from 'react';
import {ElementComponent} from '../../model/interface';
import {Controller} from 'react-hook-form';
import {PointsIconStyle} from '../../components/points/pointsIconStyle';
import {Points} from '../../components/points';
import {Field} from '../../ui/field';

export const PointsElement: FC<ElementComponent> = ({control, configuration}) => {
  return (
    <Field
      node={
        <>
          <Controller
            name="pointsIconStyle"
            control={control}
            render={({field: {onChange, value}}) => (
              <PointsIconStyle onChange={onChange} pointsIconStyle={value} />
            )}
          />
          <Controller
            name="points"
            control={control}
            render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
              <Points invalid={invalid} error={error?.message} points={value} onChange={onChange} />
            )}
          />
        </>
      }
      configuration={configuration}
      field="points"
    />
  );
};
