import {ChangeEvent, forwardRef} from 'react';

import {InputProps} from './interface';
import {InputContainer} from './ui/inputContainer';
import {Label} from './ui/label';
import {CustomInput} from './ui/input';
import {InputWrapper} from './ui/inputWrapper';
import {IconWrapper} from './ui/iconWrapper';
import {Caption} from '../caption';

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    value,
    onChange,
    placeholder,
    className,
    label,
    caption,
    name,
    errorMessage,
    type = 'string',
    invalid = false,
    icon,
    InputContainerProps,
    startIcon,
    successMessage,
    ...rest
  } = props;
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <InputContainer {...InputContainerProps}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        {startIcon && <IconWrapper placement="left">{startIcon}</IconWrapper>}
        <CustomInput
          id={name}
          startIcon={Boolean(startIcon)}
          onChange={changeHandler}
          value={value}
          className={className}
          placeholder={placeholder}
          ref={ref}
          invalid={invalid}
          type={type}
          {...rest}
        />
        {icon && <IconWrapper placement="right">{icon}</IconWrapper>}
      </InputWrapper>
      {caption && <Caption>{caption}</Caption>}
      {invalid && errorMessage && <Caption color="text-red-500">{errorMessage}</Caption>}
      {!invalid && successMessage && <Caption color="text-emerald-500">{successMessage}</Caption>}
    </InputContainer>
  );
});
