import * as yup from 'yup';
import {LinkInfo} from '@shared/api';
import {buttonTitleSchema} from '../../elements/textElement/buttonTitleSchema';

export const linkInfoConfigurationSchema = yup.object({
  title: buttonTitleSchema.required('Поле обязательно для заполнения'),
  href: yup.string().when('linkType', {
    is: 'EXTERNAL',
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  blockId: yup.string().when('linkType', {
    is: 'ANCHOR',
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  pageId: yup.string().when('linkType', {
    is: 'INTERNAL',
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  linkType: yup.string<LinkInfo['linkType']>().required(),
});
