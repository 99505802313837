import tw from 'tailwind-styled-components';

export const IconWrapper = tw.div`
    absolute
    inset-y-0
    right-0
    flex
    items-center
    pointer
    pr-3
`;
