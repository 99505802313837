import {useEffect} from 'react';
import {blockList, templatesList} from '@builder/sberlead-sbuild-templates';

import {usePageListStore} from '@entities/page';
import {useTemplatesStore} from '@entities/templates';
import {useProfileStore} from '@entities/profile';

export const useTemplatesLibrary = () => {
  const isHtmlBlockEnabled = useProfileStore.useHtmlFeatureEnabled();
  const {setBlocks} = usePageListStore.useActions();
  const {setTemplates} = useTemplatesStore.useActions();

  useEffect(() => {
    let blocks = Object.values(blockList);
    if (!isHtmlBlockEnabled) {
      blocks = blocks.filter(block => block.group !== 'HTML');
    }
    setBlocks(blocks);
  }, [setBlocks, isHtmlBlockEnabled, blockList]);

  useEffect(() => {
    setTemplates(templatesList);
  }, [templatesList]);
};
