import {BlockInfo} from '@shared/api';
import {isFieldShown, isFieldShownNew} from '../lib/utils';
import {usePageListStore} from '../../page/store/pageListStore';
import {FC} from 'react';

interface Props<T> {
  node: JSX.Element | null;
  configuration: T;
  field: string;
}

export const Field = <T extends {group: BlockInfo['group']; type: BlockInfo['type']}>(
  props: Props<T>
) => {
  const blocksMap = usePageListStore.useBlocksMap();

  const isShowField = isFieldShown(blocksMap, props.configuration)(props.field);

  if (isShowField) {
    return props.node;
  }

  return null;
};

interface FieldByTypeAndGroupProps {
  blockGroup: BlockInfo['group'];
  blockType: BlockInfo['type'];
  field: string;
  node: JSX.Element | null;
}

export const FieldByTypeAndGroup: FC<FieldByTypeAndGroupProps> = ({
  blockType,
  blockGroup,
  field,
  node,
}) => {
  const blocksMap = usePageListStore.useBlocksMap();

  const isShowField = isFieldShownNew(blockGroup, blockType, blocksMap)(field);

  if (isShowField) {
    return node;
  }

  return null;
};
