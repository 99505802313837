import {FC} from 'react';
import {StyledLink} from '@shared/ui/link';
import {ServiceCardObj} from '../model/serviceCard';

export const ServiceCard: FC<ServiceCardObj> = ({
  imageHref,
  textHref,
  title,
  href,
  payment,
  description,
}) => {
  return (
    <div className="flex justify-center my-4 cursor-pointer p-4">
      <div className="border border-solid border-gray-200 sm:max-w-xs">
        <img src={imageHref} />
        <div className="flex justify-between items-center p-5 flex-wrap">
          <h4>{title}</h4>
          <div className="flex gap-2 rounded-full bg-gray-50 text-gray-500 text-sm py-1 px-2 items-center">
            {payment}
          </div>
          <div className="mt-4">
            <span className="text-gray-500 text-sm">{description}</span>
          </div>
          <div className="mt-8">
            <StyledLink href={href}>{textHref}</StyledLink>
          </div>
        </div>
      </div>
    </div>
  );
};
