import React, {useState} from 'react';

import {BlockEditorPortal} from '../blockEditorPortal';

import {DraggableContainer} from '@shared/draggable';
import {LinkInfo} from '@shared/api';
import {useToggle} from '@shared/hooks';
import {Caption} from '@shared/ui';
import {Button} from '@shared/ui';
import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';

import {LinkInfoConfiguration} from '../../configuration/linkInfoConfiguration';
import {DraggableBullet} from '../../ui/item';
import {SettingsContainer} from '../../ui/settingsContainer';
import {MenuProps} from './model';

export const Menu: React.FC<MenuProps> = ({items, onChange, invalid, error}) => {
  const menuItems = items ?? [];
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [activeLinkIndex, setActiveLinkIndex] = useState<number>(0);

  const removeHandler = (itemIndex: number) => () =>
    onChange(menuItems.filter((item, index) => index !== itemIndex));

  const onReorderHandler = (reorderedItems: unknown[]) => {
    onChange(reorderedItems as LinkInfo[]);
  };
  const onAddMenu = () => {
    onChange([
      ...menuItems,
      {blockId: '', href: '', linkType: 'EXTERNAL', pageId: '', title: 'Новый пункт'},
    ]);
    setActiveLinkIndex(menuItems.length);
    toggleSettings();
  };

  const settingsHandler = (item: LinkInfo, index: number) => () => {
    toggleSettings();
    setActiveLinkIndex(index);
  };

  const onSubmit = (link: LinkInfo) => {
    onChange(
      menuItems.map((item, index) => {
        if (index === activeLinkIndex) {
          return link;
        }
        return item;
      })
    );
  };
  const activeLink = menuItems[activeLinkIndex] || {};

  return (
    <>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
      <DraggableContainer items={menuItems} onChange={onReorderHandler}>
        {menuItems.map((item, index) => (
          <DraggableBullet
            data-testid={`menu-bullet-${index}`}
            key={index.toString()}
            active={false}
            main={false}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item, index)}
            onRemove={removeHandler(index)}
          >
            {item.title}
          </DraggableBullet>
        ))}
      </DraggableContainer>
      <Button onClick={onAddMenu} className="w-full" color="gray" variant="ghost" size="small">
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить пункт меню
      </Button>

      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer backLabel="Обратно к пунктам меню" backHandler={() => toggleSettings()}>
          <LinkInfoConfiguration onSubmit={onSubmit} link={activeLink} />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
