import React, {FC} from 'react';
import {useToggle} from '../../../shared/hooks';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Button, Input} from '../../../shared/ui';
import {EditableProps, TSaveWebhookForm} from '../model/interface';
import {schema} from '../lib/schema';

export const WebhookEditable: FC<EditableProps> = ({item, onChange: onChangeHandler, editMode}) => {
  const [loading, toggleLoading] = useToggle();

  const {handleSubmit, control} = useForm<TSaveWebhookForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: item.name,
      url: item.url,
    },
  });

  const onSubmit = async (data: TSaveWebhookForm) => {
    toggleLoading();
    await onChangeHandler({...item, ...data}, editMode);
    toggleLoading();
  };

  const buttonName = editMode === 'create' ? 'Добавить' : 'Сохранить';

  return (
    <div>
      <Controller
        name="name"
        control={control}
        rules={{required: true}}
        render={({field: {onChange, value, ref}, fieldState: {error, invalid}}) => (
          <Input
            name="webhook-name"
            placeholder="Придумайте название"
            value={value}
            onChange={onChange}
            ref={ref}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="url"
        control={control}
        rules={{required: true}}
        render={({field: {onChange, value, ref}, fieldState: {error, invalid}}) => (
          <Input
            name="webhook-url"
            placeholder="Вставьте ссылку вида: https://app.sberсrm.com/"
            value={value}
            onChange={onChange}
            ref={ref}
            errorMessage={error?.message}
            invalid={invalid}
          />
        )}
      />
      <div className="text-gray-500 text-xs mb-2">
        Внесенные изменения коснутся всех форм, к которым подключен данный webhook. При изменении
        адреса Webhook данные с формы могут перестать отправляться. После внесения изменений
        отправьте тестовые данные для проверки корректности отправки.
      </div>
      <Button disabled={loading} variant="soft" color="indigo" onClick={handleSubmit(onSubmit)}>
        {buttonName}
      </Button>
    </div>
  );
};
