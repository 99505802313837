import * as yup from 'yup';

export const ERROR_MESSAGES = {
  required: 'Заполните это поле',
  min: 'Поле должно содержать больше ${min} символов',
  max: 'Поле должно содержать меньше ${max} символов',
  minPassword: 'Пароль должно содержать больше ${min} символов',
  maxPassword: 'Пароль должно содержать меньше ${max} символов',
  oneOf: 'Поля не совпадают',
  passwordRequirement: 'Пароль должен содержать строчную и заглавную буквы, цифру и спецсимвол',
  email: 'Некорректный адрес электронной почты',
  name: 'Недопустимые символы в имени',
  settingName: 'Имя может содержать только латинские буквы, кириллицу, цифры, пробел и "-".',
  settingSubdomain: 'Поддомен может содержать только латинские буквы, цифры и "-".',
  domain: 'Недопустимые символы в имени домена',
  settingDuplicateSubdomain: 'Такое имя уже занято. Придумайте другое',
  ownDomainAlreadyExists: 'Данный домен не доступен для регистрации',
  phone: 'Корректно введите номер телефона',
  date: 'Некорректная дата',
  passportNumberSeries: 'Некорректные данные',
};

export const SUCCESS_MESSAGES = {
  domainAvailableForRegistration: 'Данный домен доступен для регистрации',
};

yup.setLocale({
  mixed: {
    required: ERROR_MESSAGES.required,
  },
  date: {
    min: 'tes1',
    max: 'tes',
  },
});
