import {AuthResponse} from '../../../shared/api';
import {create} from 'zustand';
import {createJSONStorage, persist, devtools} from 'zustand/middleware';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';

interface AuthStore {
  authInfo: AuthResponse | null;
  setAuthInfo: (auth: AuthResponse) => void;
  clearAuthInfo: () => void;
}

const useAuthStoreBase = create<AuthStore>()(
  devtools(
    persist(
      set => ({
        authInfo: null,
        setAuthInfo: (auth: AuthResponse) => set({authInfo: auth}),
        clearAuthInfo: () => set({authInfo: null}),
      }),
      {
        name: 'auth-store',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

export const useAuthStore = createSelectorHooks(useAuthStoreBase);
