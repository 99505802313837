import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {
  AnalyticsFormData,
  Cookie,
  EditorState,
  GeneralFormData,
  SEOFormData,
  SocialMediaFormData,
} from '../model/interface';
import {createRef} from 'react';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';

export const useEditorStoreBase = create<EditorState>()(
  devtools(set => ({
    addBlockPosition: null,
    isBlockEditorPortalRender: false,
    blockEditorRef: createRef<HTMLDivElement>(),
    blockEditorElement: null,
    seo: {description: ''},
    cookie: {
      policyFile: 'undefined',
      policyPageUrl: 'https://sberlead.ru/cookiepolicy',
    },
    analytics: {code: ''},
    socialMedia: {
      description: '',
      title: '',
      imageId: '',
    },
    general: {
      name: '',
      subdomain: '',
      faviconId: undefined,
    },
    actions: {
      setAddBlockPosition: value =>
        set(() => ({
          addBlockPosition: value,
        })),
      setBlockEditorPortalRender: (value: boolean) =>
        set(() => ({
          isBlockEditorPortalRender: value,
        })),
      setBlockEditorElement: (element: HTMLDivElement) =>
        set(() => ({
          blockEditorElement: element,
        })),
      setSettingsSeo: (seo: SEOFormData) =>
        set(() => ({
          seo,
        })),
      setSettingsGeneral: (general: GeneralFormData) =>
        set(() => ({
          general,
        })),
      removeSettingsSeo: () =>
        set(() => ({
          seo: {description: ''},
        })),

      setSettingsCookie: (cookie: Cookie) =>
        set(() => ({
          cookie,
        })),
      removeSettingsCookie: () =>
        set(() => ({
          cookie: {
            policyFile: 'undefined',
            policyPageUrl: 'https://sberlead.ru/cookiepolicy',
          },
        })),

      setSettingsAnalytics: (analytics: AnalyticsFormData) =>
        set(() => ({
          analytics,
        })),
      removeSettingsAnalytics: () =>
        set(() => ({
          analytics: {code: ''},
        })),

      setSettingsSocialMedia: (socialMedia: SocialMediaFormData) =>
        set(() => ({
          socialMedia,
        })),
      removeSettingsSocialMedia: () =>
        set(() => ({
          socialMedia: {
            description: '',
            title: '',
            imageId: '',
          },
        })),
    },
  }))
);

export const useEditorStore = createSelectorHooks(useEditorStoreBase);
