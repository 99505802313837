import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
  DroppableProps as DroppablePropsDnd,
  DropResult,
} from '@hello-pangea/dnd';
import {useDraggableInPortal} from '../hooks/useDraggableInPortal';

// a little function to help us with reordering the result
const reorder = (list: unknown[], startIndex: number, endIndex: number): unknown[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface DraggableContainerProps {
  items: unknown[];
  onChange: (items: unknown[]) => void;
  children: JSX.Element[];
  DragAndDropProps?: DragDropContextProps;
  DroppableProps?: Partial<DroppablePropsDnd>;
  className?: string;
}

export const DraggableContainer: React.FC<DraggableContainerProps> = ({
  items,
  onChange,
  children,
  DragAndDropProps,
  DroppableProps,
  className,
}) => {
  const onItemsOrderChange = (value: unknown[]) => onChange(value);
  const renderDraggable = useDraggableInPortal();

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const nextState: unknown[] = reorder(items, result.source.index, result.destination.index);

    onItemsOrderChange(nextState);
  };

  return (
    <DragDropContext {...DragAndDropProps} onDragEnd={onDragEnd}>
      <Droppable {...DroppableProps} droppableId="droppable">
        {droppableProvided => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            className={className}
          >
            {children.map((element, index) => {
              return (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {renderDraggable(provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {element}
                    </div>
                  ))}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
