import React, {useState} from 'react';

import {BlockEditorPortal} from '../blockEditorPortal';

import {DraggableContainer} from '@shared/draggable';
import {Caption, Button} from '@shared/ui';
import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';
import {useToggle} from '@shared/hooks';
import {CardInfo} from '@shared/api';

import {CardInfoConfiguration} from '../../configuration/cardInfoConfiguration/cardInfoConfiguration';
import {SettingsContainer} from '../../ui/settingsContainer';
import {DraggableBullet} from '../../ui/item';

interface CardsProps {
  items: CardInfo[] | undefined;
  onChange: (items: CardInfo[]) => void;
  invalid: boolean;
  error?: string;
}

export const Cards: React.FC<CardsProps> = ({items = [], onChange, error, invalid}) => {
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0);

  const removeHandler = (itemIndex: number) => () =>
    onChange(items.filter((item, index) => index !== itemIndex));

  const onReorderHandler = (reorderedItems: unknown[]) => {
    onChange(reorderedItems as CardInfo[]);
  };
  const onAddCard = () => {
    onChange([
      ...items,
      {
        button: {
          buttonClass: 'PRIMARY',
          href: '#',
          linkType: 'EXTERNAL',
          title: 'Заголовок кнопки',
        },
        description: '',
        image: undefined,
        title: '',
      },
    ]);
    setActiveCardIndex(items.length);
    toggleSettings();
  };

  const settingsHandler = (item: CardInfo, index: number) => () => {
    toggleSettings();
    setActiveCardIndex(index);
  };

  const onChangeHandler = (link: CardInfo) => {
    onChange(
      items.map((item, index) => {
        if (index === activeCardIndex) {
          return link;
        }
        return item;
      })
    );
  };

  const activeCard = items[activeCardIndex] || {};

  return (
    <>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
      <DraggableContainer items={items} onChange={onReorderHandler}>
        {items.map((item, index) => (
          <DraggableBullet
            data-testid={`card-bullet-${index}`}
            key={index.toString()}
            active={false}
            main={false}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item, index)}
            onRemove={removeHandler(index)}
          >
            {item.title}
          </DraggableBullet>
        ))}
      </DraggableContainer>
      <Button onClick={onAddCard} className="w-full" color="gray" variant="ghost" size="small">
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить карточку
      </Button>
      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer backLabel="Обратно к карточкам" backHandler={() => toggleSettings()}>
          <CardInfoConfiguration item={activeCard} onSubmit={onChangeHandler} />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
