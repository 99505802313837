import tw from 'tailwind-styled-components';

interface LabelProps {
  disabled?: boolean;
}

export const Label = tw.label<LabelProps>`
    font-mulish-semibold
    text-sm
    w-full
    block
    mb-2
    ${props => (props.disabled ? 'text-gray-500' : 'text-gray-800')}
`;
