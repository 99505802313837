import {useState} from 'react';

type Toggle = () => void;
type SetTrue = () => void;
type SetFalse = () => void;

export const useToggle = (): [boolean, Toggle, SetTrue, SetFalse] => {
  const [isOpen, setIsOpen] = useState(false);

  const setClose = () => setIsOpen(false);
  const setOpen = () => setIsOpen(true);
  const toggle = () => setIsOpen(state => !state);

  return [isOpen, toggle, setOpen, setClose];
};
