import {GripVerticalIcon, HouseIcon, GearIcon, Trash3Icon} from '@shared/images/icons';
import {FC} from 'react';
import {StyledNavPill} from '@shared/navPill';
import {ItemProps} from './model';
import {Content, EndIconContainer, StartIconContainer, TextContainer} from './ui';

export const DraggableBullet: FC<ItemProps> = ({
  onRemove,
  onSettings,
  onClick,
  active,
  item,
  main,
  children,
  ...buttonProps
}) => {
  return (
    <StyledNavPill
      {...buttonProps}
      className="my-1 mx-0 w-full"
      onClick={() => onClick(item)}
      active={active}
    >
      <Content>
        <StartIconContainer>
          <GripVerticalIcon className="w-5 h-5 mr-2 -ml-1 text-gray-800" />
          {main ?? <HouseIcon className="w-5 h-5 mr-2 -ml-1" />}
        </StartIconContainer>
        <TextContainer dangerouslySetInnerHTML={{__html: children ?? ''}} />
        <EndIconContainer>
          <GearIcon onClick={() => onSettings(item)} className="w-5 h-5 ml-3 -mr-1 text-gray-800" />
          <Trash3Icon onClick={() => onRemove(item)} className="w-5 h-5 ml-3 -mr-1 text-gray-800" />
        </EndIconContainer>
      </Content>
    </StyledNavPill>
  );
};
