import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {SnackbarState} from './interface';
import MD5 from 'crypto-js/md5';

export const useSnackbarStoreBase = create<SnackbarState>()(
  devtools((set, get) => ({
    queue: [],
    dequeue: id => {
      set({
        queue: get().queue.filter(e => e.id !== id),
      });
    },
    enqueue: message => {
      const id = MD5(Date.now().toString()).toString();
      set({queue: [...get().queue, {...message, id}]});
    },
  }))
);

export const useSnackbarStore = createSelectorHooks(useSnackbarStoreBase);
