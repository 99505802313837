import * as yup from 'yup';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {cardInfoSchema} from '../../configuration/cardInfoConfiguration/schema';

export const cardsBlockConfigurationContentSchema = yup.object({
  title: titleSchema.optional().nullable().default(undefined),
  description: descriptionSchema.optional().nullable().default(undefined),
  cards: yup.array(cardInfoSchema).max(9, 'Максимальное количество карточек - 9'),
  button: buttonInfoConfigurationSchema.optional().nullable().default(undefined),
});
