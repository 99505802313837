import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {BlockConfigurationContentProps} from '../../model/interface';

import {TextElement} from '../../elements/textElement';
import {ButtonElement} from '../../elements/buttonElement';
import {CardsElement} from '../../elements/cardsElement';
import {SeoElement} from '../../elements/seoElement';

import {cardsBlockConfigurationContentSchema} from './schema';

import {CardsBlockConfiguration, TagType} from '@shared/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';

export const CardsBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<CardsBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<CardsBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue,
  } = useForm<Partial<CardsBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(cardsBlockConfigurationContentSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const onSubtitleChange = (value: TagType) => {
    const items = configuration.cards.map(card => ({...card, titleTag: value}));
    setValue('cards', items);
  };

  return (
    <>
      <TextElement
        control={control}
        configuration={configuration}
        label="Заголовок"
        fieldName="title"
      />
      <TextElement
        control={control}
        configuration={configuration}
        label="Описание"
        fieldName="description"
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors.cards)}
          summary="Карточки"
          details={<CardsElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.button)}
          summary="Кнопка"
          details={
            <ButtonElement fieldName="button" control={control} configuration={configuration} />
          }
        />
        <AccordionItem
          invalid={Boolean(errors.titleTag)}
          summary="SEO"
          details={
            <SeoElement
              control={control}
              configuration={configuration}
              onSubtitleChange={onSubtitleChange}
              itemsName="cards"
              hasSubtitle
            />
          }
        />
      </Accordion>
    </>
  );
};
