import {ReactComponent as EditIcon} from '@shared/images/icons/edit.svg';
import {ReactComponent as LinkIcon} from '@shared/images/icons/link.svg';
import {StyledRoutLink} from '@shared/ui/link';

import {Props} from './interface';
import React from 'react';
import {CardContainer} from '@shared/card';

export const Card: React.FC<Props> = ({
  actionBlock,
  titleBlock = '',
  constructorUrl = '',
  domain,
}) => {
  return (
    <CardContainer>
      <div className="absolute top-3 right-3">{actionBlock}</div>
      {titleBlock}
      <StyledRoutLink className="text-base" to={constructorUrl}>
        Редактировать в конструкторе <EditIcon className="text-indigo-500" width={14} />
      </StyledRoutLink>
      <StyledRoutLink
        className="text-base"
        to={domain.href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {domain.title} <LinkIcon width={14} />
      </StyledRoutLink>
    </CardContainer>
  );
};
