import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {httpClient} from '@shared/apiClient/lib/axios';
import {fromArray} from 'fp-ts/NonEmptyArray';
import {dataOf, errorOf, initialOf, loadingOf} from 'iled/dist/constructors';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {either} from 'fp-ts';
import {fromILED, fromOption} from '../../../shared/lib/utils';
import {DomainState} from '../model/interface';

export const useDomainStoreBase = create<DomainState>()(
  devtools((set, get) => ({
    activeDomainChanged: null,
    editingDomain: {
      configuration: {
        analyticsCodeText: '',
        backgroundType: undefined,
        buttonType: undefined,
        cookieBannerText: '',
        faviconId: '',
        ogMeta: undefined,
        paletteId: '',
        primaryFontId: '',
        seoText: '',
      },
      id: '',
      mainPageId: '',
      mapsApiKey: '',
      name: '',
      prettyDomain: '',
      publishedDate: '',
      subdomain: '',
      domainNameChangedDate: '',
    },
    domains: initialOf(null),
    isPrettyDomainAssigned: (prettyDomain: string) => {
      const domains = fromOption(fromILED(get().domains));
      return !domains?.find(domain => domain.prettyDomain === prettyDomain);
    },
    activeDomainId: '',
    activeDomain: initialOf(null),
    effects: {
      createDomain: async () => {
        set({
          domains: loadingOf(null),
        });
        const response = await httpClient.domain.createDomain({
          name: 'Новый сайт',
          domainConfiguration: {},
          subdomain: undefined,
        });
        if (response._tag === 'Left') {
          return either.left(response.left.error);
        }
        const {domains} = get();
        if (domains.type === 'Data' && domains.data._tag === 'Some') {
          set({
            domains: dataOf(fromArray([...domains.data.value, response.right.data])),
          });
        }
        return either.right(response.right.data);
      },
      createFromTemplate: async (domainId: string) => {
        set({
          domains: loadingOf(null),
        });

        const response = await httpClient.domain.createFromTemplate(domainId);
        if (response._tag === 'Left') {
          return either.left(response.left.error);
        }
        const {domains} = get();
        if (domains.type === 'Data' && domains.data._tag === 'Some') {
          set({
            domains: dataOf(fromArray([...domains.data.value, response.right.data])),
          });
        }
        return either.right(response.right.data);
      },

      removeDomain: async (id: string) => {
        const response = await httpClient.domain.deleteDomain(id);
        if (response._tag === 'Left') {
          return;
        }
        get().effects.fetchDomains();
      },

      fetchDomains: async () => {
        set({
          domains: loadingOf(null),
        });
        const response = await httpClient.domain.listDomains();
        if (response._tag === 'Left') {
          set({
            domains: errorOf(`Error while fetch domains`),
          });
          return;
        }
        set({
          domains: dataOf(fromArray(response.right.data)),
        });
      },
    },
  }))
);

export const useDomainStore = createSelectorHooks(useDomainStoreBase);
