import React, {lazy, Suspense, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {isSome} from 'fp-ts/Option';

import {useViewerStore} from '@entities/viewer';
import {Snackbar} from '@features/snackbar';

import {useAuthStore} from '@entities/auth';
import {useConfigStore} from '@entities/config';
import {useFeatureToggleStore} from '@shared/featureToggle/store/store';

import 'react-image-crop/dist/ReactCrop.css';
import './styles/main.css';
import '../shared/lib/utils';
import {ProtectedRoute} from './protectedRoute';
import {GlobalHistory} from './global-history';
import {httpClient} from '@shared/apiClient/lib/axios';
import {useProfileStore} from '@entities/profile';

const LandingLazy = lazy(() => import('pages/landing/landing'));
const AuthorizeByCRMTokenLazy = lazy(() => import('./authorizeByCRMToken'));
const AuthorizedApp = lazy(() => import('app/AuthorizedApp'));

const App = () => {
  const fetchCrmConfig = useConfigStore.useFetchCrmConfiguration();
  const viewer = useViewerStore.useViewer();
  const setViewer = useViewerStore.useSetViewer();

  const authInfo = useAuthStore.useAuthInfo();
  const fetchFeatureToggleFlags = useFeatureToggleStore.useFetchFeatureToggleFlags();
  const {currentProfile} = useProfileStore.useEffects();
  useEffect(() => {
    if (authInfo) {
      currentProfile().then((result: any) => {
        if (result._tag === 'Left') {
          console.log('Error while receiving Login', result);
          return;
        }

        if (result.right.data?.status === 'REGISTERED') {
          setViewer({
            name: {
              firstName: result.right.data?.login,
              lastName: '',
              middleName: '',
            },
            phone: result.right.data?.login,
            avatarImage: result.right.data?.avatarImage,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchCrmConfig();
  }, [fetchCrmConfig]);

  useEffect(() => {
    fetchFeatureToggleFlags();
  }, [fetchFeatureToggleFlags]);

  return (
    <BrowserRouter>
      <GlobalHistory />
      <Snackbar autoHideDuration={5e3} />
      <Routes>
        <Route
          path="/callback/auth-token"
          element={
            <Suspense>
              <AuthorizeByCRMTokenLazy />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense>
              <LandingLazy httpClient={httpClient} />
            </Suspense>
          }
        />
        <Route element={<ProtectedRoute isAllowed={isSome(viewer)} redirectPath="/" />}>
          <Route
            path="/admin/*"
            element={
              <Suspense>
                <AuthorizedApp />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<p>TODO: Page 404</p>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
