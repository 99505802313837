import React, {FC, useEffect} from 'react';
import {Input} from '../../components/input';
import {Select} from '@shared/ui';
import {ButtonInfo} from '@shared/api';
import {ButtonInfoConfigurationProps} from './model';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {ActiveDomainPagesOptions} from '../../components/activeDomainPagesOptions';
import {ActivePageBlocksOptions} from '../../components/activePageBlocksOptions';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {SIDEBAR_BUTTON_ELEMENT_PAGE_FIELD} from '@shared/dataTestId';

export const ButtonInfoConfiguration: FC<ButtonInfoConfigurationProps> = ({button, onSubmit}) => {
  if (!button) {
    button = {
      linkType: 'EXTERNAL',
      buttonClass: 'PRIMARY',
      title: '',
      href: '#',
    };
  }
  const onValidData: SubmitHandler<ButtonInfo> = data => {
    onSubmit(data);
  };

  const {getValues, reset, handleSubmit, control, watch, trigger} = useForm<ButtonInfo>({
    mode: 'onChange',
    resolver: yupResolver(buttonInfoConfigurationSchema),
    defaultValues: {
      ...button,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch((value, info) => {
      handleSubmit(onValidData)();
      if (info.type === 'change' && info.name === 'linkType') {
        reset({...value});
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const formLinkType = getValues('linkType');
  return (
    <>
      <Controller
        name="buttonClass"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Select
            value={value}
            label="Тип кнопки"
            onChange={onChange}
            invalid={invalid}
            errorMessage={error?.message}
          >
            <option value="PRIMARY"> Главная кнопка</option>
            <option value="SECONDARY"> Второстепенная кнопка</option>
          </Select>
        )}
      />

      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Текст кнопки"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="linkType"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Select
            value={value}
            label="Тип ссылки"
            onChange={onChange}
            invalid={invalid}
            errorMessage={error?.message}
          >
            <option value="">Не выбрано</option>
            <option value="ANCHOR"> Якорь</option>
            <option value="INTERNAL"> Внутренняя ссылка</option>
            <option value="EXTERNAL"> Внешняя ссылка</option>
          </Select>
        )}
      />

      {formLinkType === 'EXTERNAL' && (
        <Controller
          name="href"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Input
              label="Адрес ссылки"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {formLinkType === 'ANCHOR' && (
        <Controller
          name="blockId"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Select
              label="Перемещение на блок"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            >
              <ActivePageBlocksOptions />
            </Select>
          )}
        />
      )}
      {formLinkType === 'INTERNAL' && (
        <Controller
          name="pageId"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Select
              data-testid={SIDEBAR_BUTTON_ELEMENT_PAGE_FIELD}
              label="Страница"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            >
              <ActiveDomainPagesOptions />
            </Select>
          )}
        />
      )}
    </>
  );
};
