import {ReviewInfo} from '@shared/api';
import {DraggableContainer} from '@shared/draggable';
import {DraggableBullet} from '../../ui/item';
import {Button} from '@shared/ui/button';
import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';
import React, {useState} from 'react';
import {useToggle} from '@shared/hooks';
import {SettingsContainer} from '../../ui/settingsContainer';
import {BlockEditorPortal} from '../blockEditorPortal';
import {ReviewInfoConfiguration} from '../../configuration/reviewInfoConfiguration/reviewInfoConfiguration';

interface ReviewsProps {
  items: ReviewInfo[];
  onChange: (items: ReviewInfo[]) => void;
}

export const Reviews: React.FC<ReviewsProps> = ({items, onChange}) => {
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [activeReviewIndex, setActiveReviewIndex] = useState<number>(0);

  const removeHandler = (itemIndex: number) => () =>
    onChange(items.filter((item, index) => index !== itemIndex));

  const onReorderHandler = (reorderedItems: unknown[]) => {
    onChange(reorderedItems as ReviewInfo[]);
  };
  const onAddMenu = () => {
    onChange([
      ...items,
      {authorDescription: '', authorName: '', image: undefined, text: '', title: ''},
    ]);
    setActiveReviewIndex(items.length);
    toggleSettings();
  };

  const settingsHandler = (item: ReviewInfo, index: number) => () => {
    toggleSettings();
    setActiveReviewIndex(index);
  };

  const onChangeHandler = (link: ReviewInfo) => {
    onChange(
      items.map((item, index) => {
        if (index === activeReviewIndex) {
          return link;
        }
        return item;
      })
    );
  };

  const activeReview = items[activeReviewIndex] || {};

  return (
    <>
      <DraggableContainer items={items} onChange={onReorderHandler}>
        {items.map((item, index) => (
          <DraggableBullet
            data-testid={`review-bullet-${index}`}
            key={index.toString()}
            active={false}
            main={false}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item, index)}
            onRemove={removeHandler(index)}
          >
            {item.text}
          </DraggableBullet>
        ))}
      </DraggableContainer>
      <Button onClick={onAddMenu} className="w-full" color="gray" variant="ghost" size="small">
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить отзыв
      </Button>
      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer backLabel="Обратно к отзывам" backHandler={() => toggleSettings()}>
          <ReviewInfoConfiguration item={activeReview} onChange={onChangeHandler} />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
