import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {httpClient} from '@shared/apiClient/lib/axios';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {ConfigState} from './model/interface';

const useConfigStoreBase = create<ConfigState>()(
  devtools(set => ({
    crmConfiguration: {
      siteOrderUrl: '',
      formUrl: '',
      codeParameterName: '',
      siteOrderUrlMain: '',
    },
    configuration: {
      defaultIpAddress: '',
      billingFrontEndUrl: '',
      mapsApiKey: '',
      defaultDomain: '',
    },
    fetchConfiguration: async () => {
      const response = await httpClient.config.defaultConfig();
      if (response._tag === 'Right') {
        set({
          configuration: response.right.data,
        });
      }
    },
    fetchCrmConfiguration: async () => {
      const response = await httpClient.auth.getCrmConfig();
      if (response._tag === 'Right') {
        set({
          crmConfiguration: response.right.data,
        });
      }
    },
  }))
);

export const useConfigStore = createSelectorHooks(useConfigStoreBase);
