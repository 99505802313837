import tw from 'tailwind-styled-components';

interface CustomSelectProps {
  invalid?: boolean;
}

export const CustomSelect = tw.select<CustomSelectProps>`
    ${props => (props.invalid ? 'border-red-500' : 'border-gray-200')}
    ${props => (props.invalid ? 'hover:border-red-600' : 'hover:border-gray-300')}
    ${props => (props.invalid ? 'focus:border-red-600' : 'focus:border-indigo-500')}
    w-full
    px-4
    py-2.5
    border
    rounded-sm
    text-sm
    text-gray-800
    font-mulish-medium
    focus:ring-offset-0
    focus:ring-0
    focus:outline-offset-0
    outline-0
`;
