import React, {FC} from 'react';

import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';
import {Button, Caption} from '@shared/ui';

import {PointsProps} from './model';
import {PointsComposition} from './pointsComposition';

export const Points: FC<PointsProps> = ({points = [], onChange, invalid, error}) => {
  const onRemoveHandler = (currentIndex: number, value: string[]) => () => {
    onChange(value.filter((point, index) => index !== currentIndex));
  };

  const onChangeHandler = (currentIndex: number, value: string[]) => (nextValue: string) => {
    onChange(
      value.map((point, index) => {
        if (index === currentIndex) {
          return nextValue;
        }
        return point;
      })
    );
  };

  const onAddHandler = (value: string[]) => () => {
    onChange([...value, '']);
  };

  return (
    <div className="container mb-2">
      <span className="text-sm font-mulish-semibold">Название пунктов</span>

      {invalid && error && <Caption color="text-red-500">{error}</Caption>}

      <PointsComposition
        key={points?.length}
        onChange={onChangeHandler}
        points={points}
        onRemove={onRemoveHandler}
      />

      <Button
        onClick={onAddHandler(points)}
        className="w-full my-2"
        color="gray"
        variant="ghost"
        size="small"
      >
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить пункт меню
      </Button>
    </div>
  );
};
