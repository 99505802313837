import {NetworkItem} from '@shared/api';

export const networkHrefTypeLabelByType: Record<
  Exclude<NetworkItem['hrefType'], undefined>,
  string
> = {
  LINK: 'Ссылка',
  PHONE_NUMBER: 'Номер телефона',
  CHAT: 'Группа',
  USERNAME: 'Username',
};

export const networkMap: Partial<Record<NetworkItem['networkType'], NetworkInfo>> = {
  OK: {label: 'Одноклассники', variant: {LINK: {placeholder: 'https://ok.ru'}}},
  TELEGRAM: {
    label: 'Telegram',
    variant: {
      LINK: {
        placeholder: 'https://t.me/',
        match: '',
      },
      USERNAME: {
        placeholder: 'username',
        match: 'https://t.me/',
      },
    },
  },
  VK: {
    label: 'VK',
    variant: {
      LINK: {
        placeholder: 'https://vk.me/',
        match: '',
      },
      USERNAME: {
        placeholder: 'username',
        match: 'https://vk.me/',
      },
    },
  },
  // WHATSAPP: {
  //     label: 'Whatsapp',
  //     variant: {
  //         LINK: {
  //             placeholder: 'https://www.api.whatsapp.com/',
  //             match: '',
  //         },
  //         PHONE_NUMBER: {
  //             placeholder: '+7 (___) ___-__-__',
  //             match: 'https://wa.me/',
  //         },
  //         CHAT: {
  //             placeholder: 'https://www.chat.whatsapp.com/',
  //             match: '',
  //         },
  //     },
  // },
  // VIBER: {
  //     label: 'Viber',
  //     variant: {
  //         LINK: {
  //             placeholder: 'https://www.viber.com/',
  //             match: '',
  //         },
  //         PHONE_NUMBER: {
  //             placeholder: '+7 (___) ___-__-__',
  //             match: 'viber://chat?number=',
  //         },
  //     },
  // },
  //
  // DISCORD: { label: 'Discord', variant: { LINK: { placeholder: 'https://discord.gg' } } },
  // DZEN: { label: 'Яндекс.Дзен', variant: { LINK: { placeholder: 'https://dzen.ru' } } },
  // YOUTUBE: { label: 'YouTube', variant: { LINK: { placeholder: 'https://youtube.com' } } },
  // LINKEDIN: { label: 'LinkedIn', variant: { LINK: { placeholder: 'https://linkedin.com/in' } } },
  // SOUND_CLOUD: {
  //     label: 'SoundCloud',
  //     variant: { LINK: { placeholder: 'https://soundcloud.com' } },
  // },
  // PINTEREST: { label: 'Pinterest', variant: { LINK: { placeholder: 'https://pin.it' } } },
  // BEHANCE: { label: 'Behance', variant: { LINK: { placeholder: 'https://behance.net' } } },
  // SNAPCHAT: { label: 'Snapchat', variant: { LINK: { placeholder: 'https://snapchat.com' } } },
  // AVITO: { label: 'Avito', variant: { LINK: { placeholder: 'https://avito.ru' } } },
};

interface NetworkInfo {
  label: string;
  variant: Partial<
    Record<Exclude<NetworkItem['hrefType'], undefined>, {placeholder: string; match?: string}>
  >;
}

export const showNetworkHrefGui = (props: NetworkItem) => {
  if (props.hrefType) {
    const match = networkMap[props.networkType]?.variant[props.hrefType]?.match;
    if (match) {
      return props.href.replace(match, '');
    }
    return props.href;
  }
  return '';
};

export const saveNetworkHref = (props: NetworkItem) => {
  if (props.hrefType) {
    const match = networkMap[props.networkType]?.variant[props.hrefType]?.match;
    if (match) {
      return `${match}${props.href}`;
    }
    return props.href;
  }
  return props.href;
};

export const getIsRenderHrefType = (networkType: NetworkItem['networkType']) => {
  const variant = networkMap[networkType]?.variant;
  if (variant) {
    return Object.keys(variant).length > 1;
  }
  return false;
};

export const getPlaceholderByNetworkType = (
  networkType: NetworkItem['networkType'],
  hrefType: NetworkItem['hrefType']
) => {
  return networkMap[networkType]?.variant[hrefType as Exclude<NetworkItem['hrefType'], undefined>]
    ?.placeholder;
};

export const getNetworkLabelByType = (networkType: NetworkItem['networkType']) => {
  const networkInfo = networkMap[networkType];
  if (networkInfo) {
    return networkInfo.label;
  }
  return networkType.toLowerCase();
};
