import React, {FC} from 'react';
import {Select} from '@shared/ui';
import {SelectNetworkProps} from './model';
import {networkMap} from './utils';

export const SelectNetwork: FC<SelectNetworkProps> = ({onChange, invalid, errorMessage, value}) => {
  return (
    <Select
      value={value}
      label="Соцсеть или мессенджер"
      onChange={onChange}
      invalid={invalid}
      errorMessage={errorMessage}
    >
      {Object.entries(networkMap).map(([type, info]) => (
        <option key={type} value={type}>
          {info.label}
        </option>
      ))}
    </Select>
  );
};
