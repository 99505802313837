import {FC} from 'react';
import {Spinner} from '@shared/ui/spinner';
import {Paragraph} from '@shared/ui/typography';

export const SpinnerBlock: FC = () => {
  return (
    <div className="flex justify-center w-full border border-dashed border-gray-200 p-10 cursor-pointer bg-indigo-50">
      <div className="sm:max-w-xs">
        <div className="flex justify-center">
          <Spinner />
        </div>
        <div className="flex justify-between items-center p-5 flex-wrap">
          <Paragraph color="text-gray-800" className="text-base">
            Загрузка изображения...
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
