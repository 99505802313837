import * as yup from 'yup';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {galleryElementSchema} from '../../elements/galleryElement/schema';

export const galleryBlockConfigurationSchema = yup.object({
  title: titleSchema.nullable().optional().default(undefined),
  description: descriptionSchema.nullable().optional().default(undefined),
  button: buttonInfoConfigurationSchema.nullable().optional().default(undefined),
  images: galleryElementSchema.nullable().optional().default(undefined),
});
