import {BlockInfo} from '@shared/api';

export const getBlockFormFields = (block: BlockInfo['configuration'] | undefined) => {
  if (!block) {
    return [];
  }
  if ('form' in block && block.form !== undefined) {
    return block.form.fields;
  }
  return [];
};

export const politicsTooltip = `По закону владельцы сайтов имеют право обрабатывать персональные данные клиентов только после их согласия, поэтому мы рекомендуем разместить под формой предупреждающий текст со ссылкой на вашу политику в отношении обработки персональных данных (можно разместить на отдельной странице).
Пример: «Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности»`;

export const sendData = async (obj: object, url: string) => {
  await fetch(url, {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {'Content-type': 'application/json'},
    signal: AbortSignal.timeout(10e3),
  });
};
