/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface EnrichedHeaderBlockConfiguration {
  phoneNumber?: string;
  logo?: EnrichedCompanyLogoInfo;
  menu?: {
    items?: EnrichedLinkInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedLinkInfo;
    last?: EnrichedLinkInfo;
  };
  networks?: {
    items?: NetworkItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: NetworkItem;
    last?: NetworkItem;
  };
  button?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
  fixMenuWhileScrolling?: boolean;
}

export interface EnrichedTextBlockConfiguration {
  title?: string;
  titleTag?: string;
  textValue?: string;
  dropDownItems?: {
    items?: EnrichedDropDownItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedDropDownItem;
    last?: EnrichedDropDownItem;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedFirstScreenBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  pointsIconStyle?: 'CHECKMARK' | 'CHECKMARK_IN_CIRCLE' | 'TRIANGLE' | 'DOT' | 'MINUS';
  points?: {
    items?: string[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: string;
    last?: string;
  };
  form?: EnrichedFormInfo;
  primaryButton?: EnrichedButtonInfo;
  secondaryButton?: EnrichedButtonInfo;
  image?: EnrichedImageInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedButtonBlockConfiguration {
  primaryButton?: EnrichedButtonInfo;
  secondaryButton?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedSocialNetworksBlockConfiguration {
  networks?: {
    items?: NetworkItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: NetworkItem;
    last?: NetworkItem;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedCallToActionBlockConfiguration {
  title?: string;
  titleTag?: string;
  image?: EnrichedImageInfo;
  subtitle?: string;
  description?: string;
  primaryButton?: EnrichedButtonInfo;
  secondaryButton?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedReviewsBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  reviews?: {
    items?: EnrichedReviewInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedReviewInfo;
    last?: EnrichedReviewInfo;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedFooterBlockConfiguration {
  title?: string;
  description?: string;
  copyright?: string;
  form?: EnrichedFormInfo;
  logo?: EnrichedCompanyLogoInfo;
  networks?: {
    items?: NetworkItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: NetworkItem;
    last?: NetworkItem;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedImageInfo {
  url?: string;
  opacity?: string;
  altText?: string;
}

export interface EnrichedLinkInfo {
  href?: string;
  title?: string;
  target?: string;
}

export interface EnrichedButtonInfo {
  buttonClass?: 'PRIMARY' | 'SECONDARY';
  href?: string;
  title?: string;
  target?: string;
}

export interface EnrichedBackgroundInfo {
  background?: string;
  backgroundImage?: string;
  backgroundImageAltText?: string;
  backgroundVideo?: string;
  /** @format double */
  backgroundFogging?: number;
  colorInverted?: boolean;
}

export interface EnrichedReviewInfo {
  image?: EnrichedImageInfo;
  title?: string;
  text?: string;
  authorName?: string;
  authorDescription?: string;
}

export interface EnrichedTeamMemberInfo {
  memberImage?: EnrichedImageInfo;
  memberName?: string;
  nameTag?: string;
  memberDescription?: string;
}

export interface EnrichedTeamBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  team?: {
    items?: EnrichedTeamMemberInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedTeamMemberInfo;
    last?: EnrichedTeamMemberInfo;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedContactsFormBlockConfiguration {
  title?: string;
  titleTag?: string;
  subtitle?: string;
  description?: string;
  form?: EnrichedFormInfo;
  image?: EnrichedImageInfo;
  networks?: {
    items?: NetworkItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: NetworkItem;
    last?: NetworkItem;
  };
  email?: string;
  phoneNumber?: string;
  address?: string;
  button?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedCardsBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  cards?: {
    items?: EnrichedCardsInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedCardsInfo;
    last?: EnrichedCardsInfo;
  };
  button?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedCardsInfo {
  title?: string;
  titleTag?: string;
  description?: string;
  image?: EnrichedImageInfo;
  button?: EnrichedButtonInfo;
  link?: EnrichedLinkInfo;
}

export interface EnrichedContactsBlockConfiguration {
  title?: string;
  titleTag?: string;
  firstSubtitle?: string;
  secondSubtitle?: string;
  description?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  networks?: {
    items?: NetworkItem[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: NetworkItem;
    last?: NetworkItem;
  };
  form?: EnrichedFormInfo;
  background?: EnrichedBackgroundInfo;
  map?: MapInfo;
}

export interface EnrichedPartnersBlockConfiguration {
  title?: string;
  titleTag?: string;
  subtitle?: string;
  description?: string;
  partners?: {
    items?: EnrichedPartnerInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedPartnerInfo;
    last?: EnrichedPartnerInfo;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedPartnerInfo {
  image?: EnrichedImageInfo;
  link?: EnrichedLinkInfo;
}

export interface EnrichedAchievementsBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  cards?: {
    items?: EnrichedAchievementCardInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedAchievementCardInfo;
    last?: EnrichedAchievementCardInfo;
  };
  icon?: EnrichedImageInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedAchievementCardInfo {
  title?: string;
  titleTag?: string;
  description?: string;
  /** @format int64 */
  number?: number;
  unitOfMeasure?: string;
  icon?: EnrichedImageInfo;
  link?: EnrichedLinkInfo;
}

export interface EnrichedGalleryBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  button?: EnrichedButtonInfo;
  images?: {
    items?: EnrichedImageInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedImageInfo;
    last?: EnrichedImageInfo;
  };
  background?: EnrichedBackgroundInfo;
}

export interface DomainTemplateInfo {
  id?:
    | 'universal-company-page'
    | 'universal-landing'
    | 'autoservice'
    | 'cosmetology-clinic'
    | 'barbershop'
    | 'yoga'
    | 'restaurant'
    | 'river-trips';
  name?: string;
  description?: string;
  configuration?: DomainTemplateConfiguration;
  pages?: PageTemplateInfo[];
}

export interface DomainTemplateConfiguration {
  buttonType?: 'FULLY_ROUNDED' | 'PARTLY_ROUNDED' | 'SQUARED';
  primaryFontFamily?: string;
  backgroundType?: 'LIGHT' | 'DARK';
  paletteId?: string;
}

export interface PageTemplateInfo {
  path?: string;
  configuration?: PageConfiguration;
  blocks?: BlockTemplateInfo[];
  main?: boolean;
}

export interface BlockTemplateInfo {
  configuration?: BlockConfiguration;
  /** @format uuid */
  blockId?: string;
}

export interface EnrichedTariffsBlockConfiguration {
  title?: string;
  titleTag?: string;
  description?: string;
  captionAboveTitle?: string;
  tariffs?: {
    items?: EnrichedTariffInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedTariffInfo;
    last?: EnrichedTariffInfo;
  };
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedTariffInfo {
  /** @format int64 */
  price?: number;
  currency?: 'RUB' | 'USD' | 'EUR';
  name?: string;
  nameTag?: string;
  button?: EnrichedButtonInfo;
  properties?: {
    items?: EnrichedTariffProperty[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedTariffProperty;
    last?: EnrichedTariffProperty;
  };
  period?: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'NO';
  description?: string;
  popular?: boolean;
}

export interface EnrichedTariffProperty {
  title?: string;
  switchOn?: boolean;
}

export interface ProcessFormRequest {
  formId?: string;
  leadData?: LeadData;
  /** @format uuid */
  blockId?: string;
}

export type EnrichedSeparatorsBlockConfiguration = object;

export interface EnrichedFormInfo {
  formId?: string;
  fields?: {
    items?: FormFieldInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: FormFieldInfo;
    last?: FormFieldInfo;
  };
  buttonText?: string;
  targetUrl?: string;
  politics?: string;
  webhooks?: {
    items?: string[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: string;
    last?: string;
  };
}

export interface EnrichedVideoInfo {
  url?: string;
  disableSound?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  coverImage?: EnrichedImageInfo;
}

export interface EnrichedHtmlBlockConfiguration {
  html?: string;
}

export interface EnrichedVideoBlockConfiguration {
  title?: string;
  titleTag?: string;
  subtitle?: string;
  description?: string;
  firstButton?: EnrichedButtonInfo;
  secondButton?: EnrichedButtonInfo;
  firstSectionTitle?: string;
  secondSectionTitle?: string;
  firstSectionText?: string;
  secondSectionText?: string;
  video?: EnrichedVideoInfo;
  background?: EnrichedBackgroundInfo;
}

export interface InternalConfiguration {
  id?: string;
  entityId?: string;
}

export interface EnrichedCompanyLogoInfo {
  image?: EnrichedImageInfo;
  name?: string;
}

export interface EnrichedList {
  items?: object[];
  /** @format int32 */
  length?: number;
  empty?: boolean;
  /** @format int32 */
  size?: number;
  first?: object;
  last?: object;
}

export type EnrichedBlockConfiguration = object;

export enum DomainTemplateType {
  UniversalCompanyPage = 'universal-company-page',
  UniversalLanding = 'universal-landing',
  Autoservice = 'autoservice',
  CosmetologyClinic = 'cosmetology-clinic',
  Barbershop = 'barbershop',
  Yoga = 'yoga',
  Restaurant = 'restaurant',
  RiverTrips = 'river-trips',
}

export interface EnrichedProductsAndPricesBlockConfiguration {
  captionAboveTitle?: string;
  title?: string;
  titleTag?: string;
  description?: string;
  productCards?: {
    items?: EnrichedProductCardInfo[];
    /** @format int32 */
    length?: number;
    empty?: boolean;
    /** @format int32 */
    size?: number;
    first?: EnrichedProductCardInfo;
    last?: EnrichedProductCardInfo;
  };
  button?: EnrichedButtonInfo;
  background?: EnrichedBackgroundInfo;
}

export interface EnrichedProductCardInfo {
  title?: string;
  titleTag?: string;
  description?: string;
  image?: EnrichedImageInfo;
  price?: string;
  quantityAndUnits?: string;
}

export interface EnrichedDropDownItem {
  title?: string;
  value?: string;
}

export enum TagType {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  DIV = 'DIV',
}

export interface ErrorInfo {
  errorType:
    | 'AUTHENTICATION_ERROR'
    | 'ACTION_NOT_ALLOWED_ERROR'
    | 'USER_IS_BLOCKED_ERROR'
    | 'ENTITY_NOT_FOUND_ERROR'
    | 'VALIDATION_ERROR'
    | 'CREDENTIAL_ERROR'
    | 'ENTITY_ALREADY_USED'
    | 'ENTITY_ALREADY_EXISTS'
    | 'OTP_CODE_WAS_ALREADY_SENT'
    | 'OTP_CODE_OUTDATED'
    | 'NOTIFICATION_CHANNEL_NOT_FOUND'
    | 'PUBLIC_API_ERROR'
    | 'INTERNAL_SERVER_ERROR'
    | 'SMS_SERVICE_ERROR'
    | 'AUTH_SERVICE_ERROR'
    | 'BILLING_SERVICE_ERROR'
    | 'REG_RU_SERVICE_ERROR'
    | 'UNSPLASH_SERVICE_ERROR'
    | 'EMAIL_SERVICE_ERROR'
    | 'CDN_VIDEO_SERVICE_ERROR'
    | 'SERVICE_PIPE_SERVICE_ERROR';
  errorMessage?: string;
  errorList?: string[];
}

export interface PasswordSetRequest {
  password: string;
}

export interface OtpRequest {
  phoneNumber: string;
}

export interface OtpNewRequestTime {
  /** @format date-time */
  nextRequestFrom: string;
}

export interface OtpCheckRequest {
  phoneNumber: string;
  code: string;
}

export interface AuthResponse {
  login: string;
  /** @format uuid */
  profileId: string;
  profileStatus: 'CREATED' | 'REGISTERED' | 'TRIED';
  authenticationType: 'PHONE' | 'CRM' | 'VIP';
  accessToken: string;
  refreshToken?: string;
}

export interface AchievementCardInfo {
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  /** @format int64 */
  number?: number;
  unitOfMeasure?: string;
  icon?: ImageBase;
  link?: LinkInfo;
}

export type AchievementsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  cards: AchievementCardInfo[];
  icon?: ImageBase;
  background?: BackgroundInfo;
};

export interface BackgroundInfo {
  type: 'COLOR_SOLID' | 'COLOR_GRADIENT' | 'IMAGE' | 'VIDEO';
  color?: string;
  gradient?: string;
  image?: ImageBase;
  videoUrl?: string;
  /** @format double */
  fogging?: number;
  colorInverted: boolean;
}

export interface BlockConfiguration {
  group:
    | 'TEXT'
    | 'HEADER'
    | 'FIRST_SCREEN'
    | 'BUTTON'
    | 'SOCIAL_NETWORKS'
    | 'CALL_TO_ACTION'
    | 'REVIEWS'
    | 'HTML'
    | 'VIDEO'
    | 'GALLERY'
    | 'CONTACTS_FORM'
    | 'FOOTER'
    | 'TEAM'
    | 'CARDS'
    | 'CONTACTS'
    | 'PARTNERS'
    | 'ACHIEVEMENTS'
    | 'SEPARATORS'
    | 'TARIFFS'
    | 'PRODUCTS_PRICES';
}

export interface BlockInfo {
  /** @format uuid */
  id: string;
  group:
    | 'TEXT'
    | 'HEADER'
    | 'FIRST_SCREEN'
    | 'BUTTON'
    | 'SOCIAL_NETWORKS'
    | 'CALL_TO_ACTION'
    | 'REVIEWS'
    | 'HTML'
    | 'VIDEO'
    | 'GALLERY'
    | 'CONTACTS_FORM'
    | 'FOOTER'
    | 'TEAM'
    | 'CARDS'
    | 'CONTACTS'
    | 'PARTNERS'
    | 'ACHIEVEMENTS'
    | 'SEPARATORS'
    | 'TARIFFS'
    | 'PRODUCTS_PRICES';
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  configuration:
    | AchievementsBlockConfiguration
    | ButtonBlockConfiguration
    | CallToActionBlockConfiguration
    | CardsBlockConfiguration
    | ContactsBlockConfiguration
    | ContactsFormBlockConfiguration
    | FirstScreenBlockConfiguration
    | FooterBlockConfiguration
    | GalleryBlockConfiguration
    | HeaderBlockConfiguration
    | HtmlBlockConfiguration
    | PartnersBlockConfiguration
    | ProductsAndPricesBlockConfiguration
    | ReviewsBlockConfiguration
    | SeparatorsBlockConfiguration
    | SocialNetworksBlockConfiguration
    | TariffsBlockConfiguration
    | TeamBlockConfiguration
    | TextBlockConfiguration
    | VideoBlockConfiguration;
  html?: string;
  css?: string;
  /** @format int32 */
  order: number;
  isVisible: boolean;
  htmlId: string;
}

export type ButtonBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  primaryButton: ButtonInfo;
  secondaryButton?: ButtonInfo;
  background?: BackgroundInfo;
};

export interface ButtonInfo {
  buttonClass: 'PRIMARY' | 'SECONDARY';
  linkType: 'ANCHOR' | 'INTERNAL' | 'EXTERNAL';
  href?: string;
  /** @format uuid */
  blockId?: string;
  /** @format uuid */
  pageId?: string;
  title: string;
}

export type CallToActionBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  image?: ImageBase;
  primaryButton?: ButtonInfo;
  secondaryButton?: ButtonInfo;
  subtitle?: string;
  description?: string;
  background?: BackgroundInfo;
};

export interface CardInfo {
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  image?: ImageBase;
  button?: ButtonInfo;
  link?: LinkInfo;
}

export type CardsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  cards: CardInfo[];
  button?: ButtonInfo;
  background?: BackgroundInfo;
};

export interface CompanyLogoInfo {
  image?: ImageBase;
  name?: string;
}

export type ContactsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  firstSubtitle?: string;
  secondSubtitle?: string;
  description?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  networks?: NetworkItem[];
  form?: FormInfo;
  background?: BackgroundInfo;
  map?: MapInfo;
};

export type ContactsFormBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  subtitle?: string;
  description?: string;
  form?: FormInfo;
  image?: ImageBase;
  networks?: NetworkItem[];
  email?: string;
  phoneNumber?: string;
  address?: string;
  button?: ButtonInfo;
  background?: BackgroundInfo;
};

export interface DropDownItem {
  title?: string;
  value?: string;
}

export type FirstScreenBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  pointsIconStyle?: 'CHECKMARK' | 'CHECKMARK_IN_CIRCLE' | 'TRIANGLE' | 'DOT' | 'MINUS';
  points?: string[];
  form?: FormInfo;
  primaryButton?: ButtonInfo;
  secondaryButton?: ButtonInfo;
  image?: ImageBase;
  background?: BackgroundInfo;
};

export type FooterBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  description?: string;
  copyright?: string;
  form?: FormInfo;
  logo?: CompanyLogoInfo;
  networks?: NetworkItem[];
  background?: BackgroundInfo;
};

export interface FormFieldInfo {
  fieldName: string;
  fieldLabel?: string;
  fieldType: 'TEXT' | 'TELEPHONE' | 'EMAIL' | 'TEXTAREA';
  required: boolean;
  placeholder?: string;
}

export interface FormInfo {
  formId: string;
  fields: FormFieldInfo[];
  politics?: string;
  buttonText: string;
  webhooks?: string[];
}

export type GalleryBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  button?: ButtonInfo;
  images: ImageBase[];
  background?: BackgroundInfo;
};

export type HeaderBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  phoneNumber?: string;
  logo?: CompanyLogoInfo;
  menu?: LinkInfo[];
  networks?: NetworkItem[];
  button?: ButtonInfo;
  background?: BackgroundInfo;
  fixMenuWhileScrolling: boolean;
};

export type HtmlBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  html: string;
};

export interface ImageBase {
  /** @format uuid */
  id?: string;
  hash: string;
  url?: string;
  altText?: string;
}

export interface LinkInfo {
  linkType: 'ANCHOR' | 'INTERNAL' | 'EXTERNAL';
  href?: string;
  /** @format uuid */
  blockId?: string;
  /** @format uuid */
  pageId?: string;
  title: string;
}

export interface MapInfo {
  mapCenter?: Point;
  /** @format int32 */
  zoom: number;
  placeMarks: PlaceMark[];
}

export interface NetworkItem {
  networkType:
    | 'TELEGRAM'
    | 'WHATSAPP'
    | 'VIBER'
    | 'VK'
    | 'OK'
    | 'DISCORD'
    | 'DZEN'
    | 'YOUTUBE'
    | 'LINKEDIN'
    | 'SOUND_CLOUD'
    | 'PINTEREST'
    | 'BEHANCE'
    | 'SNAPCHAT'
    | 'AVITO'
    | 'TWITTER'
    | 'FACEBOOK'
    | 'TIKTOK'
    | 'TWITCH'
    | 'REDDIT';
  href: string;
  hrefType?: 'LINK' | 'PHONE_NUMBER' | 'CHAT' | 'USERNAME';
}

export interface PageConfiguration {
  name: string;
  /** @format uuid */
  faviconId?: string;
  seoConfiguration?: PageSeoConfiguration;
}

export interface PageInfo {
  /** @format uuid */
  id: string;
  status: 'DRAFT' | 'PUBLISHED' | 'HAS_CHANGES' | 'HIDDEN';
  path: string;
  name: string;
  isMain: boolean;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  publishedDate?: string;
  externalRef?: string;
  configuration: PageConfiguration;
  blocks?: BlockInfo[];
  hash: string;
}

export interface PageSeoConfiguration {
  title?: string;
  description?: string;
  canonicalLink?: string;
  noIndex?: boolean;
  noFollowLinks?: boolean;
  priority?: string;
}

export interface PartnerInfo {
  image: ImageBase;
  link?: LinkInfo;
}

export type PartnersBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  subtitle?: string;
  description?: string;
  partners: PartnerInfo[];
  background?: BackgroundInfo;
};

export interface PlaceMark {
  point: Point;
  address: string;
  externalId: string;
}

export interface Point {
  /** @format double */
  x: number;
  /** @format double */
  y: number;
}

export interface ProductCardInfo {
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  image?: ImageBase;
  price?: string;
  quantityAndUnits?: string;
}

export type ProductsAndPricesBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  captionAboveTitle?: string;
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  productCards: ProductCardInfo[];
  button?: ButtonInfo;
  background?: BackgroundInfo;
};

export interface ReviewInfo {
  image?: ImageBase;
  title?: string;
  text?: string;
  authorName?: string;
  authorDescription?: string;
}

export type ReviewsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  reviews: ReviewInfo[];
  background?: BackgroundInfo;
};

export type SeparatorsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
};

export type SocialNetworksBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  networks?: NetworkItem[];
  background?: BackgroundInfo;
};

export interface TariffInfo {
  /** @format int64 */
  price?: number;
  currency?: 'RUB' | 'USD' | 'EUR';
  name?: string;
  nameTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  button?: ButtonInfo;
  isPopular?: boolean;
  switchedOnProperties?: TariffProperty[];
  period?: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'NO';
  description?: string;
}

export interface TariffProperty {
  title?: string;
}

export type TariffsBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  captionAboveTitle?: string;
  tariffs: TariffInfo[];
  allProperties?: TariffProperty[];
  background?: BackgroundInfo;
};

export type TeamBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  description?: string;
  team: TeamMemberInfo[];
  background?: BackgroundInfo;
};

export interface TeamMemberInfo {
  memberImage: ImageBase;
  memberName: string;
  memberNameTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  memberDescription: string;
}

export type TextBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  textValue?: string;
  dropDownItems?: DropDownItem[];
  background?: BackgroundInfo;
};

export type VideoBlockConfiguration = UtilRequiredKeys<BlockConfiguration, 'group'> & {
  type:
    | 'TEXT1'
    | 'TEXT2'
    | 'TEXT3'
    | 'TEXT4'
    | 'TEXT5'
    | 'TEXT6'
    | 'TEXT7'
    | 'HEADER1'
    | 'HEADER2'
    | 'HEADER3'
    | 'HEADER4'
    | 'FIRST_SCREEN1'
    | 'FIRST_SCREEN2'
    | 'FIRST_SCREEN3'
    | 'FIRST_SCREEN4'
    | 'FIRST_SCREEN5'
    | 'BUTTON1'
    | 'BUTTON2'
    | 'BUTTON3'
    | 'SOCIAL_NETWORKS1'
    | 'SOCIAL_NETWORKS2'
    | 'SOCIAL_NETWORKS3'
    | 'CALL_TO_ACTION1'
    | 'CALL_TO_ACTION2'
    | 'CALL_TO_ACTION3'
    | 'CALL_TO_ACTION4'
    | 'REVIEWS1'
    | 'REVIEWS2'
    | 'REVIEWS3'
    | 'REVIEWS4'
    | 'GALLERY1'
    | 'GALLERY2'
    | 'GALLERY3'
    | 'GALLERY4'
    | 'GALLERY5'
    | 'GALLERY6'
    | 'HTML1'
    | 'VIDEO1'
    | 'VIDEO2'
    | 'CONTACTS_FORM1'
    | 'CONTACTS_FORM2'
    | 'CONTACTS_FORM3'
    | 'CONTACTS_FORM4'
    | 'TEAM1'
    | 'TEAM2'
    | 'TEAM3'
    | 'FOOTER1'
    | 'FOOTER2'
    | 'FOOTER3'
    | 'CARDS1'
    | 'CARDS2'
    | 'CARDS3'
    | 'CARDS4'
    | 'CARDS5'
    | 'CONTACTS1'
    | 'CONTACTS2'
    | 'CONTACTS3'
    | 'CONTACTS4'
    | 'CONTACTS5'
    | 'PARTNERS1'
    | 'PARTNERS2'
    | 'PARTNERS3'
    | 'PARTNERS4'
    | 'ACHIEVEMENTS1'
    | 'ACHIEVEMENTS2'
    | 'ACHIEVEMENTS3'
    | 'TARIFFS1'
    | 'TARIFFS2'
    | 'TARIFFS3'
    | 'SEPARATORS1'
    | 'SEPARATORS2'
    | 'SEPARATORS3'
    | 'PRODUCTS_PRICES1'
    | 'PRODUCTS_PRICES2'
    | 'PRODUCTS_PRICES3'
    | 'PRODUCTS_PRICES4';
  title?: string;
  titleTag?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'DIV';
  subtitle?: string;
  description?: string;
  firstButton?: ButtonInfo;
  secondButton?: ButtonInfo;
  firstSectionTitle?: string;
  secondSectionTitle?: string;
  firstSectionText?: string;
  secondSectionText?: string;
  video?: VideoInfo;
  background?: BackgroundInfo;
};

export interface VideoInfo {
  url: string;
  disableSound: boolean;
  autoplay: boolean;
  loop: boolean;
  coverImage?: ImageBase;
}

export interface FieldWithValue {
  field: string;
  value?: string;
}

export interface LeadData {
  fields: FieldWithValue[];
}

export interface BlockedUserResponse {
  /** @format date-time */
  blockedUntil: string;
}

export interface CreatePrettyDomainRequest {
  domainName: string;
  domainType: 'FREE' | 'PURCHASED' | 'OWN';
}

export interface PrettyDomainInfo {
  /** @format uuid */
  id: string;
  domainName: string;
  type: 'FREE' | 'PURCHASED' | 'OWN';
  status: 'DELEGATED' | 'CREATED' | 'ERROR';
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  updatedDate: string;
}

export interface DelegateDomainRequestData {
  country: 'RU';
  email: string;
  contactPhone: string;
  securityPhone: string;
  firstName: string;
  patronymicName?: string;
  lastName: string;
  passportNumberSeries: string;
  /** @format date */
  birthDate: string;
  /** @format date */
  issuedDate: string;
  issuedBy: string;
  postalCode: string;
  addressRegion?: string;
  addressCity: string;
  address: string;
}

export interface DelegatePrettyDomainRequest {
  /** @format uuid */
  prettyDomainId: string;
  data: DelegateDomainRequestData;
  /** @format uuid */
  assignToDomainId?: string;
}

export interface DeassignPrettyDomainRequest {
  /** @format uuid */
  domainId?: string;
  /** @format uuid */
  prettyDomainId?: string;
}

export interface CheckPrettyDomainRequest {
  domainName: string;
}

export interface CheckDomainResponse {
  domainName: string;
  /** @format int32 */
  price?: number;
  isPremium: boolean;
  status: 'AVAILABLE' | 'NOT_AVAILABLE';
  suggestions: DomainSuggestion[];
}

export interface DomainSuggestion {
  domainName: string;
  /** @format int32 */
  price?: number;
}

export interface CheckLocalDomainResponse {
  status: 'AVAILABLE' | 'NOT_AVAILABLE';
}

export interface AssignPrettyDomainRequest {
  /** @format uuid */
  domainId: string;
  /** @format uuid */
  prettyDomainId: string;
}

export interface CreatePageRequest {
  path: string;
  /** @format uuid */
  domainId: string;
  configuration: PageConfiguration;
}

export interface ImageInfo {
  /** @format uuid */
  id: string;
  originalExternalRef: string;
  croppedExternalRef: string;
  hash: string;
  opacity: string;
  boundingRect: Rectangle;
  cropRect: Rectangle;
}

export interface Rectangle {
  /** @format int32 */
  x?: number;
  /** @format int32 */
  y?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
}

export interface CreateFormRequest {
  /** @format uuid */
  domainId: string;
  /** @format uuid */
  pageId: string;
  formId: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  leadData: LeadData;
  comment: string;
}

export interface FormResultInfo {
  /** @format uuid */
  id: string;
  /** @format uuid */
  domainId: string;
  /** @format uuid */
  pageId: string;
  formId: string;
  /** @format date-time */
  createdDate: string;
  status: 'NEW' | 'IN_PROGRESS' | 'REJECTED' | 'ARCHIVED';
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  leadData: LeadData;
  comment: string;
}

export interface FormFilters {
  /** @format uuid */
  domainId: string;
  searchRequest?: string;
  /** @uniqueItems true */
  pageIdFilters: string[];
  /** @uniqueItems true */
  formIdsFilter: string[];
  /** @uniqueItems true */
  emailFilters: string[];
  /** @uniqueItems true */
  phoneFilters: string[];
  /** @uniqueItems true */
  firstNameFilters: string[];
  /** @uniqueItems true */
  lastNameFilters: string[];
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  /** @uniqueItems true */
  statusFilters: ('NEW' | 'IN_PROGRESS' | 'REJECTED' | 'ARCHIVED')[];
}

export interface ListFormRequest {
  filters: FormFilters;
  pagination?: Paging;
  sort?: Sort;
}

export interface Paging {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  pageNumber: number;
}

export interface Sort {
  fieldName: string;
  sortOrder: 'ASC' | 'DESC';
}

export interface PageFormResultInfo {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  /** @format int32 */
  size?: number;
  content?: FormResultInfo[];
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageableObject {
  paged?: boolean;
  unpaged?: boolean;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  offset?: number;
  sort?: SortObject;
}

export interface SortObject {
  unsorted?: boolean;
  sorted?: boolean;
  empty?: boolean;
}

export interface ExportOptions {
  timezone: string;
}

export interface ExportXLSRequest {
  /** @uniqueItems true */
  formResultIds?: string[];
  /** @format uuid */
  domainId?: string;
  exportOptions: ExportOptions;
}

export interface ChangeFormStatusRequest {
  /** @uniqueItems true */
  formResultIds: string[];
  newStatus: 'NEW' | 'IN_PROGRESS' | 'REJECTED' | 'ARCHIVED';
}

export interface CreatePaletteRequest {
  defaultColor: string;
  backgroundColor: string;
  strokeColor: string;
  disabledColor: string;
  errorColor: string;
  successColor: string;
  accentPrimaryColor: string;
  accentSecondaryColor: string;
  textPrimaryColor: string;
  textSecondaryColor: string;
}

export interface PaletteInfo {
  /** @format uuid */
  id: string;
  /** @format uuid */
  ownerId?: string;
  defaultColor: string;
  backgroundColor: string;
  strokeColor: string;
  disabledColor: string;
  errorColor: string;
  successColor: string;
  accentPrimaryColor: string;
  accentSecondaryColor: string;
  textPrimaryColor: string;
  textSecondaryColor: string;
}

export interface CreateDomainRequest {
  name: string;
  subdomain?: string;
  domainConfiguration: DomainConfiguration;
}

export interface DomainConfiguration {
  buttonType?: 'FULLY_ROUNDED' | 'PARTLY_ROUNDED' | 'SQUARED';
  /** @format uuid */
  primaryFontId?: string;
  backgroundType?: 'LIGHT' | 'DARK';
  /** @format uuid */
  paletteId?: string;
  /** @format uuid */
  faviconId?: string;
  analyticsCodeText?: string;
  seoText?: string;
  seoConfiguration?: DomainSeoConfiguration;
  cookieBannerText?: string;
  ogMeta?: OgMeta;
  forms?: WebhookInfo[];
}

export interface DomainSeoConfiguration {
  robotsPath?: string;
  sitemapPath?: string;
  notFoundPage?: string;
  noIndex?: boolean;
  webMasterCode?: string;
}

export interface OgMeta {
  /** @format uuid */
  imageId?: string;
  title?: string;
  text?: string;
}

export interface WebhookInfo {
  name?: string;
  url: string;
  /** @format uuid */
  id?: string;
}

export interface DomainInfo {
  /** @format uuid */
  id: string;
  subdomain: string;
  name: string;
  configuration: DomainConfiguration;
  /** @format uuid */
  mainPageId?: string;
  mainPageHash: string;
  /** @format date-time */
  publishedDate?: string;
  prettyDomain?: string;
  mapsApiKey?: string;
  /** @format date-time */
  domainNameChangedDate: string;
}

export interface WebhookRequest {
  webhook: WebhookInfo;
}

export interface BillingStatus {
  /** @format int64 */
  billingClientId?: number;
  profileAuthenticationType: 'PHONE' | 'CRM' | 'VIP';
  /** @format date-time */
  subscriptionEndDate?: string;
  activeTariffs: string[];
  activeServices: string[];
  currentTariffInfo?: TariffInfo;
  isCardAttached?: boolean;
  isSubscriptionActive?: boolean;
}

export interface PasswordUpdateRequest {
  oldPassword: string;
  newPassword: string;
}

export interface RenewRequest {
  refreshToken: string;
  /** @format uuid */
  profileId: string;
}

export interface AuthRequest {
  username: string;
  password: string;
}

export interface CodeAuthRequest {
  code: string;
}

export interface UpdateProfileRequest {
  name?: string;
  email?: string;
  /** @format uuid */
  avatarImageId?: string;
}

export interface ProfileInfo {
  /** @format uuid */
  id: string;
  login: string;
  status: 'CREATED' | 'REGISTERED' | 'TRIED';
  authenticationType: 'PHONE' | 'CRM' | 'VIP';
  name: string;
  email: string;
  avatarImage?: ImageInfo;
  paymentMethod: 'NONE' | 'CARD' | 'SBBOL';
  paymentMethodStatus: 'EMPTY' | 'ACTIVE' | 'ERROR';
  /** @uniqueItems true */
  features: ('COPYRIGHT_OFF' | 'ANALYTICS' | 'HTMLBLOCK')[];
}

export interface UpdateBlockRequest {
  configuration?:
    | AchievementsBlockConfiguration
    | ButtonBlockConfiguration
    | CallToActionBlockConfiguration
    | CardsBlockConfiguration
    | ContactsBlockConfiguration
    | ContactsFormBlockConfiguration
    | FirstScreenBlockConfiguration
    | FooterBlockConfiguration
    | GalleryBlockConfiguration
    | HeaderBlockConfiguration
    | HtmlBlockConfiguration
    | PartnersBlockConfiguration
    | ProductsAndPricesBlockConfiguration
    | ReviewsBlockConfiguration
    | SeparatorsBlockConfiguration
    | SocialNetworksBlockConfiguration
    | TariffsBlockConfiguration
    | TeamBlockConfiguration
    | TextBlockConfiguration
    | VideoBlockConfiguration;
  isVisible?: boolean;
}

export interface UpdatePageRequest {
  path?: string;
  configuration?: PageConfiguration;
}

export interface UpdateFormRequest {
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  leadData: LeadData;
  comment: string;
}

export interface UpdateDomainRequest {
  domain?: string;
  name?: string;
  configuration?: DomainConfiguration;
  mapsApiKey?: string;
}

export interface PurchaseDomainInfo {
  /** @format int32 */
  period: number;
  /** @format int32 */
  price: number;
  /** @format int32 */
  renewPrice: number;
  /** @format date */
  nextPaymentDate: string;
}

export interface PageListInfo {
  /** @format uuid */
  id: string;
  status: 'DRAFT' | 'PUBLISHED' | 'HAS_CHANGES' | 'HIDDEN';
  path: string;
  name: string;
  isMain: boolean;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  publishedDate?: string;
  hash: string;
}

export interface Photo {
  id: string;
  description?: string;
  urls: PhotoUrls;
}

export interface PhotoUrls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface PhotoCollection {
  id: string;
  title: string;
  photos: Photo[];
}

export interface FeaturesFlagsInfo {
  registrationFromUi: boolean;
  addingCustomDomain: boolean;
  purchaseDomain: boolean;
}

export interface FontInfo {
  /** @format uuid */
  id: string;
  familyName: string;
  default: boolean;
}

export interface ButtonTypeInfo {
  type: 'FULLY_ROUNDED' | 'PARTLY_ROUNDED' | 'SQUARED';
  borderRadius: string;
}

export interface DomainCssInfo {
  css: string;
}

export interface DefaultConfigInfo {
  mapsApiKey: string;
  defaultDomain: string;
  defaultIpAddress: string;
  billingFrontEndUrl: string;
}

export interface ChecklistPointsInfo {
  checklist: ChecklistProgressInfo[];
  /** @format double */
  total: number;
}

export interface ChecklistProgressInfo {
  /** @format uuid */
  id: string;
  text: string;
  textCompleted: string;
  action:
    | 'ADD_LOGO'
    | 'CHANGE_SITE_HEADER'
    | 'SET_DOMAIN_NAME'
    | 'ADD_CONTACTS'
    | 'ADD_FORM'
    | 'ADD_FONT'
    | 'ADD_PALETTE'
    | 'CHECK_MOBILE_VERSION'
    | 'ADD_SEO_TEXT'
    | 'ADD_COOKIE_BANNER'
    | 'ADD_ANALYTICS'
    | 'PUBLISH';
  /** @format double */
  percent: number;
  completed: boolean;
}

export interface CrmAuthConfiguration {
  formUrl: string;
  codeParameterName: string;
  siteOrderUrl: string;
  siteOrderUrlMain: string;
}

import axios, {AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType} from 'axios';
import {TaskEither} from 'fp-ts/TaskEither';

export type ResponseData<D> = {
  status: number;
  data: D;
};

export type ResponseError = {
  status: number | null | undefined;
  error: HttpClientError | ErrorInfo;
};

export type HttpClientError = {
  code?: string;
  message: string;
  details?: ErrorDetails[];
};

export type ErrorDetails = {name: string; message: string; value: string; code?: string};

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  public requestAPI: <D>(
    url: string,
    options: AxiosRequestConfig
  ) => TaskEither<ResponseError, ResponseData<D>>;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    config: {securityWorker, secure, format, ...axiosConfig} = {},
    eitherDecorator,
  }: {
    config: ApiConfig<SecurityDataType>;
    eitherDecorator: any;
  }) {
    this.instance = axios.create({...axiosConfig, baseURL: axiosConfig.baseURL || ''});
    this.requestAPI = eitherDecorator(this.instance);

    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <D>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams) => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.requestAPI<D>(path, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? {'Content-Type': type} : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })();
  };
}

/**
 * @title Site Builder API
 * @version 1.0.0
 * @baseUrl http://localhost:8081
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  resetPassword = {
    /**
     * No description
     *
     * @tags reset-password-controller
     * @name ResetPermanentPassword
     * @summary Устанавливает постоянный пароль
     * @request POST:/api/reset-password/set-new
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    resetPermanentPassword: (
      data: PasswordSetRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/reset-password/set-new`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reset-password-controller
     * @name SendResetPasswordOtp
     * @summary Отправляет транспортный пароль для сброса
     * @request POST:/api/reset-password/send-otp
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    sendResetPasswordOtp: (
      data: OtpRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/reset-password/send-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reset-password-controller
     * @name CheckNextOtpRequestTime
     * @summary Проверяет, когда можно отправить смс повторно
     * @request POST:/api/reset-password/otp-time
     * @response `200` `OtpNewRequestTime` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkNextOtpRequestTime: (
      data: OtpRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<OtpNewRequestTime>>> =>
      this.request<OtpNewRequestTime>({
        path: `/api/reset-password/otp-time`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reset-password-controller
     * @name CheckRestPasswordOtp
     * @summary Проверяет транспортный пароль для регистрации и авторизует пользователя
     * @request POST:/api/reset-password/check-otp
     * @response `200` `AuthResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkRestPasswordOtp: (
      data: OtpCheckRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<AuthResponse>>> =>
      this.request<AuthResponse>({
        path: `/api/reset-password/check-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  register = {
    /**
     * No description
     *
     * @tags registration-controller
     * @name SetPermanentPassword
     * @summary Устанавливает постоянный пароль
     * @request POST:/api/register/set-password
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    setPermanentPassword: (
      data: PasswordSetRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/register/set-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration-controller
     * @name SentRegistrationOtp
     * @summary Отправляет транспортный пароль для регистрации
     * @request POST:/api/register/send-otp
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    sentRegistrationOtp: (
      data: OtpRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/register/send-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration-controller
     * @name CheckNextOtpRequestTime1
     * @summary Проверяет, когда можно отправить смс повторно
     * @request POST:/api/register/otp-time
     * @response `200` `OtpNewRequestTime` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkNextOtpRequestTime1: (
      data: OtpRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<OtpNewRequestTime>>> =>
      this.request<OtpNewRequestTime>({
        path: `/api/register/otp-time`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration-controller
     * @name CheckRegistrationOtp
     * @summary Проверяет транспортный пароль для регистрации и авторизует пользователя
     * @request POST:/api/register/check-otp
     * @response `200` `AuthResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkRegistrationOtp: (
      data: OtpCheckRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<AuthResponse>>> =>
      this.request<AuthResponse>({
        path: `/api/register/check-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  publish = {
    /**
     * No description
     *
     * @tags publish-controller
     * @name Publish
     * @summary Публикует выбранную страницу
     * @request POST:/api/publish
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    publish: (
      query: {
        /** @format uuid */
        pageId: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/publish`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags publish-controller
     * @name PublishDomain
     * @summary Публикует все страницы выбранного сайта
     * @request POST:/api/publish/domain
     * @secure
     * @response `200` `(PageInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    publishDomain: (
      query: {
        /** @format uuid */
        domainId: string;
        subdomain?: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo[]>>> =>
      this.request<PageInfo[]>({
        path: `/api/publish/domain`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  public = {
    /**
     * No description
     *
     * @tags public-form-controller
     * @name ProcessForm
     * @summary Получает и сохраняет результат заполнения формы
     * @request POST:/api/public/form/{pageExternalRef}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    processForm: (
      pageExternalRef: string,
      data: ProcessFormRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/public/form/${pageExternalRef}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags profile-controller
     * @name CheckIfUserBlocked
     * @summary Проверяет, заблокирован ли пользователь (регистрация/авторизация/восстановление пароля)
     * @request POST:/api/profile/check-block
     * @response `200` `BlockedUserResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkIfUserBlocked: (
      query: {
        phoneNumber: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<BlockedUserResponse>>> =>
      this.request<BlockedUserResponse>({
        path: `/api/profile/check-block`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile-controller
     * @name UpdateProfile
     * @summary Обновляет информацию о текущем пользователе
     * @request PATCH:/api/profile
     * @secure
     * @response `200` `ProfileInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateProfile: (
      data: UpdateProfileRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ProfileInfo>>> =>
      this.request<ProfileInfo>({
        path: `/api/profile`,
        method: 'PATCH',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile-controller
     * @name GetCurrentProfile
     * @summary Получает информацию о текущем пользователе
     * @request GET:/api/profile/current
     * @secure
     * @response `200` `ProfileInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getCurrentProfile: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ProfileInfo>>> =>
      this.request<ProfileInfo>({
        path: `/api/profile/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  prettyDomain = {
    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name ListPrettyDomains
     * @summary Возвращает список красивых доменов пользователя
     * @request GET:/api/pretty-domain
     * @secure
     * @response `200` `(PrettyDomainInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listPrettyDomains: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PrettyDomainInfo[]>>> =>
      this.request<PrettyDomainInfo[]>({
        path: `/api/pretty-domain`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name CreatePrettyDomain
     * @summary Предварительно создает и блокирует указанный красивый домен за пользователем
     * @request POST:/api/pretty-domain
     * @secure
     * @response `200` `PrettyDomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createPrettyDomain: (
      data: CreatePrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PrettyDomainInfo>>> =>
      this.request<PrettyDomainInfo>({
        path: `/api/pretty-domain`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name DelegatePrettyDomain
     * @summary Окончательно регистрирует домен на пользователя
     * @request POST:/api/pretty-domain/delegate
     * @secure
     * @response `200` `PrettyDomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    delegatePrettyDomain: (
      data: DelegatePrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PrettyDomainInfo>>> =>
      this.request<PrettyDomainInfo>({
        path: `/api/pretty-domain/delegate`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name DeassignPrettyDomain
     * @summary Отключает красивый домен от указанного сайт
     * @request POST:/api/pretty-domain/deassign
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deassignPrettyDomain: (
      data: DeassignPrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/pretty-domain/deassign`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name CheckPrettyDomain
     * @summary Возвращает информацию - свободен ли указанный домен
     * @request POST:/api/pretty-domain/check
     * @secure
     * @response `200` `CheckDomainResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkPrettyDomain: (
      data: CheckPrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<CheckDomainResponse>>> =>
      this.request<CheckDomainResponse>({
        path: `/api/pretty-domain/check`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name CheckLocalPrettyDomain
     * @summary Возвращает информацию - свободен ли указанный домен
     * @request POST:/api/pretty-domain/check-local
     * @secure
     * @response `200` `CheckLocalDomainResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkLocalPrettyDomain: (
      data: CheckPrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<CheckLocalDomainResponse>>> =>
      this.request<CheckLocalDomainResponse>({
        path: `/api/pretty-domain/check-local`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name AssignPrettyDomain
     * @summary Назначает красивый домен на указанный сайт
     * @request POST:/api/pretty-domain/assign
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    assignPrettyDomain: (
      data: AssignPrettyDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/pretty-domain/assign`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name PurchaseDomainInfo
     * @summary Возвращает информацию о стоимости покупаемого домена
     * @request GET:/api/pretty-domain/price-info/{domain}
     * @secure
     * @response `200` `PurchaseDomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    purchaseDomainInfo: (
      domain: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PurchaseDomainInfo>>> =>
      this.request<PurchaseDomainInfo>({
        path: `/api/pretty-domain/price-info/${domain}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pretty-domains-controller
     * @name DeletePrettyDomain
     * @summary Удаляет красивый домен
     * @request DELETE:/api/pretty-domain/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deletePrettyDomain: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/pretty-domain/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  page = {
    /**
     * No description
     *
     * @tags pages-controller
     * @name CreatePage
     * @summary Создает новую страницу сайта
     * @request POST:/api/page
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createPage: (
      data: CreatePageRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name AddBlockToPage
     * @summary Добавляет блок к указанной странице
     * @request POST:/api/page/{pageId}/block
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    addBlockToPage: (
      pageId: string,
      data:
        | AchievementsBlockConfiguration
        | ButtonBlockConfiguration
        | CallToActionBlockConfiguration
        | CardsBlockConfiguration
        | ContactsBlockConfiguration
        | ContactsFormBlockConfiguration
        | FirstScreenBlockConfiguration
        | FooterBlockConfiguration
        | GalleryBlockConfiguration
        | HeaderBlockConfiguration
        | HtmlBlockConfiguration
        | PartnersBlockConfiguration
        | ProductsAndPricesBlockConfiguration
        | ReviewsBlockConfiguration
        | SeparatorsBlockConfiguration
        | SocialNetworksBlockConfiguration
        | TariffsBlockConfiguration
        | TeamBlockConfiguration
        | TextBlockConfiguration
        | VideoBlockConfiguration,
      query?: {
        /**
         * @format int32
         * @min 0
         */
        order?: number;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${pageId}/block`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name DragAndDropBlock
     * @summary Изменяет порядок блоков на странице - переносит выбранный блок на новое место
     * @request POST:/api/page/{pageId}/block/{blockId}/drag-and-drop/{newOrder}
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    dragAndDropBlock: (
      pageId: string,
      blockId: string,
      newOrder: number,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${pageId}/block/${blockId}/drag-and-drop/${newOrder}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name MakePageMain
     * @summary Устанавливает выбранную страницу сайта как главную
     * @request POST:/api/page/{id}/main
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    makePageMain: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${id}/main`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name UpdateBlock
     * @summary Редактирует выбранный блок
     * @request PATCH:/api/page/{pageId}/block/{blockId}
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateBlock: (
      pageId: string,
      blockId: string,
      data: UpdateBlockRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${pageId}/block/${blockId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name GetPage
     * @summary Возращает информацию по странице сайта
     * @request GET:/api/page/{id}
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getPage: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name DeletePage
     * @summary Удаляет выбранную страницу сайта
     * @request DELETE:/api/page/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deletePage: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/page/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name UpdatePage
     * @summary Редактирует выбранную страницу
     * @request PATCH:/api/page/{id}
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updatePage: (
      id: string,
      data: UpdatePageRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name ListPages
     * @summary Возращает список страниц сайта
     * @request GET:/api/page/list
     * @secure
     * @response `200` `(PageListInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listPages: (
      query: {
        /** @format uuid */
        domainId: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageListInfo[]>>> =>
      this.request<PageListInfo[]>({
        path: `/api/page/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages-controller
     * @name RemoveBlockFromPage
     * @summary Удаляет блок с указанной страницы
     * @request DELETE:/api/page/{pageId}/block/{id}
     * @secure
     * @response `200` `PageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    removeBlockFromPage: (
      pageId: string,
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageInfo>>> =>
      this.request<PageInfo>({
        path: `/api/page/${pageId}/block/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  image = {
    /**
     * No description
     *
     * @tags images-controller
     * @name CreateImage
     * @summary Загружает новое изображение на сервер
     * @request POST:/api/image
     * @secure
     * @response `200` `ImageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createImage: (
      data: {
        /** @format binary */
        imageInfo: File;
        /** @format binary */
        image: File;
        /** @format binary */
        croppedImage: File;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ImageInfo>>> =>
      this.request<ImageInfo>({
        path: `/api/image`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags images-controller
     * @name GetImage
     * @summary Возвращает информацию по выбранному изображению
     * @request GET:/api/image/{id}
     * @secure
     * @response `200` `ImageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getImage: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ImageInfo>>> =>
      this.request<ImageInfo>({
        path: `/api/image/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags images-controller
     * @name DeleteImage
     * @summary Удаляет выбранное изображение
     * @request DELETE:/api/image/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deleteImage: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/image/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags images-controller
     * @name UpdateImage
     * @summary Редактирует выбранное изображение
     * @request PATCH:/api/image/{id}
     * @secure
     * @response `200` `ImageInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateImage: (
      id: string,
      data: {
        /** @format binary */
        imageInfo: File;
        /** @format binary */
        image: File;
        /** @format binary */
        croppedImage: File;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ImageInfo>>> =>
      this.request<ImageInfo>({
        path: `/api/image/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags images-controller
     * @name LoadImage
     * @summary Загружает выбранное изображение
     * @request GET:/api/image/load/{externalRef}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    loadImage: (
      externalRef: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/image/load/${externalRef}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  form = {
    /**
     * No description
     *
     * @tags form-result-controller
     * @name CreateFormResult
     * @summary Добавляет заявку вручную
     * @request POST:/api/form
     * @secure
     * @response `200` `FormResultInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createFormResult: (
      data: CreateFormRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FormResultInfo>>> =>
      this.request<FormResultInfo>({
        path: `/api/form`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-result-controller
     * @name ListFormResults
     * @summary Возвращает список ответов на форму
     * @request POST:/api/form/list-results
     * @secure
     * @response `200` `PageFormResultInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listFormResults: (
      data: ListFormRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PageFormResultInfo>>> =>
      this.request<PageFormResultInfo>({
        path: `/api/form/list-results`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-result-controller
     * @name ExportToXls
     * @summary Выгружает указанные строки (либо все заявки) в формате XLS
     * @request POST:/api/form/export
     * @secure
     * @response `200` `FormResultInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    exportToXls: (
      data: ExportXLSRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FormResultInfo>>> =>
      this.request<FormResultInfo>({
        path: `/api/form/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-result-controller
     * @name ChangeStatus
     * @summary Изменить статус заявки
     * @request POST:/api/form/change-status
     * @secure
     * @response `200` `(FormResultInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    changeStatus: (
      data: ChangeFormStatusRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FormResultInfo[]>>> =>
      this.request<FormResultInfo[]>({
        path: `/api/form/change-status`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-result-controller
     * @name DeleteFormResult
     * @summary Удаляет заявку
     * @request DELETE:/api/form/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deleteFormResult: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/form/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-result-controller
     * @name UpdateFormResult
     * @summary Обновляет заявку вручную
     * @request PATCH:/api/form/{id}
     * @secure
     * @response `200` `FormResultInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateFormResult: (
      id: string,
      data: UpdateFormRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FormResultInfo>>> =>
      this.request<FormResultInfo>({
        path: `/api/form/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  elements = {
    /**
     * No description
     *
     * @tags general-elements-controller
     * @name ListPalettes
     * @summary Возвращает список доступных палитр
     * @request GET:/api/elements/palettes
     * @secure
     * @response `200` `(PaletteInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listPalettes: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PaletteInfo[]>>> =>
      this.request<PaletteInfo[]>({
        path: `/api/elements/palettes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags general-elements-controller
     * @name CreatePalette
     * @summary Создает новую палитру
     * @request POST:/api/elements/palettes
     * @secure
     * @response `200` `PaletteInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createPalette: (
      data: CreatePaletteRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PaletteInfo>>> =>
      this.request<PaletteInfo>({
        path: `/api/elements/palettes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags general-elements-controller
     * @name ListFonts
     * @summary Возвращает список всех шрифтов
     * @request GET:/api/elements/fonts
     * @secure
     * @response `200` `(FontInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listFonts: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FontInfo[]>>> =>
      this.request<FontInfo[]>({
        path: `/api/elements/fonts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags general-elements-controller
     * @name ListDefaultFonts
     * @summary Возвращает список предустановленных шрифтов
     * @request GET:/api/elements/fonts/default
     * @secure
     * @response `200` `(FontInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listDefaultFonts: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FontInfo[]>>> =>
      this.request<FontInfo[]>({
        path: `/api/elements/fonts/default`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags general-elements-controller
     * @name ListButtonTypes
     * @summary Возвращает список доступных типов кнопок
     * @request GET:/api/elements/button-types
     * @secure
     * @response `200` `(ButtonTypeInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listButtonTypes: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ButtonTypeInfo[]>>> =>
      this.request<ButtonTypeInfo[]>({
        path: `/api/elements/button-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags general-elements-controller
     * @name DeletePalette
     * @summary Удаляет выбранную палитру
     * @request DELETE:/api/elements/palettes/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deletePalette: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/elements/palettes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  domain = {
    /**
     * No description
     *
     * @tags domains-controller
     * @name CreateDomain
     * @summary Создает новый домен
     * @request POST:/api/domain
     * @secure
     * @response `200` `DomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createDomain: (
      data: CreateDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainInfo>>> =>
      this.request<DomainInfo>({
        path: `/api/domain`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook-controller
     * @name AddWebhook
     * @summary Создает новый webhook
     * @request POST:/api/domain/{domainId}/webhook
     * @secure
     * @response `200` `WebhookInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    addWebhook: (
      domainId: string,
      data: WebhookRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<WebhookInfo>>> =>
      this.request<WebhookInfo>({
        path: `/api/domain/${domainId}/webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name CreateFromTemplate
     * @summary Создает новый сайт по указанному шаблону
     * @request POST:/api/domain/create-from-template/{domainTemplateId}
     * @secure
     * @response `200` `DomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    createFromTemplate: (
      domainTemplateId: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainInfo>>> =>
      this.request<DomainInfo>({
        path: `/api/domain/create-from-template/${domainTemplateId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name GetDomain
     * @summary Возвращает информацию по выбранному домену
     * @request GET:/api/domain/{id}
     * @secure
     * @response `200` `DomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getDomain: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainInfo>>> =>
      this.request<DomainInfo>({
        path: `/api/domain/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name DeleteDomain
     * @summary Удаляет домен
     * @request DELETE:/api/domain/{id}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deleteDomain: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/domain/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name UpdateDomain
     * @summary Обновляет домен
     * @request PATCH:/api/domain/{id}
     * @secure
     * @response `200` `DomainInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateDomain: (
      id: string,
      data: UpdateDomainRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainInfo>>> =>
      this.request<DomainInfo>({
        path: `/api/domain/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook-controller
     * @name GetWebhook
     * @summary Возвращает вебхук
     * @request GET:/api/domain/{domainId}/webhook/{webhookId}
     * @secure
     * @response `200` `WebhookInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getWebhook: (
      domainId: string,
      webhookId: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<WebhookInfo>>> =>
      this.request<WebhookInfo>({
        path: `/api/domain/${domainId}/webhook/${webhookId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook-controller
     * @name DeleteWebhook
     * @summary Удаляет webhook
     * @request DELETE:/api/domain/{domainId}/webhook/{webhookId}
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    deleteWebhook: (
      domainId: string,
      webhookId: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/domain/${domainId}/webhook/${webhookId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook-controller
     * @name UpdateWebhook
     * @summary Редактирует существующий webhook
     * @request PATCH:/api/domain/{domainId}/webhook/{webhookId}
     * @secure
     * @response `200` `WebhookInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    updateWebhook: (
      domainId: string,
      webhookId: string,
      data: WebhookRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<WebhookInfo>>> =>
      this.request<WebhookInfo>({
        path: `/api/domain/${domainId}/webhook/${webhookId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name GetDomainCss
     * @summary Возвращает информацию по CSS выбранного домена
     * @request GET:/api/domain/{id}/css
     * @secure
     * @response `200` `DomainCssInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getDomainCss: (
      id: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainCssInfo>>> =>
      this.request<DomainCssInfo>({
        path: `/api/domain/${id}/css`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook-controller
     * @name ListWebhooks
     * @summary Возвращает список вебхуков сайта
     * @request GET:/api/domain/{domainId}/webhook/list
     * @secure
     * @response `200` `(WebhookInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listWebhooks: (
      domainId: string,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<WebhookInfo[]>>> =>
      this.request<WebhookInfo[]>({
        path: `/api/domain/${domainId}/webhook/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name ListDomains
     * @summary Возвращает список доменов
     * @request GET:/api/domain/list
     * @secure
     * @response `200` `(DomainInfo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listDomains: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DomainInfo[]>>> =>
      this.request<DomainInfo[]>({
        path: `/api/domain/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domains-controller
     * @name CheckSubdomain
     * @summary Проверяет - доступен ли поддомен
     * @request GET:/api/domain/check-subdomain
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkSubdomain: (
      query: {
        subdomain: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/domain/check-subdomain`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  checklist = {
    /**
     * No description
     *
     * @tags checklist-controller
     * @name MarkPoint
     * @summary Отмечает действие чек-листа как выполненное
     * @request POST:/api/checklist/{action}/mark
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    markPoint: (
      action:
        | 'ADD_LOGO'
        | 'CHANGE_SITE_HEADER'
        | 'SET_DOMAIN_NAME'
        | 'ADD_CONTACTS'
        | 'ADD_FORM'
        | 'ADD_FONT'
        | 'ADD_PALETTE'
        | 'CHECK_MOBILE_VERSION'
        | 'ADD_SEO_TEXT'
        | 'ADD_COOKIE_BANNER'
        | 'ADD_ANALYTICS'
        | 'PUBLISH',
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/checklist/${action}/mark`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags checklist-controller
     * @name ListPoints
     * @summary Возвращает список всех доступных действий чек-листа
     * @request GET:/api/checklist/details
     * @secure
     * @response `200` `ChecklistPointsInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    listPoints: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ChecklistPointsInfo>>> =>
      this.request<ChecklistPointsInfo>({
        path: `/api/checklist/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  billing = {
    /**
     * No description
     *
     * @tags billing-controller
     * @name TurnSubscription
     * @summary Переключает подписку (устанавливает бесплатный тариф следующим)
     * @request POST:/api/billing/switch-subscription
     * @secure
     * @response `200` `BillingStatus` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    turnSubscription: (
      query: {
        tarStatus: 'ON' | 'OFF';
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<BillingStatus>>> =>
      this.request<BillingStatus>({
        path: `/api/billing/switch-subscription`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-controller
     * @name SetTariff
     * @summary Устанавливает новый тарифный план (услугу в терминах биллинга)
     * @request POST:/api/billing/set-tariff
     * @secure
     * @response `200` `BillingStatus` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    setTariff: (
      query: {
        tariffCode: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<BillingStatus>>> =>
      this.request<BillingStatus>({
        path: `/api/billing/set-tariff`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-controller
     * @name FinishCardAttachment
     * @summary Завершает привязку карты (устанавливает статус клиента в локальной БД в правильное состояние (дублируется асинхронной задачей)
     * @request POST:/api/billing/card/finish
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    finishCardAttachment: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/billing/card/finish`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-controller
     * @name GetStatus
     * @summary Возвращает текущий биллинговый статус клиента
     * @request GET:/api/billing/status
     * @secure
     * @response `200` `BillingStatus` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getStatus: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<BillingStatus>>> =>
      this.request<BillingStatus>({
        path: `/api/billing/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-controller
     * @name StartTransaction
     * @summary Формирует cookie с одноразовым токеном для корректной привязки карты
     * @request GET:/api/billing/start-transaction
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    startTransaction: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/billing/start-transaction`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth-controller
     * @name SetVipPassword
     * @summary Устанавливает постоянный пароль VIP пользователю
     * @request POST:/api/auth/vip/set-password
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    setVipPassword: (
      data: PasswordUpdateRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/auth/vip/set-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name Renew
     * @summary Обновляет accessToken
     * @request POST:/api/auth/renew
     * @secure
     * @response `200` `AuthResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    renew: (
      data: RenewRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<AuthResponse>>> =>
      this.request<AuthResponse>({
        path: `/api/auth/renew`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name Login
     * @summary Авторизует пользователя
     * @request POST:/api/auth/login
     * @response `200` `AuthResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    login: (
      data: AuthRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<AuthResponse>>> =>
      this.request<AuthResponse>({
        path: `/api/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name AuthByCrm
     * @summary Обменивает authorization_code на token, авторизует клиента
     * @request POST:/api/auth/crm
     * @secure
     * @response `200` `AuthResponse` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    authByCrm: (
      data: CodeAuthRequest,
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<AuthResponse>>> =>
      this.request<AuthResponse>({
        path: `/api/auth/crm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags logout-controller
     * @name Logout
     * @request GET:/api/auth/logout
     * @secure
     * @response `200` `void` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    logout: (
      query: {
        refreshToken: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<void>>> =>
      this.request<void>({
        path: `/api/auth/logout`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name GetCrmConfig
     * @summary Возвращает настройки для конфигурации виджета логина через CRM
     * @request GET:/api/auth/crm/configuration
     * @secure
     * @response `200` `CrmAuthConfiguration` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getCrmConfig: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<CrmAuthConfiguration>>> =>
      this.request<CrmAuthConfiguration>({
        path: `/api/auth/crm/configuration`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name CheckAuth
     * @summary Проверяет авторизован ли пользователь
     * @request GET:/api/auth/check
     * @secure
     * @response `200` `ProfileInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    checkAuth: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<ProfileInfo>>> =>
      this.request<ProfileInfo>({
        path: `/api/auth/check`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  preview = {
    /**
     * No description
     *
     * @tags preview-controller
     * @name Preview
     * @summary Отображает страницы в предварительном виде
     * @request GET:/api/preview
     * @secure
     * @response `200` `string` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    preview: (
      query: {
        /** @format uuid */
        pageId: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<string>>> =>
      this.request<string>({
        path: `/api/preview`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  imageBank = {
    /**
     * No description
     *
     * @tags image-bank-controller
     * @name SearchPhotos
     * @summary Возвращает список фотографий по запросу
     * @request GET:/api/image-bank/search
     * @secure
     * @response `200` `(Photo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    searchPhotos: (
      query: {
        query: string;
        /** @default "10" */
        perPage?: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<Photo[]>>> =>
      this.request<Photo[]>({
        path: `/api/image-bank/search`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags image-bank-controller
     * @name GetRandomPhoto
     * @summary Возвращает случайное фото из фотобанка
     * @request GET:/api/image-bank/random
     * @secure
     * @response `200` `Photo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getRandomPhoto: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<Photo>>> =>
      this.request<Photo>({
        path: `/api/image-bank/random`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags image-bank-controller
     * @name GetCollections
     * @summary Возвращает список коллекций фотографий
     * @request GET:/api/image-bank/collections
     * @secure
     * @response `200` `(PhotoCollection)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getCollections: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<PhotoCollection[]>>> =>
      this.request<PhotoCollection[]>({
        path: `/api/image-bank/collections`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags image-bank-controller
     * @name GetCollectionPhotos
     * @summary Возвращает спиок фотографий из указанной коллекции
     * @request GET:/api/image-bank/collections/{collectionId}/photos
     * @secure
     * @response `200` `(Photo)[]` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    getCollectionPhotos: (
      collectionId: string,
      query?: {
        /** @default "10" */
        perPage?: string;
      },
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<Photo[]>>> =>
      this.request<Photo[]>({
        path: `/api/image-bank/collections/${collectionId}/photos`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  features = {
    /**
     * No description
     *
     * @tags feature-flags-controller
     * @name AllFeaturesFlags
     * @summary Возвращает все feature flags
     * @request GET:/api/features
     * @secure
     * @response `200` `FeaturesFlagsInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    allFeaturesFlags: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<FeaturesFlagsInfo>>> =>
      this.request<FeaturesFlagsInfo>({
        path: `/api/features`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  config = {
    /**
     * No description
     *
     * @tags config-controller
     * @name DefaultConfig
     * @summary Возвращает настройки по умолчанию
     * @request GET:/api/config
     * @secure
     * @response `200` `DefaultConfigInfo` OK
     * @response `500` `ErrorInfo` Internal Server Error
     */
    defaultConfig: (
      params: RequestParams = {}
    ): ReturnType<TaskEither<ResponseError, ResponseData<DefaultConfigInfo>>> =>
      this.request<DefaultConfigInfo>({
        path: `/api/config`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
