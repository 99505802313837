import tw from 'tailwind-styled-components';
import styled from 'styled-components';

interface Props {
  position: 'relative' | 'absolute';
}

const StyledDropdown = styled.div<Props>`
  --placement: bottom-right;
`;

export const Dropdown = tw(StyledDropdown)<Props>`
    ${props => props.position}
    hs-dropdown
    inline-flex
`;

export const DropdownContent = tw.div`
    hs-dropdown-menu
    transition-[opacity,margin]
    duration-[0.1ms]
    hs-dropdown-open:opacity-100
    opacity-0
    w-72
    hidden
    z-10
    mt-2
    min-w-[15rem]
    bg-white
    rounded
    drop-shadow-lg
    border-gray-200
    border
    p-2
`;

export const DropdownItem = tw.div`
    flex
    items-center
    gap-x-3.5
    py-2
    px-3
    rounded-md
    text-sm
    text-gray-800
    font-mulish-medium
    focus:ring-2
    focus:ring-blue-500
    cursor-pointer
    hover:bg-gray-100
`;
