import {EyeFillIcon, EyeSlashIcon, GearIcon} from '@shared/images/icons';
import {FC} from 'react';
import {VisibleItemProps} from './model';
import {fold} from 'fp-ts/boolean';
import {NavPill} from '@shared/navPill/navPill';
import clsx from 'clsx';

export const VisibleBullet: FC<VisibleItemProps> = ({
  onSettings,
  onVisible,
  onClick,
  item,
  visible,
  children,
  invalid,
}) => {
  const disabled = !visible;
  return (
    <NavPill
      disabled={disabled}
      endIcon={
        <>
          <GearIcon
            onClick={() => !disabled && onSettings(item)}
            className="w-5 h-5 ml-3 -mr-1 text-gray-800"
          />
          {fold(
            () => <EyeSlashIcon onClick={() => onVisible(item)} className="w-5 h-5 ml-3 -mr-1" />,
            () => <EyeFillIcon onClick={() => onVisible(item)} className="w-5 h-5 ml-3 -mr-1" />
          )(visible)}
        </>
      }
      onClick={() => onClick(item)}
    >
      <div className={clsx(invalid && 'text-red-500')}>{children}</div>
    </NavPill>
  );
};
