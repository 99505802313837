import * as yup from 'yup';
import {FirstScreenBlockConfiguration} from '@shared/api';
import {formSchema} from '../../elements/formElement/schema';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {pointSchema} from '../../elements/pointsElement/schema';
import {imageElementSchema} from '../../elements/imageElement/schema';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';

export const firstScreenBlockConfigurationContentSchema = yup.object({
  title: titleSchema.optional().nullable().default(undefined),
  description: descriptionSchema.optional().nullable().default(undefined),
  pointsIconStyle: yup
    .string<Exclude<FirstScreenBlockConfiguration['pointsIconStyle'], undefined>>()
    .nullable(),
  form: formSchema.optional().nullable().default(undefined),
  primaryButton: buttonInfoConfigurationSchema.optional().nullable().default(undefined),
  secondaryButton: buttonInfoConfigurationSchema.optional().nullable().default(undefined),
  image: imageElementSchema.optional().nullable().default(undefined),
  points: yup.array(pointSchema).nullable().max(10, 'Максимальное кол-во пунктов - 10'),
});
