import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {TextElement} from '../../elements/textElement';
import {GalleryElement} from '../../elements/galleryElement';
import {ButtonElement} from '../../elements/buttonElement';
import {SeoElement} from '../../elements/seoElement';

import {BlockConfigurationContentProps} from '../../model/interface';

import {GalleryBlockConfiguration} from '@shared/api/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';

import {galleryBlockConfigurationSchema} from './schema';

export const GalleryBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<GalleryBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<GalleryBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<Partial<GalleryBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(galleryBlockConfigurationSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <TextElement
        label="Заголовок"
        fieldName="title"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Описание"
        fieldName="description"
        control={control}
        configuration={configuration}
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors?.images)}
          summary="Фотогалерея"
          details={<GalleryElement control={control} configuration={configuration} />}
        />

        <AccordionItem
          invalid={Boolean(errors?.button)}
          summary="Кнопка"
          details={
            <ButtonElement fieldName="button" control={control} configuration={configuration} />
          }
        />

        <AccordionItem
          invalid={Boolean(errors.titleTag)}
          summary="SEO"
          details={<SeoElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
