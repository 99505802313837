import React, {FC, InputHTMLAttributes, ReactNode} from 'react';
import {Label} from './styles/label';
import {DropZoneBlock} from './styles/dropZoneBlock';
import {IconContainer} from './styles/iconContainer';
import {ReactComponent as ImageIcon} from '../../images/icons/Image.svg';
import {Description} from './styles/description';
import {Paragraph} from '../typography';
import {ImagePreview} from '../../imagePreview/imagePreview';
import {Button} from '../button';
import {ArrowRepeatIcon, CropIcon} from '../../images/icons';

interface UploadPreviewProps
  extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onRemove: () => void;
  onClick: () => void;
  onChangeImage: () => void;
  onCropImage: () => void;
  label?: string;
  description?: string | ReactNode;
  helperText?: string | ReactNode;
  image: string | undefined;
}

export const UploadPreview: FC<UploadPreviewProps> = ({
  onCropImage,
  onChangeImage,
  onRemove,
  image,
  onClick,
  label,
  name,
  helperText = '',
  description = 'Выбрать изображение',
}) => {
  const content = image ? (
    <>
      <ImagePreview onClick={onClick} image={image} onRemove={onRemove} />
      <div className="flex justify-between">
        <Button onClick={onChangeImage} color="indigo" variant="link" size="default">
          <ArrowRepeatIcon />
          Заменить
        </Button>
        <Button onClick={onCropImage} color="indigo" variant="link" size="default">
          <CropIcon />
          Обрезать
        </Button>
      </div>
    </>
  ) : (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div onClick={onClick}>
        <DropZoneBlock>
          <IconContainer>
            <ImageIcon />
          </IconContainer>
          <Description className="font-mulish-semibold text-indigo-500">{description}</Description>
          <Paragraph className="text-center">{helperText}</Paragraph>
        </DropZoneBlock>
      </div>
    </>
  );
  return content;
};
