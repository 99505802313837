import React, {useEffect, useRef, useState} from 'react';
import Cropper, {ReactCropperElement} from 'react-cropper';

import CropperJS from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import {ImageCropProps} from './model';

export const ImageCrop: React.FC<ImageCropProps> = ({imageFile, onChange}) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      const cb = () => {
        setImageDataUrl(reader.result as string);
      };
      reader.addEventListener('load', cb);
      return () => {
        reader.removeEventListener('load', cb);
      };
    }
  }, [imageFile]);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: CropperJS = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();

    canvas?.toBlob(
      (blob: Blob | null) => {
        if (blob) {
          const data = cropper.getData();
          const {x: cropX, y: cropY, width: cropWidth, height: cropHeight} = data;
          const blobUrl = URL.createObjectURL(blob);
          const croppedImage = {blobUrl, blob};
          const croppedRect = {
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight,
          };
          onChange({croppedImage, croppedRect});
        }
      },
      imageFile?.type,
      0.9
    );
  };

  return (
    <div className="flex justify-center">
      <Cropper
        key={imageDataUrl}
        src={imageDataUrl}
        ref={cropperRef}
        minContainerHeight={460}
        minContainerWidth={934}
        zoomable
        movable={false}
        cropend={onCrop}
      />
    </div>
  );
};
