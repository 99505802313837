import {DATA_SB_ID_ATTR} from './constants';
import {ReactNode} from 'react';
import {TPublishType} from '@widgets/adminHeaderContent/hooks/interface';

export const getElementByDataSbIdAttr = (sbAttrId: string) => {
  return document.querySelector(`[${DATA_SB_ID_ATTR}=${sbAttrId}]`);
};

export function getMimeTypeFromArrayBuffer(arrayBuffer: ArrayBuffer) {
  const uint8arr = new Uint8Array(arrayBuffer);

  const len = 4;
  if (uint8arr.length >= len) {
    let signatureArr = Array.from({length: len});
    for (let i = 0; i < len; i++) {
      signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16);
    }
    const signature = signatureArr.join('').toUpperCase();

    switch (signature) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
        return 'image/jpeg';
      case '504B0304':
        return 'application/zip';
      default:
        return '';
    }
  }
  return '';
}

const replacer: Record<string, string> = {
  й: 'y',
  ц: 'c',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sh',
  з: 'z',
  х: 'h',
  ъ: '',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'a',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: '',
  б: 'b',
  ю: 'u',
  ' ': '-',
};

export function cyrillicToLatin(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (replacer[str[i].toLowerCase()] !== undefined) {
      let replace = '';
      if (str[i] === str[i].toLowerCase()) {
        replace = replacer[str[i].toLowerCase()];
      } else if (str[i] === str[i].toUpperCase()) {
        replace = replacer[str[i].toLowerCase()].toUpperCase();
      }

      str = str.replace(str[i], replace).toLowerCase();
    }
  }

  return str;
}

export const getDomainPublishHref = (
  defaultDomain: string,
  pageHash?: string,
  prettyDomain?: string,
  subdomain?: string,
  publishType?: TPublishType,
  activePagePath?: string
): {
  title: string;
  href: string;
} => {
  const subdomainHref = `https://${subdomain}.${defaultDomain}`;
  const isPretty = Boolean(prettyDomain);
  const prettyHref = `https://${prettyDomain}`;

  const siteHref = isPretty ? prettyHref : subdomainHref;
  const publishTextLink = publishType === 'site' ? siteHref : siteHref + activePagePath;

  const publishItemHref = `https://${defaultDomain}/view?hash=${pageHash}`;

  return {
    title: publishTextLink,
    href: publishItemHref,
  };
};

export const getDomainHrefBySubdomain = (defaultDomain?: string, subdomain?: string): string => {
  return `https://${subdomain}.${defaultDomain}`;
};
export const getDomainHrefByDomain = (domain: string): string => {
  return `https://${domain}`;
};
export const foldBooleanTrue = (onTrue: () => ReactNode) => (value: boolean) => {
  if (value) {
    return onTrue();
  }
  return null;
};

export const waitForTimeout = (timeoutMs: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, timeoutMs);
  });
};
