import * as yup from 'yup';
import {textValueSchema} from '../../elements/textElement/textValueSchema';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {faqInfoSchema} from '../../configuration/faqInfoConfiguration/schema';

export const MAX_COUNT_DROPDOWN_ITEMS = 12;

export const textBlockConfigurationContentSchema = yup.object({
  textValue: textValueSchema.nullable().optional().default(undefined),
  title: titleSchema.nullable().optional().default(undefined),
  dropDownItems: yup
    .array(faqInfoSchema)
    .max(MAX_COUNT_DROPDOWN_ITEMS, `Максимальное количество карточек - ${MAX_COUNT_DROPDOWN_ITEMS}`)
    .nullable()
    .default(null),
});
