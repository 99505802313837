import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Field} from '../../ui/field';
import {ElementWithName} from '../../model/interface';
import {Switch} from '@shared/ui/switch';

export const SwitchElement: FC<ElementWithName> = ({control, configuration, fieldName, label}) => {
  return (
    <Field
      node={
        <Controller
          name={fieldName}
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Switch
              className="mb-6"
              invalid={invalid}
              error={error?.message}
              label={label}
              value={value}
              onChange={onChange}
            />
          )}
        />
      }
      configuration={configuration}
      field={fieldName}
    />
  );
};
