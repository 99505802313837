import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';

import {some} from 'fp-ts/Option';
import {fromArray} from 'fp-ts/NonEmptyArray';

import {dataOf, errorOf, initialOf, loadingOf} from 'iled/dist/constructors';

import {BlockType} from '@builder/sberlead-sbuild-templates';

import {BlockInfo, HtmlBlockConfiguration, PageListInfo, UpdatePageRequest} from '@shared/api/api';
import {httpClient} from '@shared/apiClient/lib/axios';
import {PageListState, TBlockImageMap, TBlockMap} from '../model/interface';
import {
  fromILED,
  fromOption,
  getBlockImagePreviewPath,
  needPublishMessage,
} from '../../../shared/lib/utils';
import {useSnackbarStoreBase} from '../../snackbar/store/snackbar';

export const usePageListStoreBase = create<PageListState>()(
  devtools((set, get) => ({
    blocksImagePathMap: {},
    blocksMap: {
      TEXT: {
        description: '',
        group: 'TEXT',
        items: [],
        name: '',
      },
    },
    activeDomainId: '',
    activePage: initialOf(null),
    activeEditPage: {
      createdDate: '',
      id: '',
      isMain: false,
      name: '',
      path: '',
      publishedDate: '',
      status: 'DRAFT',
      hash: '',
    },
    pages: initialOf(null),
    blocks: [],
    effects: {
      publishPage: async (pageId: string) => {
        const result = await httpClient.publish.publish({pageId});
        if (result._tag === 'Left') {
          set({
            activePage: errorOf(`Error while .publishPage pageId=${pageId}`),
          });
        }
      },
      publishActivePage: async () => {
        const activePageId = fromOption(fromILED(get().activePage))?.id;
        if (activePageId) {
          await get().effects.publishPage(activePageId);
          const result = await httpClient.publish.publish({pageId: activePageId});
          if (result._tag === 'Left') {
            set({
              activePage: errorOf(`Error while .publishActivePage pageId=${activePageId}`),
            });
          }
        }
      },
      updateHtmlBlock: async (pageId, blockId, htmlContent) => {
        const configuration: HtmlBlockConfiguration = {
          html: htmlContent,
          group: 'HTML',
          type: 'HTML1',
        };
        const result = await httpClient.page.updateBlock(pageId, blockId, {
          configuration,
        });
        if (result._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while .updateHtmlBlock at page pageId=${pageId} blockId=${blockId}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(result.right.data))});
      },
      updateBlock: async (pageId, blockId, configuration) => {
        const result = await httpClient.page.updateBlock(pageId, blockId, {
          configuration,
        });
        if (result._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while .updateBlock at page pageId=${pageId} blockId=${blockId}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(result.right.data))});
      },
      removeBlockFromPage: async (pageId: string, blockId: string) => {
        set({activePage: loadingOf(null)});
        const result = await httpClient.page.removeBlockFromPage(pageId, blockId);
        if (result._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while remove Block from Page blockId=${blockId} pageId=${pageId}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(result.right.data))});
      },
      dragBlock: async (pageId, blockId, newOrder) => {
        set({activePage: loadingOf(null)});
        const response = await httpClient.page.dragAndDropBlock(pageId, blockId, newOrder);
        if (response._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while drag Block on Page pageId=${pageId} blockId=${blockId} newOrder=${newOrder}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(response.right.data))});
      },
      toggleVisible: async (pageId, blockId, isVisible) => {
        set({activePage: loadingOf(null)});
        const response = await httpClient.page.updateBlock(pageId, blockId, {isVisible});
        if (response._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while toggleVisible Block on Page pageId=${pageId} blockId=${blockId} isVisible=${isVisible}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(response.right.data))});
      },
      copyBlock: async (pageId, block, order) => {
        set({activePage: loadingOf(null)});
        const response = await httpClient.page.addBlockToPage(pageId, block, {order});
        if (response._tag === 'Left') {
          set({
            activePage: errorOf(
              `Error while copyBlock on Page pageId=${pageId} blockConfiguration=${block} order=${order}`
            ),
          });
          return;
        }
        set({activePage: dataOf(some(response.right.data))});
      },
      addBlockToPage: async (pageId, block, position) => {
        set({activePage: loadingOf(null)});
        const query = typeof position === 'number' ? {order: position} : {};
        httpClient.page.addBlockToPage(pageId as string, block, query).then(res => {
          if (res._tag === 'Left') {
            set({activePage: errorOf('Error while receiving Page id=' + pageId)});
            return;
          }
          set({activePage: dataOf(some(res.right.data))});
        });
      },
      fetchPage: async (pageId: string) => {
        set({activePage: loadingOf(null)});
        httpClient.page.getPage(pageId).then((result: any) => {
          if (result._tag === 'Left') {
            set({activePage: errorOf('Error while receiving Page id=' + pageId)});
            return;
          }
          set({activePage: dataOf(some(result.right.data))});
        });
      },
      fetchPages: async (domainId: string) => {
        set({pages: loadingOf(null)});
        httpClient.page.listPages({domainId}).then((result: any) => {
          if (result._tag === 'Left') {
            set({pages: errorOf('Error while receiving PageList')});
            return;
          }
          set({pages: dataOf(fromArray(result.right.data))});
        });
      },
      updatePage: async (activeDomainId, pageId: string, request: UpdatePageRequest) => {
        const response = await httpClient.page.updatePage(pageId, request);
        if (response._tag === 'Left') {
          set({pages: errorOf('Error while update Page id=' + pageId)});
          return;
        }
        useSnackbarStoreBase.getState().enqueue({variant: 'info', message: needPublishMessage});
        get().effects.fetchPages(activeDomainId);
      },
      removePage: async (activeDomainId, pageId: string) => {
        const response = await httpClient.page.deletePage(pageId);
        if (response._tag === 'Left') {
          set({pages: errorOf('Error while delete Page id=' + pageId)});
          return;
        }
        useSnackbarStoreBase.getState().enqueue({variant: 'info', message: needPublishMessage});
        get().effects.fetchPages(activeDomainId);
      },
      addPage: async (activeDomainId, request) => {
        const response = await httpClient.page.createPage(request);
        if (response._tag === 'Left') {
          set({pages: errorOf('Error while add Page path=' + request.path)});
          return;
        }
        useSnackbarStoreBase.getState().enqueue({variant: 'info', message: needPublishMessage});
        get().effects.fetchPages(activeDomainId);
      },
      makePageMain: async (activeDomainId, pageId) => {
        const response = await httpClient.page.makePageMain(pageId);
        if (response._tag === 'Left') {
          set({pages: errorOf('Error while make Page main id=' + pageId)});
          return;
        }
        useSnackbarStoreBase.getState().enqueue({variant: 'info', message: needPublishMessage});
        get().effects.fetchPages(activeDomainId);
      },
    },
    actions: {
      setActiveEditPage: (page: PageListInfo) =>
        set(() => ({
          activeEditPage: page,
        })),
      setPages: pages =>
        set(() => ({
          pages,
        })),
      setBlocks: (blocks: BlockType[]) => {
        const imageMap: TBlockImageMap = {};
        const map: TBlockMap = {};

        blocks.forEach(block => {
          map[block.group] = block;
          block.items.forEach(item => {
            imageMap[block.group] = imageMap[block.group]
              ? {
                  ...imageMap[block.group],
                  [item.block.type]: getBlockImagePreviewPath(block.group, item.block.type),
                }
              : {
                  [item.block.type]: getBlockImagePreviewPath(block.group, item.block.type),
                };
          });
        });
        set(() => ({
          blocks,
          blocksMap: map,
          blocksImagePathMap: imageMap,
        }));
      },
    },
  }))
);

export const usePageListStore = createSelectorHooks(usePageListStoreBase);

export const blockByTypeSelector =
  (group: BlockInfo['group'], type: BlockInfo['type']) => (state: PageListState) =>
    state.blocksMap[group]?.items.find(block => block.block.type === type);

export const blockImageByGroupAndTypeSelector = (
  group: BlockInfo['group'],
  type: BlockInfo['type'],
  imageMap: PageListState['blocksImagePathMap']
) => {
  const groupObject = imageMap[group];
  if (!groupObject) {
    return '';
  }
  return groupObject[type] ?? '';
};

export const activeDomainMainPageSelector = (state: PageListState) => {
  const pages = fromILED(state.pages);
  if (pages?._tag === 'Some') {
    return pages.value.find(page => page.isMain)?.id ?? null;
  }
  return null;
};

export const pageByIdSelector = (pageId: string | undefined) => (state: PageListState) => {
  const pages = fromOption(fromILED(state.pages));
  return pages?.find(pg => pg.id === pageId);
};
