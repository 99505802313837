import * as yup from 'yup';

export const imageElementSchema = yup
  .object({
    id: yup.string().nullable().optional(),
    hash: yup.string().nullable(),
    url: yup.string().nullable().optional(),
    altText: yup.string().nullable().optional(),
  })
  .test('id or url must be present', 'Корректно заполните поле', obj => {
    if (!obj) {
      return true;
    }
    return Boolean(obj.id) || Boolean(obj.url);
  });
