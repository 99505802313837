import React, {FC, InputHTMLAttributes, ReactNode, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

import {ReactComponent as ImageIcon} from '@shared/images/icons/Image.svg';
import {Paragraph} from '../typography';

import {Label} from './styles/label';
import {DropZoneBlock} from './styles/dropZoneBlock';
import {IconContainer} from './styles/iconContainer';
import {Input} from './styles/input';
import {Description} from './styles/description';
import {Accent} from './styles/accent';

interface UploadFileProps
  extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onUpload: (droppedFiles: Array<File>) => void;
  label?: string;
  description?: string | ReactNode;
  helperText?: string;
}

export const UploadFile: FC<UploadFileProps> = ({
  onUpload,
  label,
  name,
  helperText = 'Максимальный вес: 50MB',
  description = (
    <>
      Перетащите файл сюда или <Accent>загрузите с устройства</Accent>
    </>
  ),
  ...rest
}) => {
  const onDrop = useCallback(
    (droppedFiles: Array<File>) => {
      onUpload(droppedFiles);
    },
    [onUpload, name]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
    },
  });

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div {...getRootProps()}>
        <Input {...rest} id={name} {...getInputProps()} />
        <DropZoneBlock active={isDragActive ? 'active' : ''}>
          <IconContainer>
            <ImageIcon />
          </IconContainer>
          <Description>{description}</Description>
          <Paragraph className="text-center">{helperText}</Paragraph>
        </DropZoneBlock>
      </div>
    </>
  );
};
