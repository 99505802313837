import {ChangeEventHandler, FC} from 'react';
import {SwitchInput, TColor} from './ui';
import {Caption} from '..';
import clsx from 'clsx';

interface SwitchProps {
  value?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  invalid?: boolean;
  error?: string;
  className?: string;
  color?: TColor;
  size?: TSize;
}

type TSize = 'xs' | 'base' | 'xl';

export const Switch: FC<SwitchProps> = ({
  value,
  className,
  onChange,
  label,
  invalid,
  error,
  color = 'blue',
  size = 'base',
}) => {
  return (
    <div className={clsx('flex items-center', className)}>
      <SwitchInput
        onChange={onChange}
        className={clsx(`text-${color}-500`, {
          'w-[2.1rem]': size === 'base',
          'h-5': size === 'base',
          'before:w-4': size === 'base',
          'before:h-4': size === 'base',
          'checked:before:translate-x-[90%]': size === 'base',
          'before:translate-y-[-1px]': size === 'base',
        })}
        color={color}
        type="checkbox"
        id="hs-basic-with-description-checked"
        checked={value}
      />
      <label
        htmlFor="hs-basic-with-description-checked"
        className="text-sm text-gray-800 ml-3 dark:text-gray-400"
      >
        {label}
      </label>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
    </div>
  );
};
