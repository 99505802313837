import React, {ReactNode} from 'react';
import {
  CaretRightFillIcon,
  CheckCircleFillIcon,
  CheckLgIcon,
  CircleFillIcon,
  RectangleIcon,
} from '@shared/images/icons';
import {pointIconType} from './model';

export const pointsIconStyleVariant: {icon: ReactNode; type: pointIconType}[] = [
  {
    icon: <CheckLgIcon height={24} className="fill-indigo-500" />,
    type: 'CHECKMARK',
  },
  {
    icon: <CheckCircleFillIcon height={24} className="text-blue-600" />,
    type: 'CHECKMARK_IN_CIRCLE',
  },
  {
    icon: <CaretRightFillIcon height={24} className="fill-indigo-500" />,
    type: 'TRIANGLE',
  },
  {
    icon: <RectangleIcon className="fill-indigo-500 my-[10px]" />,
    type: 'MINUS',
  },
  {
    icon: <CircleFillIcon className="fill-indigo-500 m-[6px]" />,
    type: 'DOT',
  },
];
