import React, {FC} from 'react';
import {fold} from 'fp-ts/Option';
import {PageInfo} from '../../../shared/api';
import {Spinner, SpinnerContainer} from '../../../shared/ui/spinner';
import {FoldILED} from 'iled';
import {usePageListStore} from '../../page/store/pageListStore';

export const ActivePageBlocksOptions: FC = () => {
  const activePage = usePageListStore.useActivePage();
  return (
    <FoldILED
      state={activePage}
      onData={data => {
        return fold(
          () => null,
          (page: PageInfo) => {
            if (page.blocks && page.blocks.length > 0) {
              return (
                <>
                  <option value="">Не выбрано</option>
                  {page.blocks.map(block => (
                    <option key={block.id} value={block.id}>
                      {block.group}
                    </option>
                  ))}
                </>
              );
            }
            return null;
          }
        )(data);
      }}
      onLoading={() => (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      onInitial={() => null}
      onError={() => null}
    />
  );
};
