import {create} from 'zustand';
import {createJSONStorage, devtools, persist} from 'zustand/middleware';
import {none, some} from 'fp-ts/Option';

import {Viewer} from '../index';

import {ViewerState} from '../model/interface';
import {globalNavigate} from '../../../app/global-history';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {useAuthStore} from '../../auth';

export const useViewerStoreBase = create<ViewerState>()(
  devtools(
    persist(
      set => ({
        cookieOverlooked: false,
        viewer: none,
        setCookieOverlooked: () => {
          set({cookieOverlooked: true});
        },
        setViewer: (viewer: Viewer) =>
          set(() => ({
            viewer: some(viewer),
          })),
        removeViewer: () =>
          set(() => ({
            viewer: none,
          })),
        login: () => {
          // TODO: Loading
          // TODO: Make fake request
          // TODO: Call `setViewer`
        },
        logout: () => {
          useAuthStore.getState().clearAuthInfo();
          set(() => ({
            viewer: none,
          }));
          globalNavigate('/');
        },
      }),
      {
        name: 'viewer-store',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

export const useViewerStore = createSelectorHooks(useViewerStoreBase);
