import {DropDownItem} from '@shared/api';
import {Text} from '../../components/text';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {useEffect} from 'react';
import {faqInfoSchema} from './schema';
import {doNotSaveEmptyStringMiddlewareConfiguration} from '@features/blockConfiguration/utils';

interface FaqInfoConfigurationProps {
  item: DropDownItem;
  onSubmit: (item: DropDownItem) => void;
}

export const FaqInfoConfiguration: React.FC<FaqInfoConfigurationProps> = ({item, onSubmit}) => {
  const onValidData: SubmitHandler<DropDownItem> = data => {
    if (onSubmit) {
      onSubmit(doNotSaveEmptyStringMiddlewareConfiguration(data));
    }
  };

  const {handleSubmit, control, watch} = useForm<DropDownItem>({
    mode: 'onChange',
    resolver: yupResolver(faqInfoSchema),
    defaultValues: {
      ...item,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Заголовок"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="value"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Текст"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
    </>
  );
};
