import * as yup from 'yup';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {reviewsElementSchema} from '../../elements/reviewsElement/schema';

export const reviewsBlockConfigurationSchema = yup.object({
  title: titleSchema.nullable().optional().default(undefined),
  description: descriptionSchema.nullable().optional().default(undefined),
  reviews: reviewsElementSchema.nullable().optional().default(undefined),
});
