import {TagType} from '@shared/api';

export type TTitleTag = TagType;
export const titleTags: TTitleTag[] = [
  TagType.H1,
  TagType.H2,
  TagType.H3,
  TagType.H4,
  TagType.H5,
  TagType.DIV,
];
