import tw from 'tailwind-styled-components';
import {DropZoneStyleProps} from '../ui/uploadInput/interface';
import React, {FC} from 'react';
import {ReactComponent as TrashIcon} from '@shared/images/icons/trash.svg';

interface ImagePreviewProps {
  image: string | undefined;
  onRemove: () => void;
  onClick?: () => void;
}

export const PreviewZone = tw.div<DropZoneStyleProps>`
    w-full
    border
    border-dashed
    border-gray-200
    p-10
    mb-6
    relative
`;
export const ImagePreview: FC<ImagePreviewProps> = ({image, onRemove, onClick}) => {
  const onRemoveHandler: React.MouseEventHandler<SVGSVGElement> = e => {
    e.stopPropagation();
    onRemove();
  };
  return (
    <PreviewZone onClick={onClick}>
      <TrashIcon
        onClick={onRemoveHandler}
        width={20}
        height={20}
        className="inline-block absolute right-3 top-3 cursor-pointer"
      />
      <img className="mr-auto ml-auto" src={image} />
    </PreviewZone>
  );
};
