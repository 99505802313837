import React, {FC} from 'react';
import {fold, fromNullable} from 'fp-ts/Option';

import {ButtonInfo} from '@shared/api';
import {Switch, Caption} from '@shared/ui';

import {ButtonConfigurationProps} from './model';
import {ButtonInfoConfiguration} from '../buttonInfoConfiguration';

export const ButtonConfiguration: FC<ButtonConfigurationProps> = ({
  button,
  onChange,
  invalid,
  error,
}) => {
  const isButton = button !== null;

  const changeButtonView = () => {
    if (isButton) {
      onChange(null);
    } else {
      onChange({
        blockId: '',
        href: '#',
        linkType: 'EXTERNAL',
        title: '',
        buttonClass: 'PRIMARY',
      });
    }
  };

  return (
    <>
      <Switch
        className="mb-6"
        label="Отображать кнопку"
        value={isButton}
        onChange={changeButtonView}
      />
      {isButton &&
        fold(
          () => null,
          (data: ButtonInfo) => {
            return (
              <>
                <ButtonInfoConfiguration button={data} onSubmit={onChange} />
                {invalid && error && <Caption color="text-red-500">{error}</Caption>}
              </>
            );
          }
        )(fromNullable(button))}
    </>
  );
};
