import React, {useEffect} from 'react';

import {Image} from '../image/image';
import {LogoProps} from './model';

import {Input} from '../input';
import {CompanyLogoInfo} from '@shared/api/api';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {logoSchema} from '../../elements/logoElement/schema';

export const Logo: React.FC<LogoProps> = ({
  onChangeHandler,
  label = 'Название компании',
  logoInfo,
}) => {
  const onValidData: SubmitHandler<CompanyLogoInfo> = data => {
    onChangeHandler(data);
  };

  const {handleSubmit, control, watch} = useForm<CompanyLogoInfo>({
    mode: 'onChange',
    resolver: yupResolver(logoSchema),
    defaultValues: {
      ...logoInfo,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Controller
        name="name"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            value={value}
            onChange={onChange}
            label={label}
            placeholder={label}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="image"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Image
            onChange={onChange}
            imageBase={value}
            invalid={invalid}
            errorMessage={error?.message}
            hasAltText
          />
        )}
      />
    </>
  );
};
