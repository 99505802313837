import {TextProps} from './model';
import {Bigtext} from '@shared/ui/bigtext';

export const Text: React.FC<TextProps> = ({
  onChange,
  textValue,
  label = 'Текст',
  invalid,
  errorMessage,
}) => {
  const onChangeHandler = (value: string) => {
    if (value === '') {
      return onChange(null);
    }
    onChange(value);
  };
  return (
    <div className="container mb-2">
      <span className="text-sm font-mulish-semibold">{label}</span>
      <Bigtext
        value={textValue}
        onChange={onChangeHandler}
        invalid={invalid}
        errorMessage={errorMessage}
      />
    </div>
  );
};
