import * as yup from 'yup';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {menuItemSchema} from '../../elements/menuElement/schema';
import {logoSchema} from '../../elements/logoElement/schema';
import {networksSchema} from '../../elements/networksElement/schema';
import {phoneNumberSchema} from '../../elements/switchElement/schema';

export const headerBlockConfigurationContentSchema = yup.object({
  fixMenuWhileScrolling: yup.boolean().default(false),
  phoneNumber: phoneNumberSchema.nullable().optional().default(undefined),
  logo: logoSchema.optional().nullable().default(undefined),
  menu: yup.array(menuItemSchema).optional().nullable().max(9, 'Максимум 9 пунктов'),
  button: buttonInfoConfigurationSchema.optional().nullable().default(undefined),
  networks: networksSchema,
});
