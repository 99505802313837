import tw from 'tailwind-styled-components';

export const Button = tw.button`
    hs-tab-active:bg-white 
    hs-tab-active:text-gray-700 

    py-2 
    px-4
    h-9 
    
    items-center 
    gap-2 
    bg-transparent 
    text-sm 
    text-gray-500 
    hover:text-gray-700 
    font-medium 
    rounded-sm
    hover:hover:text-blue-600 
    flex-auto
`;
