import React, {FC} from 'react';
import {Button} from '@shared/ui/button';
import {pointIconType, PointsIconStyleProps} from './model';
import {pointsIconStyleVariant} from './utils';

export const PointsIconStyle: FC<PointsIconStyleProps> = ({onChange, pointsIconStyle}) => {
  const iconStyleHandler = (value: pointIconType) => () => {
    onChange(value);
  };

  return (
    <div className="container mb-6">
      <fieldset className="flex max-w-md flex-col gap-4" id="radio">
        <span className="text-sm font-mulish-semibold">Стили иконок</span>
        <div className="flex items-center gap-4">
          {pointsIconStyleVariant.map(style => (
            <Button
              key={style.type}
              className={`py-2 px-2 ${style.type === pointsIconStyle ? 'active' : ''}`}
              color="blue"
              variant="outline"
              size="default"
              onClick={iconStyleHandler(style.type)}
            >
              {style.icon}
            </Button>
          ))}
        </div>
      </fieldset>
    </div>
  );
};
