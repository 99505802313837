import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';

import {dataOf, errorOf, initialOf, loadingOf} from 'iled/dist/constructors';
import {some} from 'fp-ts/Option';

import {DomainEditState} from '../model/interface';
import {fromILED, fromOption, needPublishMessage} from '../../../shared/lib/utils';
import {DomainInfo, WebhookInfo} from '@shared/api';
import {httpClient} from '@shared/apiClient/lib/axios';
import {useSnackbarStoreBase} from '../../snackbar/store/snackbar';
import {isEqual} from 'lodash-es';
import {useWebhookStore} from '../../webhook';

export const useDomainEditStoreBase = create<DomainEditState>()(
  devtools((set, get) => ({
    domainCss: '',
    editingDomain: {
      configuration: {
        analyticsCodeText: '',
        backgroundType: undefined,
        buttonType: undefined,
        cookieBannerText: '',
        faviconId: '',
        ogMeta: undefined,
        paletteId: '',
        primaryFontId: '',
        seoText: '',
      },
      id: '',
      mainPageId: '',
      mainPageHash: '',
      mapsApiKey: '',
      name: '',
      prettyDomain: '',
      publishedDate: '',
      subdomain: '',
      domainNameChangedDate: '',
    },
    activeDomainId: '',
    activeDomain: initialOf(null),
    prettyActiveDomain: () => {
      const {activeDomain} = get();
      if (activeDomain.type === 'Data' && activeDomain.data._tag === 'Some') {
        return activeDomain.data.value.prettyDomain ?? '';
      }
      return '';
    },
    activeDomainSubdomain: () => {
      const {activeDomain} = get();
      if (activeDomain.type === 'Data' && activeDomain.data._tag === 'Some') {
        return activeDomain.data.value.subdomain;
      }
      return '';
    },
    actions: {
      setEditingDomain: () => {
        const activeDomain = fromOption(fromILED(get().activeDomain));
        if (activeDomain) {
          set({
            editingDomain: activeDomain,
          });
        }
      },
      setActiveDomainChangedConfiguration: config => {
        const {editingDomain} = get();
        const result = {
          ...editingDomain,
          configuration: {...editingDomain.configuration, ...config},
        };
        set({
          editingDomain: result,
        });
      },
      setActiveDomainChange: (config: Partial<DomainInfo>) => {
        const domain = get().editingDomain;
        if (domain) {
          const result = {...domain, ...config};
          set({
            editingDomain: result,
          });
        }
      },
      setActiveDomainId: (activeDomainId: string) => {
        set({
          activeDomainId,
        });
      },
    },
    effects: {
      updateDomain: async isShowSuccess => {
        const activeDomain = get().editingDomain;
        if (!activeDomain) {
          return;
        }
        set({
          activeDomain: loadingOf(null),
        });

        const {subdomain, id, configuration, name} = activeDomain;
        const forms = fromOption(fromILED(useWebhookStore.getState().webhooks));
        const response = await httpClient.domain.updateDomain(id, {
          domain: subdomain,
          name,
          configuration: {...configuration, forms: forms as WebhookInfo[]},
        });
        if (response._tag === 'Left') {
          set({
            activeDomain: errorOf(`Error while update domain id=${id}`),
          });
          return;
        }
        if (isShowSuccess !== undefined && !isShowSuccess) {
          return;
        }
        useSnackbarStoreBase.getState().enqueue({variant: 'info', message: needPublishMessage});
        set({
          activeDomain: dataOf(some(response.right.data)),
        });
      },
      publishDomainById: async (domainId, subdomain) => {
        const {activeDomain} = get();

        set({
          activeDomain: loadingOf(null),
        });
        const response = await httpClient.publish.publishDomain({
          domainId,
          subdomain,
        });
        if (response._tag === 'Left') {
          set({
            activeDomain: errorOf(`Error while .publishDomainById id=${domainId}`),
          });
        }
        if (
          subdomain &&
          response._tag === 'Right' &&
          activeDomain.type === 'Data' &&
          activeDomain.data._tag === 'Some'
        ) {
          set({
            activeDomain: dataOf(some({...activeDomain.data.value, subdomain})),
          });
        }
      },
      publishDomain: async () => {
        const {activeDomain} = get();
        set({
          activeDomain: loadingOf(null),
        });
        if (activeDomain.type === 'Data' && activeDomain.data._tag === 'Some') {
          const {id, subdomain} = activeDomain.data.value;
          const {subdomain: changedSubdomain} = get().editingDomain;
          const nextSubdomain = changedSubdomain || subdomain;
          const response = await httpClient.publish.publishDomain({
            domainId: id,
            subdomain: nextSubdomain,
          });
          if (response._tag === 'Left') {
            set({
              activeDomain: errorOf(
                `Error while .publishDomain id=${id} subdomain=${nextSubdomain}`
              ),
            });
            return false;
          }
          if (response._tag === 'Right') {
            set({
              activeDomain: dataOf(some({...activeDomain.data.value, subdomain: nextSubdomain})),
            });
            return true;
          }
        }
        return false;
      },
      fetchActiveDomain: async (id: string) => {
        set({
          activeDomain: loadingOf(null),
        });
        const response = await httpClient.domain.getDomain(id);
        if (response._tag === 'Left') {
          set({activeDomain: errorOf(`Error while .fetchActiveDomain id=${id}`)});
          return;
        }
        set({
          activeDomain: dataOf(some(response.right.data)),
          editingDomain: response.right.data,
        });
        get().effects.fetchDomainCss(response.right.data.id);
      },
      fetchDomainCss: async (domainId: string) => {
        const response = await httpClient.domain.getDomainCss(domainId);
        if (response._tag === 'Right') {
          set({domainCss: response.right.data.css});
        }
      },
    },
  }))
);

export const useDomainEditStore = createSelectorHooks(useDomainEditStoreBase);

export const useActiveDomain = (state: DomainEditState) => fromOption(fromILED(state.activeDomain));

export const useActiveDomainHref = (state: DomainEditState) => (defaultDomain?: string) => {
  const active = fromOption(fromILED(state.activeDomain));
  if (active) {
    if (active.prettyDomain) {
      return active.prettyDomain;
    }
    return `${defaultDomain}.${active.subdomain}`;
  }
  return '';
};

export const useActiveDomainChanged = (state: DomainEditState) => {
  const activeDomain = fromOption(fromILED(state.activeDomain));
  if (activeDomain) {
    const isEquivalent = isEqual(activeDomain, state.editingDomain);
    return !isEquivalent;
  }
  return false;
};

export const useActiveDomainBackgroundType = (state: DomainEditState) => {
  const activeDomain = fromOption(fromILED(state.activeDomain));
  if (activeDomain) {
    return activeDomain.configuration.backgroundType;
  }
  return null;
};
