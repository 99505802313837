import React, {FC, useState} from 'react';
import {Button} from '@shared/ui';
import {Modal} from 'flowbite-react';
import {ImageCrop} from '@shared/imageCrop/imageCrop';
import {ArrowCounterclockwiseIcon} from '@shared/images/icons';
import {EditImageProps} from '../model/editImage';
import {ImageCropChange} from '@shared/imageCrop/model/imageCrop';

export const ImageEditor: FC<EditImageProps> = ({
  open,
  onClose,
  onChange,
  imageFile,
  croppedImage,
}) => {
  const [initialImage, setInitialImage] = useState<File | null>(() => croppedImage);

  const [image, setImage] = useState<{dataUrl: string; blob: Blob | null}>({
    dataUrl: '',
    blob: null,
  });

  const onSaveHandler = () => {
    if (imageFile) {
      onChange({
        imageFile: new File([image.blob as BlobPart], imageFile?.name, {
          type: imageFile?.type,
        }),
        imageUrl: image.dataUrl,
      });
      onClose();
    }
  };

  const onCropHandler = (props: ImageCropChange) => {
    setImage({dataUrl: props.croppedImage.blobUrl, blob: props.croppedImage.blob});
  };

  const onResetHandler = () => {
    setInitialImage(imageFile);
  };

  React.useEffect(() => {
    if (open && initialImage !== croppedImage) {
      setInitialImage(croppedImage);
    }
  }, [open]);

  return (
    <Modal className="z-[150]" size="5xl" show={open} onClose={onClose}>
      <Modal.Header>Обрезать изображение</Modal.Header>
      <Modal.Body />
      <ImageCrop imageFile={initialImage} onChange={onCropHandler} />
      <Modal.Footer>
        <div className="w-full flex justify-between">
          <Button onClick={onResetHandler} color="gray" variant="link" size="default">
            <span className="text-black text-sm flex gap-2">
              <ArrowCounterclockwiseIcon className="text-[#1F2937]" />
              Вернуться к оригиналу
            </span>
          </Button>
          <Button
            disabled={!image.blob}
            onClick={onSaveHandler}
            color="indigo"
            variant="solid"
            size="default"
          >
            Сохранить
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
