import React from 'react';
import {Caption, CustomPhoneInput, InputContainer} from '@shared/ui';
import {PhoneNumberProps} from './model';

export const PhoneNumber: React.FC<PhoneNumberProps> = ({
  onChange,
  value,
  label = 'Номер телефона',
  invalid,
  error,
}) => {
  const onChangeHandler = (changedValue: string) => {
    if (value === '') {
      return onChange(null);
    }
    onChange(changedValue);
  };
  return (
    <div className="container">
      <span className="text-sm font-mulish-semibold">{label}</span>
      <InputContainer>
        <CustomPhoneInput value={value} onChange={onChangeHandler} country="RU" id="phoneNumber" />
      </InputContainer>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
    </div>
  );
};
