import React, {FC} from 'react';
import {Select} from '@shared/ui';
import {SelectHrefTypeProps} from './model';
import {networkHrefTypeLabelByType, networkMap} from './utils';
import {NetworkItem} from '@shared/api';

export const SelectNetworkHrefType: FC<SelectHrefTypeProps> = ({
  onChange,
  invalid,
  errorMessage,
  value,
  activeNetworkType,
}) => {
  return (
    <Select
      value={value}
      label="Тип"
      onChange={onChange}
      invalid={invalid}
      errorMessage={errorMessage}
    >
      {Object.keys(networkMap[activeNetworkType]?.variant ?? {}).map(type => (
        <option key={type} value={type}>
          {networkHrefTypeLabelByType[type as Exclude<NetworkItem['hrefType'], undefined>]}
        </option>
      ))}
    </Select>
  );
};
