import tw from 'tailwind-styled-components';

export type TColor = 'indigo' | 'blue' | 'gray' | 'red';

export interface SwitchInputProps {
  color: TColor;
}

export const SwitchInput = tw.input<SwitchInputProps>`
    relative
    shrink-0
    bg-gray-100
    checked:bg-none
    checked:bg-${props => props.color}-600
    border-2
    border-gray-300
    rounded-full
    cursor-pointer
    transition-colors
    ease-in-out
    duration-200
    border
    border-transparent
    ring-1
    ring-transparent
    ring-offset-white
    focus:ring-0
    focus:ring-offset-0
    focus:outline-none
    focus:shadow-none
    appearance-none
    dark:bg-gray-700
    dark:checked:bg-${props => props.color}-600
    dark:focus:ring-offset-gray-800
    before:inline-block
    before:bg-white
    checked:before:bg-${props => props.color}-200
    before:translate-x-0
    before:shadow
    before:rounded-full
    before:transform
    before:ring-0
    before:transition
    before:ease-in-out
    before:duration-200
    dark:before:bg-gray-400
    dark:checked:before:bg-${props => props.color}-200
`;
