import {Api} from '../../../api';
import {useAuthStore} from '@entities/auth';
import {InternalAxiosRequestConfig} from 'axios';
import {useViewerStore} from '@entities/viewer/store/viewer';
import {globalNavigate} from '../../../../app/global-history';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {httpClient} from './axiosClient';

export const withAuthChecking = (api: Api<any>): Api<any> => {
  const refreshAuthLogic = async (): Promise<any> => {
    const {setAuthInfo, authInfo} = useAuthStore.getState();
    const {setViewer, logout} = useViewerStore.getState();
    if (authInfo) {
      const {refreshToken, profileId} = authInfo;
      if (refreshToken) {
        const response = await httpClient.auth.renew({refreshToken, profileId});
        if (response._tag === 'Left') {
          return logout();
        }
        setAuthInfo(response.right.data);
        setViewer({
          name: {
            firstName: response.right.data?.login,
            lastName: '',
            middleName: '',
          },
          phone: response.right.data?.login,
        });
        if (window.location.pathname === '/') {
          return globalNavigate('/admin');
        }
        return globalNavigate(0);
      }
    }
    logout();
  };

  createAuthRefreshInterceptor(api.instance, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true,
    statusCodes: [401],
  });

  const prerequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.url?.match(/renew/)) {
      return config;
    }
    const token = useAuthStore.getState().authInfo?.accessToken;
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  };

  api.instance.interceptors.request.use(prerequest);

  return api;
};
