import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Field} from '../../ui/field';
import {ElementComponent} from '../../model/interface';
import {PhoneNumber} from '../../components/phoneNumber';

export const PhoneNumberElement: FC<ElementComponent> = ({control, configuration}) => {
  return (
    <Field
      node={
        <Controller
          name="phoneNumber"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <PhoneNumber
              onChange={onChange}
              value={value}
              invalid={invalid}
              error={error?.message}
            />
          )}
        />
      }
      configuration={configuration}
      field="phoneNumber"
    />
  );
};
