import {TextBlockConfiguration} from '@shared/api/api';
import {SubmitHandler} from 'react-hook-form';
import {useBlockContentForm} from '../../hooks/useBlockContentForm';
import {textBlockConfigurationContentSchema} from './schema';
import {TextElement} from '../../elements/textElement';
import {SeoElement} from '../../elements/seoElement';
import {FaqElement} from '../../elements/faqElement';

import {Accordion, AccordionItem} from '@shared/ui/accordion';

interface TextBlockConfigurationContentProps {
  configuration: TextBlockConfiguration;
  onChangeHandler: (key: string, value: unknown) => void;
  onSubmit: (configuration: Partial<TextBlockConfiguration>) => void;
}

export const TextBlockConfigurationContent: React.FC<TextBlockConfigurationContentProps> = ({
  configuration,
  onSubmit,
}) => {
  const onValidData: SubmitHandler<Partial<TextBlockConfiguration>> = data => {
    onSubmit(data);
  };
  const {control} = useBlockContentForm({
    schema: textBlockConfigurationContentSchema,
    onSubmitSuccess: onValidData,
    defaultValues: {
      title: configuration.title,
      textValue: configuration.textValue,
      dropDownItems: configuration.dropDownItems,
    },
  });
  return (
    <>
      <TextElement
        configuration={configuration}
        control={control}
        fieldName="title"
        label="Заголовок"
      />
      <TextElement
        configuration={configuration}
        control={control}
        fieldName="textValue"
        label="Текст"
      />
      <Accordion>
        <AccordionItem
          summary="Раскрывающиеся пункты"
          details={<FaqElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          summary="SEO"
          details={<SeoElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
