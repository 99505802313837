import React, {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Field, FieldByTypeAndGroup} from '../../ui/field';
import {SeoElementProps} from './model/interface';
import {Select} from '@shared/ui';
import {titleTags} from '@entities/blockContentConfiguration/elements/seoElement/utils';
import {TagType} from '@shared/api';

export const SeoElement: FC<SeoElementProps> = ({
  control,
  onSubtitleChange,
  configuration,
  hasSubtitle = false,
  itemsName,
}) => {
  const handleSetAllFields = (tag: TagType) => {
    if (onSubtitleChange) {
      onSubtitleChange(tag);
    }
  };

  return (
    <>
      <Field
        node={
          <Controller
            name="titleTag"
            control={control}
            render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
              <Select
                value={value}
                label="Тег для заголовка"
                onChange={onChange}
                invalid={invalid}
                errorMessage={error?.message}
              >
                {titleTags.map(tag => (
                  <option key={value} value={tag}>
                    {tag}
                  </option>
                ))}
              </Select>
            )}
          />
        }
        configuration={configuration}
        field="titleTag"
      />

      {hasSubtitle && (
        <FieldByTypeAndGroup
          field={`${itemsName}[0].titleTag`}
          blockType={configuration.type}
          blockGroup={configuration.group}
          node={
            <Controller
              name={`${itemsName}[0].titleTag`}
              control={control}
              render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
                <Select
                  value={value}
                  label="Тег для подзаголовка"
                  onChange={event => {
                    onChange(event);
                    handleSetAllFields(event.target.value as TagType);
                  }}
                  invalid={invalid}
                  errorMessage={error?.message}
                >
                  {titleTags.map(tag => (
                    <option key={value} value={tag}>
                      {tag}
                    </option>
                  ))}
                </Select>
              )}
            />
          }
        />
      )}
    </>
  );
};
