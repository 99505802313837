import {FC, useState} from 'react';
import {Caption} from '..';

export interface BigtextProps {
  value: any;
  onChange: (val: string) => void;
  invalid?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
}

export const Bigtext: FC<BigtextProps> = ({onBlur, value, onChange, errorMessage, invalid}) => {
  const [val] = useState(value);

  return (
    <div>
      <div
        onBlur={onBlur}
        className="border border-gray-200 mt-2 text-sm font-mulish-medium py-3 px-4"
        contentEditable
        onInput={event => {
          onChange(event.currentTarget.innerHTML);
        }}
        dangerouslySetInnerHTML={{__html: val}}
      />
      {invalid && errorMessage && <Caption color="text-red-500">{errorMessage}</Caption>}
    </div>
  );
};
