import {Api} from '../../../api/api';
import {withTaskEither} from './withTaskEither';
import {withAuthChecking} from './withAuthChecking';

const api = new Api({
  config: {
    baseURL: '',
  },
  eitherDecorator: withTaskEither,
});

export const httpClient = withAuthChecking(api);
