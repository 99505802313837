import * as yup from 'yup';
import {formFieldSchema} from '../../configuration/formFieldConfiguration/schema';

export const formSchema = yup.object({
  webhooks: yup.array(yup.string()).optional().nullable(),
  formId: yup.string().max(25, 'Максимальная длина 25 символов').required('Обязательное поле'),
  fields: yup.array(formFieldSchema).required(),
  buttonText: yup
    .string()
    .max(60, 'Максимальная длина - 60 символов')
    .required('Обязательное поле'),
});
