import tw from 'tailwind-styled-components';

export interface NavPillProps {
  round?: 'none' | 'small' | 'full';
  active?: boolean;
}

const round = {
  none: 'rounded-none',
  small: 'rounded-sm',
  full: 'rounded-full',
};

export const StyledNavPill = tw.button<NavPillProps>`
    leading-4.5
    transition-all
    
    text-sm
    text-gray-800
    
    py-3
    px-3
    
    border
    border-transparent
    
    font-mulish-semibold
    
    ring-offset-white
    
    rounded
    bg-gray-200
    disabled:bg-gray-100
    hover:ring-2
    hover:ring-offset-0
    hover:ring-indigo-500
    hover:bg-indigo-50
    hover:text-indigo-500
        
    ${(props: NavPillProps) => round[props.round ?? 'small']}
    ${(props: NavPillProps) =>
      props.active &&
      `
        ring-2
        ring-offset-0
        ring-indigo-500
        bg-indigo-50
        text-indigo-500
        shadow-3xl
        shadow-indigo-200
    `}
    
    focus:ring-2
    focus:ring-offset-0
    focus:ring-indigo-500
    focus:bg-indigo-50
    focus:text-indigo-500
    focus:shadow-3xl
    focus:shadow-indigo-200
    
    w-full
    inline-flex
    justify-center
    items-center
    gap-2
`;
