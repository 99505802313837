import {ILED} from 'iled';
import {Option} from 'fp-ts/Option';

export const ResizeObserverErrorCallback = (e: ErrorEvent) => {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message === 'ResizeObserver loop completed with undelivered notifications.'
  ) {
    const resizeObserverErrDiv = document.querySelector('#webpack-dev-server-client-overlay-div');
    const resizeObserverErr = document.querySelector('#webpack-dev-server-client-overlay');
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
};

window.addEventListener('error', ResizeObserverErrorCallback);

export const needPublishMessage =
  'Настройки сохранены, переопубликуйте все страницы, чтобы увидеть изменения на сайте';

export const getBlockImagePreviewPath = (group: string, type: string) => {
  return require(
    '@builder/sberlead-sbuild-templates/blocks/' +
      group.toLowerCase().replace('_', '') +
      '/' +
      type.toLowerCase().replace('_', '') +
      '/index.png'
  );
};

export const fromILED = <T>(item: ILED<unknown, unknown, unknown, T>) => {
  if (item.type === 'Data') {
    return item.data as T;
  }
  return null;
};

export const fromOption = <T>(item: Option<T> | null) => {
  if (!item) {
    return null;
  }
  if (item._tag === 'Some') {
    return item.value as T;
  }
  return null;
};

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.append(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    // eslint-disable-next-line no-console
    console.log('Fallback: Copying text command was ' + msg);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Fallback: Oops, unable to copy', error);
  }

  textArea.remove();
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-console
      console.log('Async: Copying to clipboard was successful!');
    },
    error => {
      // eslint-disable-next-line no-console
      console.error('Async: Could not copy text:', error);
    }
  );
}

export const getPreviewUrl = (pageId: string | null | undefined) => {
  const url = new URL(window.location.origin);
  url.pathname = 'api/preview';
  url.searchParams.append('pageId', pageId ?? '');
  return url.toString();
};

export const htmlReplaceRegex = /(<([^>]+)>)|(;nbsp)/gi;
