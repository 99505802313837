export const doNotSaveEmptyStringMiddleware = (value: unknown) => {
  if (value === '') {
    return undefined;
  }
  return value;
};

export const doNotSaveEmptyStringMiddlewareConfiguration = (
  configuration: Record<string, any>
): object => {
  for (let key in configuration) {
    if (configuration[key] === '' || configuration[key] === '<br>') {
      configuration[key] = undefined;
    }
  }
  return configuration;
};
