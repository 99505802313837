import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {TextElement} from '../../elements/textElement';
import {ImageElement} from '../../elements/imageElement';
import {FormElement} from '../../elements/formElement';
import {SeoElement} from '../../elements/seoElement';

import {ContactsFormBlockConfiguration} from '@shared/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';
import {contactsFormBlockConfigurationSchema} from './schema';

import {BlockConfigurationContentProps} from '../../model/interface';
import {NetworksElement} from '../../elements/networksElement';

export const ContactsFormBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<ContactsFormBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<ContactsFormBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    trigger,
    handleSubmit,
    formState: {errors},
    control,
    watch,
  } = useForm<Partial<ContactsFormBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(contactsFormBlockConfigurationSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <TextElement
        label="Надпись над заголовком"
        fieldName="subtitle"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Заголовок"
        fieldName="title"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Описание"
        fieldName="description"
        control={control}
        configuration={configuration}
      />

      <TextElement
        label="Адрес электронной почты"
        fieldName="email"
        control={control}
        configuration={configuration}
      />

      <TextElement
        label="Номер телефона"
        fieldName="phoneNumber"
        control={control}
        configuration={configuration}
      />

      <TextElement
        label="Адрес местонахождения"
        fieldName="address"
        control={control}
        configuration={configuration}
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors.image)}
          summary="Изображение"
          details={<ImageElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.form)}
          summary="Форма"
          details={<FormElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.networks)}
          summary="Ссылки на соцсети и мессенджеры"
          details={<NetworksElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.titleTag)}
          summary="SEO"
          details={<SeoElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
