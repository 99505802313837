import {useConfigStore} from '@entities/config/store';

export const useBillingFrontendUrl = (returnPath: string, failPath: string) => {
  const {billingFrontEndUrl} = useConfigStore.useConfiguration();

  try {
    const url = new URL(billingFrontEndUrl);
    const params = new URLSearchParams(url.searchParams);
    const returnUrl = new URL(params.get('returnUrl') ?? '');
    const failUrl = new URL(params.get('failUrl') ?? '');
    returnUrl.pathname = returnPath;
    failUrl.pathname = failPath;
    params.set('returnUrl', returnUrl.href.toString());
    params.set('failUrl', failUrl.href.toString());
    url.search = decodeURIComponent(params.toString());

    return url.toString();
  } catch (error) {
    return billingFrontEndUrl;
  }
};
