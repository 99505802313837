import {forwardRef} from 'react';

import {Caption} from '..';

import {SelectProps} from './interface';
import {SelectContainer} from './ui/selectContainer';
import {Label} from './ui/label';
import {CustomSelect} from './ui/select';
import {SelectWrapper} from './ui/selectWrapper';
import {IconWrapper} from './ui/iconWrapper';

export const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    value,
    onChange,
    placeholder,
    className,
    label,
    caption,
    name,
    errorMessage,
    invalid = false,
    icon,
    ...rest
  } = props;

  return (
    <SelectContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <SelectWrapper>
        <CustomSelect
          id={name}
          onChange={onChange}
          value={value}
          className={className}
          ref={ref}
          invalid={invalid}
          {...rest}
        >
          {props.children}
        </CustomSelect>
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </SelectWrapper>
      {caption && <Caption>{caption}</Caption>}
      {invalid && errorMessage && <Caption color="text-red-500">{errorMessage}</Caption>}
    </SelectContainer>
  );
});
