import tw from 'tailwind-styled-components';

export const Input = tw.input`
    shadow 
    appearance-none 
    border 
    rounded 
    w-full 
    py-2 
    px-3 
    text-gray-700 
    leading-tight 
    focus:outline-none 
    focus:shadow-outline
`;
