import {BlockInfo} from '@shared/api';
import {TBlockMap} from '../../page/model/interface';
import {has} from 'lodash-es';
import * as yup from 'yup';
import {removeNbsp} from '../../../shared/utils/yup';

export const isFieldShown =
  <T extends {group: BlockInfo['group']; type: BlockInfo['type']}>(
    blocksMap: TBlockMap,
    configuration: T
  ) =>
  (field: string): boolean => {
    const blockGroup = configuration.group;
    const blockType = configuration.type;
    const defaultBlock = blocksMap[blockGroup]?.items.find(block => block.block.type === blockType);
    if (defaultBlock) {
      return has(defaultBlock.block, field);
    }
    return false;
  };

export const isFieldShownNew =
  (blockGroup: BlockInfo['group'], blockType: BlockInfo['type'], blocksMap: TBlockMap) =>
  (field: string): boolean => {
    const defaultBlock = blocksMap[blockGroup]?.items.find(block => block.block.type === blockType);
    if (defaultBlock) {
      return has(defaultBlock.block, field);
    }
    return false;
  };

export const productPricesDescriptionSchema = yup
  .string()
  .transform(removeNbsp)
  .textValueMax(500, 'Максимальная длина 500 символов');

export const productPricesTitleSchema = yup
  .string()
  .transform(removeNbsp)
  .textValueMax(250, 'Максимальная длина 250 символов');
