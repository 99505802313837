import React, {ReactElement} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuthStore} from '@entities/auth';

interface Props {
  isAllowed: boolean;
  redirectPath: string;
  children?: ReactElement;
}

export const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const authInfo = useAuthStore.useAuthInfo();
  if (!props.isAllowed && authInfo) {
    return <Navigate to={props.redirectPath} replace />;
  }

  return props.children ?? <Outlet />;
};
