export const REMOVE_MODAL_CLOSE_BUTTON = 'remove-modal-close-button';
export const REMOVE_MODAL_BODY = 'remove-modal-body';
export const REMOVE_MODAL_CONFIRM_BUTTON = 'remove-modal-confirm-button';
export const REMOVE_MODAL_CANCEL_BUTTON = 'remove-modal-cancel-button';
export const REMOVE_MODAL_TITLE = 'remove-modal-title';
export const REMOVE_MODAL_DESCRIPTION = 'remove-modal-description';
export const BLOCK_EDITOR_MODAL_CLOSE_BUTTON = 'block-editor-modal-close-button';

export const MODAL_CLOSE_BUTTON = 'modal-close-button';
export const MODAL_BODY = 'modal-body';
export const MODAL_CONFIRM_BUTTON = 'modal-confirm-button';
export const MODAL_CANCEL_BUTTON = 'modal-cancel-button';
export const MODAL_TITLE = 'modal-title';
export const MODAL_DESCRIPTION = 'modal-description';

export const LOGIN_BUTTON = 'login-button';
export const LOGIN_CRM_BUTTON = 'login-crm-button';
export const SHOW_LOGIN_FORM = 'show-login-form';
export const SHOW_REGISTRATION_FORM = 'show-registration-form';
export const SHOW_LOGIN_FORM_MOBILE = 'show-login-form-mobile';

export const ADMIN_HEADER_MENU_ITEM = 'admin-header-menu-item';

export const ADMIN_HEADER_CONTAINER = 'admin-header-container';
export const ADMIN_BODY_CONTAINER = 'admin-body-container';

export const ADMIN_DOMAIN_CARD = 'admin-domain-card';
export const ADMIN_DOMAIN_CREATE_BUTTON = 'admin-domain-create';
export const ADMIN_DOMAIN_BUY_BUTTON = 'admin-domain-buy';
export const ADMIN_DOMAIN_BUY_CARD = 'admin-domain-buy-card';

export const BUY_DOMAIN_TITLE = 'buy-domain-title';
export const BUY_DOMAIN_DESCRIPTION = 'buy-domain-description';
export const BUY_DOMAIN_SELECT_SITE = 'buy-domain-select-site';
export const BUY_DOMAIN_CHECK_INPUT = 'buy-domain-check-input';
export const BUY_DOMAIN_SUGGEST_CONTAINER = 'buy-domain-suggest-container';
export const BUY_DOMAIN_CHECK_ACTION = 'buy-domain-check-action';
export const DOMAIN_BULLET_SUCCESS = 'domain-bullet-success';
export const DOMAIN_BULLET_ERROR = 'domain-bullet-error';
export const DOMAIN_BULLET_WARNING = 'domain-bullet-warning';
export const DOMAIN_BULLET_PRICE = 'domain-bullet-price';

export const ACTION_BAR_TITLE = 'action-bar-title';
export const ACTION_BAR_BUTTON = 'action-bar-button';

export const SIDEBAR_LIBRARY_BUTTON = 'sidebar-library-button';
export const SIDEBAR_DESIGN_BUTTON = 'sidebar-design-button';
export const SIDEBAR_PAGE_SETTINGS_BUTTON = 'sidebar-page-settings-button';
export const SIDEBAR_SITE_SETTINGS_BUTTON = 'sidebar-site-settings-button';
export const SIDEBAR_ADD_PAGE_BUTTON = 'sidebar-add-page-button';
export const SIDEBAR_CLOSE_PAGE_SETTINGS_BUTTON = 'sidebar-close-page-settings-button';
export const SIDEBAR_BUTTON_ELEMENT = 'sidebar-button-element';
export const SIDEBAR_BUTTON_ELEMENT_PAGE_FIELD = 'sidebar-button-element-page-field';

export const EDITOR_BLOCK_EDIT_BUTTON = 'editor-block-edit-button';
