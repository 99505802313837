import tw from 'tailwind-styled-components';

interface IconWrapperProps {
  placement: 'right' | 'left';
}
export const IconWrapper = tw.div<IconWrapperProps>`
		${props => (props.placement === 'left' ? 'left-0' : 'right-0')}
    absolute
    inset-y-0
    flex
    items-center
    pointer
    px-3
`;
