import {Button} from '@shared/ui/button';
import {SettingsContainerProps} from './model';
import {StyledSettingsContainer} from './ui';
import {ChevronLeftIcon} from '@shared/images/icons';

export const SettingsContainer: React.FC<SettingsContainerProps> = ({
  backHandler,
  backLabel,
  children,
}) => {
  return (
    <StyledSettingsContainer>
      <div>
        <Button className="mb-6" color="blue" variant="link" onClick={backHandler} size="default">
          <ChevronLeftIcon />
          {backLabel}
        </Button>
      </div>
      {children}
    </StyledSettingsContainer>
  );
};
