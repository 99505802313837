import tw from 'tailwind-styled-components';
import {Props} from './interface';

export const H1XL = tw.h1<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-semibold
    text-4xl
    leading-[44px]
    mb-4
`;

export const H1 = tw.h1<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-semibold
    text-3xl
    mb-4
`;

export const H2 = tw.h2<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-bold
    text-lg
    mb-4
`;

export const H3 = tw.h3<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-bold
    text-base
    mb-4
`;

export const H4 = tw.h4<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-bold
    text-sm
    mb-2
`;

export const Paragraph = tw.p<Props>`
    ${props => props.color || 'text-gray-500'}
    font-mulish-regular
    text-sm
    mb-4
    font-normal
`;

export const XL2 = tw.h2<Props>`
    ${props => props.color || 'text-gray-800'}
    font-mulish-bold
    text-2xl
    mb-4
`;
