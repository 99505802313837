import tw from 'tailwind-styled-components';

export interface ButtonProps {
  variant: 'solid' | 'outline' | 'ghost' | 'soft' | 'white' | 'link';
  size?: 'small' | 'default' | 'large';
  round?: 'none' | 'small' | 'full';
  color: 'indigo' | 'blue' | 'gray' | 'red' | 'amber';
}

const size = {
  small: `
        py-2
        px-3
    `,
  default: `
        py-3
        px-4
    `,
  large: `
        p-5
    `,
};

const round = {
  none: 'rounded-none',
  small: 'rounded-sm',
  full: 'rounded-full',
};

const colorByVariant = (props: ButtonProps) => {
  const map: Record<ButtonProps['variant'], string> = {
    solid: `
            bg-${props.color}-500
            hover:bg-${props.color}-600
            focus:ring-${props.color}-500
        `,
    outline: `
            text-${props.color}-800
            bg-white
            hover:bg-${props.color}-50
            hover:border-${props.color}-500
            focus:ring-${props.color}-500
            focus:bg-white
        `,
    ghost: `
            text-${props.color}-500
            hover:bg-${props.color}-100
            focus:ring-${props.color}-100
            focus:bg-white
        `,
    soft: `
            bg-${props.color}-100
            text-${props.color}-500
            hover:bg-${props.color}-500
            focus:ring-${props.color}-500
        `,
    white: `

            focus:ring-${props.color}-600
        `,
    link: `
            text-${props.color}-500
            hover:text-${props.color}-700
            focus:ring-${props.color}-500
        `,
  };

  return map[props.variant];
};

const variant = {
  solid: `
        border
        border-transparent
        font-mulish-semibold
        text-white
    `,
  outline: `
        border-2
        border-gray-200
        font-mulish-semibold
    `,
  ghost: `
        border
        border-transparent
        font-mulish-semibold
    `,
  soft: `
        border
        border-transparent
        font-mulish-semibold
        hover
        ring-offset-white
    `,
  white: `
        border
        font-medium
        bg-white
        text-indigo-500
        shadow-sm
        align-middle
        hover:bg-indigo-200
        focus:ring-offset-white
        [&.active]:ring-offset-white
    `,
  link: `
        border
        border-transparent
        font-mulish-semibold
        ring-offset-white
    `,
};

export const Button = tw.button<ButtonProps>`
    text-sm
    ${(props: ButtonProps) => size[props.size ?? 'default']}
    ${(props: ButtonProps) => round[props.round ?? 'small']}
    ${(props: ButtonProps) => colorByVariant(props)}
    ${(props: ButtonProps) => variant[props.variant]}

    inline-flex
    justify-center
    items-center
    gap-2
    leading-4.5
    transition-all
    font-mulish-semibold
    disabled:opacity-50

    focus:outline-none
    focus:ring-2
    focus:ring-offset-0

    [&.active]:outline-none
    [&.active]:ring-2
    [&.active]:ring-offset-0
`;
