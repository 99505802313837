import React, {FC, useEffect} from 'react';
import {Input} from '../../components/input';
import {Select} from '@shared/ui';
import {FormFieldInfo} from '@shared/api';
import {FormFieldConfigurationProps} from './model';
import {Switch} from '@shared/ui';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {formFieldSchema} from './schema';

export const FormFieldConfiguration: FC<FormFieldConfigurationProps> = ({
  field,
  onChange: onChangeHandler,
}) => {
  const onValidData: SubmitHandler<FormFieldInfo> = data => {
    onChangeHandler(data);
  };

  const {handleSubmit, trigger, control, watch} = useForm<FormFieldInfo>({
    mode: 'onChange',
    resolver: yupResolver(formFieldSchema),
    defaultValues: {
      ...field,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Controller
        name="fieldType"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Select
            disabled
            value={value}
            label="Тип"
            onChange={onChange}
            invalid={invalid}
            errorMessage={error?.message}
          >
            <option value="TEXT"> Текстовое поле</option>
            <option value="TELEPHONE"> Телефон</option>
            <option value="EMAIL"> Email</option>
          </Select>
        )}
      />

      <Controller
        name="fieldLabel"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            disabled
            value={value}
            label="Название поля"
            onChange={onChange}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="placeholder"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Подсказка для пользователя в поле"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="required"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Switch
            className="mb-6"
            label="Обязательное поле"
            value={value}
            onChange={onChange}
            invalid={invalid}
            error={error?.message}
          />
        )}
      />
    </>
  );
};
