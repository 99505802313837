import tw from 'tailwind-styled-components';

import {DropZoneStyleProps} from '../interface';

export const DropZoneBlock = tw.div<DropZoneStyleProps>`
    w-full
    border
    border-dashed
    border-gray-200
    p-10
    mb-6
    cursor-pointer
    ${props => (props.active === 'active' ? 'bg-indigo-50 border-indigo-500' : 'bg-white')}
`;
