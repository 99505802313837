import {object, ObjectSchema, string} from 'yup';
import {TSaveWebhookForm} from '../model/interface';

export const schema: ObjectSchema<Omit<TSaveWebhookForm, 'name'> & {name?: string | null}> = object(
  {
    url: string()
      .required('Данное поле обязательно для заполнения')
      .url('Введите корректный адрес', {forceWithProtocol: true}),
    name: string()
      .optional()
      .max(50, 'Введенное имя не должно превышать ${max} символов')
      .nullable(),
  }
);
