import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {SelectNetwork} from './selectNetwork';
import {Input} from '../input';
import {SelectNetworkHrefType} from './selectNetworkHrefType';
import React, {FC, useEffect} from 'react';
import {NetworkItem} from '@shared/api';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {networkItemSchema} from './schema';
import {getIsRenderHrefType, getPlaceholderByNetworkType, showNetworkHrefGui} from './utils';

interface NetworkConfigurationProps {
  activeItem: NetworkItem;
  onSubmit: SubmitHandler<NetworkItem>;
}

export const NetworkConfiguration: FC<NetworkConfigurationProps> = ({activeItem, onSubmit}) => {
  const {handleSubmit, control, watch, trigger, reset, getValues} = useForm<NetworkItem>({
    mode: 'onChange',
    resolver: yupResolver(networkItemSchema),
    defaultValues: {
      ...activeItem,
      hrefType: activeItem.hrefType ?? 'LINK',
      href: showNetworkHrefGui(activeItem),
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch((e, b) => {
      handleSubmit(onSubmit)();
      if (b.type === 'change') {
        if (b.name === 'hrefType') {
          reset({...e, href: ''}, {keepErrors: false});
        }
        if (b.name === 'networkType') {
          reset({...e, href: '', hrefType: 'LINK'}, {keepErrors: false});
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const formHrefType = getValues('hrefType');
  const formNetworkType = getValues('networkType');

  const isRenderHrefType = getIsRenderHrefType(formNetworkType);
  const activeItemPlaceHolder = getPlaceholderByNetworkType(formNetworkType, formHrefType);

  return (
    <>
      <Controller
        name="networkType"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <SelectNetwork
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      {isRenderHrefType && (
        <Controller
          name="hrefType"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <SelectNetworkHrefType
              activeNetworkType={formNetworkType}
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      <Controller
        name="href"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Ссылка"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
            placeholder={activeItemPlaceHolder}
          />
        )}
      />
    </>
  );
};
