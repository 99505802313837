import {FC, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {PhoneNumberElement} from '../../elements/phoneNumberElement';
import {SwitchElement} from '../../elements/switchElement';
import {LogoElement} from '../../elements/logoElement';
import {MenuElement} from '../../elements/menuElement';
import {ButtonElement} from '../../elements/buttonElement';
import {NetworksElement} from '../../elements/networksElement';

import {HeaderBlockConfiguration} from '@shared/api/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';

import {BlockConfigurationContentProps} from '../../model/interface';

import {headerBlockConfigurationContentSchema} from './schema';

export const HeaderBlockConfigurationContent: FC<
  BlockConfigurationContentProps<HeaderBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<HeaderBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    trigger,
  } = useForm<Partial<HeaderBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(headerBlockConfigurationContentSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <SwitchElement
        configuration={configuration}
        control={control}
        label="Фиксировать меню при скролле"
        fieldName="fixMenuWhileScrolling"
      />
      <PhoneNumberElement control={control} configuration={configuration} />
      <Accordion>
        <AccordionItem
          invalid={Boolean(errors.logo)}
          summary="Логотип"
          details={<LogoElement control={control} configuration={configuration} />}
        />

        <AccordionItem
          invalid={Boolean(errors.menu)}
          summary="Пункты меню"
          details={<MenuElement control={control} configuration={configuration} />}
        />

        <AccordionItem
          invalid={Boolean(errors.button)}
          summary="Кнопка"
          details={
            <ButtonElement fieldName="button" control={control} configuration={configuration} />
          }
        />
        <AccordionItem
          invalid={Boolean(errors.networks)}
          summary="Ссылки на соцсети и мессенджеры"
          details={<NetworksElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
