import {useNavigate, useParams} from 'react-router-dom';
import {TSidebarPath} from '../lib/utils';

export const useSidebarNavigation = () => {
  const {domainId, pageId} = useParams();

  const navigate = useNavigate();
  const editPage = (editPageId?: string) => {
    if (editPageId) {
      return navigate(
        `/admin/domains/${domainId}/site/${pageId}/sidebar/page/edit/${editPageId ?? ''}`
      );
    }
    navigate(`/admin/domains/${domainId}/site/${pageId}/sidebar/page/create`);
  };
  const editPageBackHandler = () => {
    navigate(`/admin/domains/${domainId}/site/${pageId}/sidebar/page`);
  };
  const onNavigateEditorSideControls = (path: TSidebarPath) => () => {
    navigate(`/admin/domains/${domainId}/site/${pageId}/sidebar/${path}`);
  };

  return {editPage, onNavigateEditorSideControls, editPageBackHandler};
};
