import React, {FC} from 'react';
import {ElementComponent} from '../../model/interface';
import {Field} from '../../ui/field';
import {Controller} from 'react-hook-form';
import {Reviews} from '../../components/reviews/reviews';

export const ReviewsElement: FC<ElementComponent> = ({control, configuration}) => {
  return (
    <Field
      node={
        <Controller
          name="reviews"
          control={control}
          render={({field: {onChange, value}}) => <Reviews items={value} onChange={onChange} />}
        />
      }
      configuration={configuration}
      field="reviews"
    />
  );
};
