import tw from 'tailwind-styled-components';

export const NavContainer = tw.div`
    flex 
    bg-gray-100 
    hover:bg-gray-200 
    rounded-lg 
    transition 
    p-1 
`;
