import {useEffect} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {ReviewInfo} from '@shared/api';
import {Image} from '../../components/image';
import {Text} from '../../components/text';
import {reviewInfoSchema} from '../../elements/reviewsElement/schema';

interface ReviewInfoConfigurationProps {
  item: ReviewInfo;
  onChange: (item: ReviewInfo) => void;
}

export const ReviewInfoConfiguration: React.FC<ReviewInfoConfigurationProps> = ({
  item,
  onChange: onChangeHandler,
}) => {
  const onValidData: SubmitHandler<ReviewInfo> = data => {
    onChangeHandler(data);
  };

  const {handleSubmit, control, watch} = useForm<ReviewInfo>({
    mode: 'onChange',
    resolver: yupResolver(reviewInfoSchema),
    defaultValues: {
      ...item,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Controller
        name="image"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Image
            onChange={onChange}
            imageBase={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Заголовок отзыва"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="text"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Текст отзыва"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="authorName"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Имя"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="authorDescription"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Описание"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
    </>
  );
};
