import * as yup from 'yup';
import {subtitleSchema} from '../../elements/textElement/subtitleSchema';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {imageElementSchema} from '../../elements/imageElement/schema';
import {networkItemSchema} from '../../components/networks/schema';
import {formSchema} from '../../elements/formElement/schema';
import {addressSchema} from '../../elements/textElement/addressSchema';
import {emailSchema} from '../../elements/textElement/emailSchema';
import {phoneNumberSchema} from '../../elements/switchElement/schema';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';

export const contactsFormBlockConfigurationSchema = yup.object({
  subtitle: subtitleSchema.optional().nullable().default(undefined),
  title: titleSchema.nullable().optional().default(undefined),
  description: descriptionSchema.nullable().optional().default(undefined),
  image: imageElementSchema.nullable().optional().default(undefined),
  networks: yup
    .array(networkItemSchema)
    .max(6, 'Максимальное количество ссылок - 6')
    .optional()
    .nullable(),
  form: formSchema.optional().nullable().default(undefined),
  address: addressSchema.optional().nullable().default(undefined),
  email: emailSchema.optional().nullable().default(undefined),
  phoneNumber: phoneNumberSchema.nullable().default(undefined),
  button: buttonInfoConfigurationSchema.nullable().default(undefined),
});
