import React, {FC, useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {BlockEditorPortal} from '../blockEditorPortal';

import {blockByTypeSelector, usePageListStoreBase} from '@entities/page';

import {BlockInfo, FormFieldInfo, FormInfo} from '@shared/api/api';
import {TooltipQuestion} from '@shared/ui/tooltipQuestion';
import {StyledRoutLink} from '@shared/ui/link';
import {useToggle} from '@shared/hooks';

import {FormFieldConfiguration} from '../../configuration/formFieldConfiguration/formFieldConfiguration';
import {SettingsContainer} from '../../ui/settingsContainer';
import {VisibleBullet} from '../../ui/item/visibleBullet';
import {formSchema} from '../../elements/formElement/schema';

import {Input} from '../input';
import {Text} from '../text';

import {getBlockFormFields, politicsTooltip} from './utils';
import {Button} from '@shared/ui';
import {GearIcon} from '@shared/images/icons';
import {useWebhookStore} from '../../../webhook';
import {fromILED} from '@shared/lib/utils';
import {isSome} from 'fp-ts/Option';
import {useParams} from 'react-router-dom';
import {useSidebarNavigation} from '@entities/sidebarNavigation';
import {WebhookSettings} from './webhookSettings';

interface FormProps {
  blockType: BlockInfo['type'];
  blockGroup: BlockInfo['group'];
  form: FormInfo | undefined | null;
  onChange: (form: FormInfo | null) => void;
}

export const Form: FC<FormProps> = ({
  form = {fields: [], formId: '', buttonText: ''},
  onChange: onChangeHandler,
  blockGroup,
  blockType,
}) => {
  if (!form) {
    form = {fields: [], formId: '', buttonText: ''};
  }
  const {domainId} = useParams();
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [isWebhookOpen, toggleWebhook] = useToggle();
  const [activeFieldName, setActiveFieldName] = useState<string>('');
  const {fetch} = useWebhookStore.useEffects();
  const webhooks = fromILED(useWebhookStore.useWebhooks());
  const {onNavigateEditorSideControls} = useSidebarNavigation();
  const formFieldsByFieldName: Record<string, FormFieldInfo> = {};

  form.fields.forEach(field => (formFieldsByFieldName[field.fieldName] = field));

  const defaultBlockConfiguration = usePageListStoreBase(
    blockByTypeSelector(blockGroup, blockType)
  );
  const standardBlockFields = getBlockFormFields(defaultBlockConfiguration?.block);

  const settingsHandler = (fieldName: string) => () => {
    setActiveFieldName(fieldName);
    toggleSettings();
  };

  const onVisibleHandler = (field: FormFieldInfo) => () => {
    const {fieldName} = field;
    const result: Record<string, FormFieldInfo> = {...formFieldsByFieldName};
    if (fieldName in formFieldsByFieldName) {
      delete result[fieldName];
    } else {
      result[fieldName] = field;
    }

    onChangeHandler({
      formId: form?.formId ?? '',
      buttonText: form?.buttonText ?? '',
      fields: Object.values(result),
    });
  };

  const activeField = formFieldsByFieldName[activeFieldName];

  const onFieldChangeHandler = (field: FormFieldInfo) => {
    formFieldsByFieldName[field.fieldName] = field;
    onChangeHandler({
      formId: form?.formId ?? '',
      buttonText: form?.buttonText ?? '',
      fields: Object.values(formFieldsByFieldName),
    });
  };

  const onValidData: SubmitHandler<FormInfo> = data => {
    onChangeHandler(data);
  };

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    trigger,
  } = useForm<FormInfo>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...form,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  useEffect(() => {
    fetch(domainId);
  }, [fetch]);

  const onWebhookSettings = () => {
    if (webhooks && isSome(webhooks)) {
      return toggleWebhook();
    }
    onNavigateEditorSideControls('settings/webhooks')();
  };
  const onFormWebhooksChange = (hooks: string[]) => {
    if (form) {
      onChangeHandler({...form, webhooks: hooks});
    }
  };
  return (
    <>
      {standardBlockFields.map((item, index) => {
        const isFieldInvalid = errors.fields && Boolean(errors.fields[index]);
        return (
          <VisibleBullet
            invalid={isFieldInvalid}
            key={index.toString()}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item.fieldName)}
            onVisible={onVisibleHandler(item)}
            visible={item.fieldName in formFieldsByFieldName}
          >
            {item.fieldLabel}
          </VisibleBullet>
        );
      })}
      <Controller
        name="buttonText"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Текст кнопки"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="politics"
        control={control}
        render={({field: {onChange, value, onBlur}, fieldState: {invalid, error}}) => (
          <Text
            label={
              <div className="flex gap-1">
                Текст под формой
                <TooltipQuestion className="inline" tooltip={politicsTooltip} />
              </div>
            }
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
            onBlur={onBlur}
          />
        )}
      />

      <p className="text-sm font-mulish-medium">
        Полученные заявки из контактных форм сохраняются в вашем личном кабинете
        <StyledRoutLink to="/admin/requests">в разделе Заявки</StyledRoutLink>
      </p>
      <Controller
        name="formId"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Название формы в таблице с заявками"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />

      <Button
        onClick={onWebhookSettings}
        className="w-full"
        color="indigo"
        variant="soft"
        type="button"
      >
        Настроить отправку заявок в CRM <GearIcon />
      </Button>

      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer backLabel="Обратно к полям формы" backHandler={() => toggleSettings()}>
          <FormFieldConfiguration field={activeField} onChange={onFieldChangeHandler} />
        </SettingsContainer>
      </BlockEditorPortal>
      <BlockEditorPortal open={isWebhookOpen}>
        <SettingsContainer backLabel="Обратно к полям формы" backHandler={() => toggleWebhook()}>
          <WebhookSettings
            onChange={onFormWebhooksChange}
            formWebhooks={form.webhooks}
            formFieldNames={Object.keys(formFieldsByFieldName)}
            onNavigateWebhooks={onNavigateEditorSideControls('settings/webhooks')}
          />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
