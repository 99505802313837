import {DraggableProvided, DraggingStyle} from '@hello-pangea/dnd';
import {ReactElement, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

export const useDraggableInPortal = () => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.height = '100%';
      element.style.width = '100%';
      element.style.top = '0';

      document.body.append(element);

      return () => {
        element.remove();
      };
    }
  }, [element]);

  return (render: (provided: DraggableProvided) => ReactElement) =>
    (provided: DraggableProvided) => {
      const result = render(provided);
      const style = provided.draggableProps.style as DraggingStyle;
      if (style.position === 'fixed') {
        return createPortal(result, element);
      }
      return result;
    };
};
