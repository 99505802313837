import * as yup from 'yup';
import {imageElementSchema} from '../../elements/imageElement/schema';
import {buttonInfoConfigurationSchema} from '../../elements/buttonElement/schema';
import {linkInfoConfigurationSchema} from '../linkInfoConfiguration/schema';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';

export const cardInfoSchema = yup.object({
  title: titleSchema.nullable().optional().default(undefined),
  description: descriptionSchema.nullable().optional().default(undefined),
  image: imageElementSchema.nullable().optional().default(undefined),
  button: buttonInfoConfigurationSchema.nullable().optional().default(undefined),
  link: linkInfoConfigurationSchema.nullable().optional().default(undefined),
});
