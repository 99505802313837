import {httpClient} from '@shared/apiClient/lib/axios';
import {getMimeTypeFromArrayBuffer} from '@shared/utils/utils';
import {Img} from 'entities/image';
import {ImageBase} from '@shared/api';

export const loadSavedImage = async (img: ImageBase) => {
  const result: Img = {
    croppedImage: {
      file: null,
      url: '',
    },
    image: {
      file: null,
      url: '',
    },
    imageInfo: {
      boundingRect: {height: 0, width: 0, x: 0, y: 0},
      cropRect: {height: 0, width: 0, x: 0, y: 0},
      croppedExternalRef: '',
      hash: '',
      id: '',
      opacity: '',
      originalExternalRef: '',
    },
  };
  if (!img.id) {
    return result;
  }
  const imageInfo = await httpClient.image.getImage(img.id);

  if (imageInfo._tag === 'Left') {
    throw new Error('Error while receiving Image');
  }

  result.imageInfo = imageInfo.right.data;

  const croppedImage = await httpClient.image.loadImage(result.imageInfo.croppedExternalRef, {
    format: 'arraybuffer',
  });
  const originalImage = await httpClient.image.loadImage(result.imageInfo.originalExternalRef, {
    format: 'arraybuffer',
  });

  if (croppedImage._tag === 'Left' || originalImage._tag === 'Left') {
    throw new Error('Error while receiving Image');
  }
  const croppedImageType = getMimeTypeFromArrayBuffer(
    croppedImage.right.data as unknown as ArrayBuffer
  );
  const originalImageType = getMimeTypeFromArrayBuffer(
    originalImage.right.data as unknown as ArrayBuffer
  );

  result.image.file = new File([originalImage.right.data as unknown as string], 'original', {
    type: originalImageType,
  });
  result.image.url = URL.createObjectURL(result.image.file);

  result.croppedImage.file = new File(
    [croppedImage.right.data as unknown as string],
    'croppedImage',
    {type: croppedImageType}
  );
  result.croppedImage.url = URL.createObjectURL(result.croppedImage.file);

  return result;
};

export const loadImageWithURL = async (img: ImageBase) => {
  const result: Img = {
    croppedImage: {
      file: null,
      url: '',
    },
    image: {
      file: null,
      url: '',
    },
    imageInfo: {
      boundingRect: {height: 0, width: 0, x: 0, y: 0},
      cropRect: {height: 0, width: 0, x: 0, y: 0},
      croppedExternalRef: '',
      hash: '',
      id: '',
      opacity: '',
      originalExternalRef: '',
    },
  };

  if (!img.url) {
    return result;
  }

  const originalImage = await fetch(img.url).then(response => {
    if (!response.ok) {
      throw new Error('Error while receiving Image');
    }
    return response.arrayBuffer();
  });

  const originalImageType = getMimeTypeFromArrayBuffer(originalImage);

  result.image.file = new File([originalImage], 'original', {
    type: originalImageType,
  });
  result.image.url = URL.createObjectURL(result.image.file);

  result.croppedImage.file = new File([originalImage], 'croppedImage', {
    type: originalImageType,
  });
  result.croppedImage.url = URL.createObjectURL(result.croppedImage.file);

  return result;
};
