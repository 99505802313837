import * as yup from 'yup';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {textValueSchema} from '@entities/blockContentConfiguration/elements/textElement/textValueSchema';

export const faqInfoSchema = yup.object({
  title: descriptionSchema.nullable().optional().default(undefined),
  value: textValueSchema
    .nullable()
    .optional()
    .default(undefined)
    .textValueMax(1500, 'Максимальная длина 1500 символов'),
});
