import React, {FC} from 'react';
import {ElementComponent} from '../../model/interface';
import {Field} from '../../ui/field';
import {Image} from '../../components/image';
import {Controller} from 'react-hook-form';

export const ImageElement: FC<ElementComponent> = ({configuration, control}) => {
  return (
    <Field
      node={
        <Controller
          name="image"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Image
              onChange={onChange}
              imageBase={value}
              invalid={invalid}
              errorMessage={error?.message}
              hasAltText
            />
          )}
        />
      }
      configuration={configuration}
      field="image"
    />
  );
};
