import tw from 'tailwind-styled-components';

export const Label = tw.label`
    block 
    bg-inherit
    text-sm 
    font-bold 
    mb-2 
    capitalize
`;
