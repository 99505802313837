import * as yup from 'yup';
import {LinkInfo} from '@shared/api';
import {menuItemTitleSchema} from '../textElement/menuItemTitleSchema';

export const menuItemSchema = yup.object({
  title: menuItemTitleSchema.required('Поле обязательно для заполнения'),
  href: yup.string().nullable(),
  blockId: yup.string().nullable(),
  pageId: yup.string().nullable(),
  linkType: yup.string<LinkInfo['linkType']>().required(),
});
