import tw from 'tailwind-styled-components';

interface Props {
  variant: 'solid' | 'outline' | 'ghost' | 'soft' | 'white' | 'link';
  size: 'small' | 'default' | 'large';
  round?: 'none' | 'small' | 'full';
  color: 'indigo' | 'blue';
}

const size = {
  small: `
        py-2
        px-3
    `,
  default: `
        py-3
        px-4
    `,
  large: `
        py-3
        px-4
        sm:p-5
    `,
};

const round = {
  none: 'rounded-none',
  small: 'rounded-sm',
  full: 'rounded-full',
};

const colorByVariant = (props: Pick<Props, 'color' | 'variant'>) => {
  const map = {
    solid: `
            bg-${props.color}-500
            hover:bg-${props.color}-600
            focus:ring-${props.color}-500
        `,
    outline: `
            text-${props.color}-500
            hover:bg-${props.color}-500
            hover:border-${props.color}-500
            focus:ring-${props.color}-500
        `,
    ghost: `
            text-${props.color}-500
            hover:bg-${props.color}-100
            focus:ring-${props.color}-500
        `,
    soft: `
            bg-${props.color}-100
            text-${props.color}-500
            hover:bg-${props.color}-500
            focus:ring-${props.color}-500
        `,
    white: `
            focus:ring-${props.color}-600
        `,
    link: `
            text-${props.color}-500
            hover:text-${props.color}-700
            focus:ring-${props.color}-500
        `,
  };

  return map[props.variant];
};

const variant = {
  solid: `
        border
        border-transparent
        font-mulish-semibold
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
    `,
  outline: `
        py-[.688rem]
        border-2
        border-gray-200
        font-mulish-semibold
        hover
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
    `,
  ghost: `
        border
        border-transparent
        font-mulish-semibold
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
    `,
  soft: `
        border
        border-transparent
        font-mulish-semibold
        hover
        focus:outline-none
        focus:ring-2
        ring-offset-white
        focus:ring-offset-2
    `,
  white: `
        border
        font-medium
        bg-white
        text-gray-700
        shadow-sm
        align-middle
        hover:bg-gray-50
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-offset-white
    `,
  link: `
        border
        border-transparent
        font-mulish-semibold
        focus:outline-none
        focus:ring-2
        ring-offset-white
        focus:ring-offset-2
    `,
};

export const IconButton = tw.button<Props>`
    ${(props: Props) => size[props.size]}
    ${(props: Props) => round[props.round ?? 'small']}
    ${props => colorByVariant(props)}
    ${(props: Props) => variant[props.variant]}

    inline-flex
    justify-center
    items-center
    gap-2
    text-sm
    transition-all

    font-mulish-semibold
    flex-shrink-0
    text-white
`;
