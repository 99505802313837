import React, {FC} from 'react';
import {Button} from '@shared/ui';
import {GripVerticalIcon, Trash3FillIcon} from '@shared/images/icons';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';

interface StyledImageProps {}

const ActionsContainer = tw.div`
    absolute
    top-0
    left-0
    w-full
    h-full
    opacity-0
`;

const StyledImageContainer = styled.div<StyledImageProps>`
  &:hover img {
    opacity: 0.3;
    transition: opacity linear 0.2s;
  }

  &:hover .actions-container {
    opacity: 1;
  }
`;

interface ImagePreviewProps {
  image: string | undefined;
  onEdit: () => void;
  onRemove: () => void;
}

const ImageContainer = tw(StyledImageContainer)<StyledImageProps>`
    overflow-hidden
    rounded
    relative
    w-[108px]
    h-[108px]
`;

export const GalleryImagePreview: FC<ImagePreviewProps> = ({image, onEdit, onRemove}) => {
  return (
    <div>
      <ImageContainer>
        <img className="absolute top-0 left-0 w-full h-full object-cover" src={image} />
        <ActionsContainer className="actions-container ease-in">
          <div className="p-1 absolute top-0 flex justify-between w-full">
            <Button className="p-1" variant="link" color="indigo" size="small">
              <GripVerticalIcon className="text-gray-800" />
            </Button>
            <Button onClick={onRemove} className="p-1" variant="link" color="indigo" size="small">
              <Trash3FillIcon />
            </Button>
          </div>
          <div className="absolute bottom-0 flex justify-center w-full">
            <Button onClick={onEdit} variant="link" color="indigo" size="small">
              Изменить
            </Button>
          </div>
        </ActionsContainer>
      </ImageContainer>
    </div>
  );
};
