import React, {FC} from 'react';
import {FoldILED} from 'iled';
import {fold} from 'fp-ts/Option';
import {NonEmptyArray} from 'fp-ts/NonEmptyArray';
import {PageListInfo} from '../../../shared/api';
import {Spinner, SpinnerContainer} from '../../../shared/ui/spinner';
import {usePageListStore} from '../../page/store/pageListStore';

export const ActiveDomainPagesOptions: FC = () => {
  const pages = usePageListStore.usePages();

  return (
    <FoldILED
      state={pages}
      onData={data => {
        return fold(
          () => null,
          (pageList: NonEmptyArray<PageListInfo>) => (
            <>
              <option value="">Не выбрано</option>
              {pageList.map(page => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
            </>
          )
        )(data);
      }}
      onLoading={() => (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      onInitial={() => null}
      onError={() => null}
    />
  );
};
