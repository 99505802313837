import {useEffect, useState} from 'react';
import {ILED} from 'iled';
import {fold, fromNullable, Option, some} from 'fp-ts/Option';
import {dataOf, errorOf, loadingOf} from 'iled/dist/constructors';
import {ImageBase} from '@shared/api/api';
import {clone} from 'remeda';
import {loadImageWithURL, loadSavedImage} from './utils';
import {merge} from 'lodash-es';
import {Img, initialState} from '@entities/image';

export const useLoadImage = (imageBase: ImageBase | undefined) => {
  const [image, setImage] = useState<ILED<null, null, string, Option<Img>>>(
    dataOf(some(initialState))
  );

  useEffect(() => {
    async function loadImage() {
      const imageBaseOption = fromNullable(imageBase);
      fold(
        () => setImage(dataOf(some(initialState))),
        async (img: ImageBase) => {
          if (!img.id && !img.url) {
            setImage(dataOf(some(initialState)));
            return;
          }

          let result: Img = clone(initialState);

          setImage(loadingOf(null));
          try {
            let loadedImg: Img | null = null;
            if (img.id) {
              loadedImg = await loadSavedImage(img);
            }
            if (img.url) {
              loadedImg = await loadImageWithURL(img);
            }

            result = merge(result, loadedImg);

            setImage(dataOf(some(result)));
          } catch {
            setImage(errorOf('Error while receiving Image'));
          }
        }
      )(imageBaseOption);
    }

    loadImage();
  }, [imageBase?.id, imageBase?.hash]);

  return image;
};
