import {FC} from 'react';
import {InputProps as BaseInputProps, Input as BaseInput} from '@shared/ui/input';

interface InputProps extends Omit<BaseInputProps, 'onChange'> {
  onChange: (value: string | null | undefined) => void;
}

export const Input: FC<InputProps> = ({onChange, ...props}) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      return onChange(null);
    }
    onChange(e.target.value);
  };
  return <BaseInput onChange={onChangeHandler} {...props} />;
};
