import * as yup from 'yup';
import {NetworkItem} from '@shared/api';

export const networkItemSchema = yup.object({
  networkType: yup.string<NetworkItem['networkType']>().required(),
  href: yup
    .string()
    .max(2000, 'Максимальная длина символов 2000')
    .required('Поле обязательно для заполнения'),
  hrefType: yup.string<Exclude<NetworkItem['hrefType'], undefined>>().nullable(),
});
