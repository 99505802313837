import tw from 'tailwind-styled-components';

export const SpinnerContainer = tw.div`
    flex
    justify-center
`;

export const Spinner = tw.div`
    w-8
    h-8
    rounded-full
    border-4
    border-solid
    border-indigo-100
    border-l-indigo-500
    animate-spin
`;
