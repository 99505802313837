import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Field} from '../../ui/field';
import {ElementComponent} from '../../model/interface';
import {Networks} from '../../components/networks';

export const NetworksElement: FC<ElementComponent> = ({control, configuration}) => {
  return (
    <Field
      node={
        <Controller
          name="networks"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Networks items={value} onChange={onChange} invalid={invalid} error={error?.message} />
          )}
        />
      }
      configuration={configuration}
      field="networks"
    />
  );
};
