import {FC, ReactNode, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useEditorStore} from '@entities/editor';

interface BlockEditorPortalProps {
  children: ReactNode;
  open: boolean;
}

export const BlockEditorPortal: FC<BlockEditorPortalProps> = ({children, open}) => {
  const element = useEditorStore.useBlockEditorElement();
  const {setBlockEditorPortalRender} = useEditorStore.useActions();

  useEffect(() => {
    if (open && element) {
      setBlockEditorPortalRender(true);
    }
    return () => {
      setBlockEditorPortalRender(false);
    };
  }, [open, element]);
  if (!open) {
    return null;
  }

  if (element) {
    return createPortal(children, element);
  }

  return null;
};
