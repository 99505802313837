import {FC, useEffect} from 'react';
import {SnackProps} from '../model/interface';
import {CheckCircleFillIcon, XLgIcon} from '../../../shared/images/icons';

export const Snack: FC<SnackProps> = ({message, onClose, autoHide}) => {
  useEffect(() => {
    if (autoHide && autoHide > 0) {
      setTimeout(() => {
        onClose();
      }, autoHide);
    }
  }, [autoHide]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-fit bg-gray-800 text-white text-lg font-mulish-semibold p-4 mb-2 relative">
        <div className="flex items-center pr-8">
          <CheckCircleFillIcon className="mr-5 text-gray-200" />
          {message}
        </div>
        <XLgIcon
          onClick={onClose}
          width={16}
          height={16}
          className="text-gray-400 absolute right-[10px] top-[10px] hover:text-indigo-200 cursor-pointer"
        />
      </div>
    </div>
  );
};
