import * as yup from 'yup';
import {titleSchema} from '../../elements/textElement/titleSchema';
import {descriptionSchema} from '../../elements/textElement/descriptionSchema';
import {formSchema} from '../../elements/formElement/schema';
import {logoSchema} from '../../elements/logoElement/schema';
import {networksSchema} from '../../elements/networksElement/schema';

export const footerBlockConfigurationSchema = yup.object({
  title: titleSchema.nullable().optional().default(undefined),
  description: descriptionSchema.nullable().optional().default(undefined),
  copyright: yup.string().nullable().optional().default(undefined),
  form: formSchema.nullable().optional().default(undefined),
  logo: logoSchema.nullable().optional().default(undefined),
  networks: networksSchema.nullable().optional().default(undefined),
});
