import React, {FC, useCallback, useState} from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import SortableItem from './sortableItem';
import {ImageBase} from '../api';

interface DraggableProps {
  items: ImageBase[];
  onChange: (items: unknown[]) => void;
  children: JSX.Element[];
}

export const DraggableImages: FC<DraggableProps> = ({items, onChange, children}) => {
  const [, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event;

      if (active.id !== over?.id) {
        const oldIndex = items.map(e => e.id ?? e.url).indexOf(active.id);
        const newIndex = items.map(e => e.id ?? e.url).indexOf(over!.id);
        const result = arrayMove(items, oldIndex, newIndex);

        onChange(result);
      }

      setActiveId(null);
    },
    [items]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={items.map(item => (item.id ?? item.url) as string)}
        strategy={rectSortingStrategy}
      >
        <div className="flex flex-wrap gap-2">
          {children.map(element => (
            <SortableItem key={element.key} id={element.key as string}>
              {element}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
