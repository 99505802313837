import React, {FC, useEffect} from 'react';
import {ReactComponent as Trash} from '@shared/images/icons/trash3.svg';
import {Bigtext} from '@shared/ui/bigtext/bigtext';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {pointSchema} from '../../../elements/pointsElement/schema';
import * as yup from 'yup';

interface PointBulletProps {
  value: string;
  onChange: (value: string) => void;
  onRemove: () => void;
}

export const PointBullet: FC<PointBulletProps> = ({
  onChange: onChangeHandler,
  value: val,
  onRemove,
}) => {
  const onValidData: SubmitHandler<{value: string}> = data => {
    if (onChangeHandler) {
      onChangeHandler(data.value);
    }
  };

  const {handleSubmit, control, watch, trigger} = useForm<{value: string}>({
    mode: 'onChange',
    resolver: yupResolver(yup.object({value: pointSchema})),
    defaultValues: {
      value: val,
    },
  });

  useEffect(() => {
    trigger();
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex items-center">
      <div className="flex-1">
        <Controller
          name="value"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Bigtext
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            />
          )}
        />
      </div>
      <Trash onClick={onRemove} className="w-5 h-5 ml-3 -mr-1" />
    </div>
  );
};
