import React, {FC} from 'react';
import {PointBullet} from './ui/pointBullet';

interface PointsCompositionProps {
  points?: string[];
  onRemove: (index: number, points: string[]) => () => void;
  onChange: (index: number, points: string[]) => (nextValue: string) => void;
}

export const PointsComposition: FC<PointsCompositionProps> = ({points, onRemove, onChange}) => {
  return (
    <div>
      {points?.map((text, i) => (
        <PointBullet
          key={i}
          value={text}
          onChange={onChange(i, points)}
          onRemove={onRemove(i, points)}
        />
      ))}
    </div>
  );
};
