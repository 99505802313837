import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {httpClient} from '@shared/apiClient/lib/axios';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {FeatureToggleState} from '../model/interface';

export const useFeatureToggleStoreBase = create<FeatureToggleState>()(
  devtools(set => ({
    flags: {
      registrationFromUi: false,
      addingCustomDomain: false,
      purchaseDomain: false,
    },
    fetchFeatureToggleFlags: async () => {
      const response = await httpClient.features.allFeaturesFlags();
      if (response._tag === 'Right') {
        set({
          flags: response.right.data,
        });
      }
    },
  }))
);

export const useFeatureToggleStore = createSelectorHooks(useFeatureToggleStoreBase);

export const isPrettyDomainsVisibleSelector = (state: FeatureToggleState) => {
  return state.flags.addingCustomDomain || state.flags.purchaseDomain;
};
