import {Link} from 'react-router-dom';
import tw from 'tailwind-styled-components';

import {Props} from './interface';

const baseLinkStyles = `
    gap-x-2
    items-center
    text-sm
    text-indigo-500
    -mt-px
    first:rounded-t-lg
    first:mt-0
    last:rounded-b-lg
    focus:z-10
    focus:outline-none
    underline-offset-2
    hover:underline
    mb-3
    font-mulish-semibold
`;

export const StyledRoutLink = tw(Link)<Props>`
    ${() => baseLinkStyles}
    ${props => props.color || ''}
    ${props => (props.hasunderline ? 'underline' : '')}
    ${props => (props.inlineflex ? 'inline-flex' : 'flex')}
`;

export const StyledLink = tw.a<Props>`
    ${() => baseLinkStyles}
    ${props => props.color || ''}
    ${props => (props.hasunderline ? 'underline' : '')}
    ${props => (props.inlineflex ? 'inline-flex' : 'flex')}
`;

export const HrefLink = tw.a<Props>`
    ${() => baseLinkStyles}
    ${props => props.color || ''}
    ${props => (props.hasunderline ? 'underline' : '')}
    ${props => (props.inlineflex ? 'inline-flex' : 'flex')}
`;

export const BackLink = tw(StyledRoutLink)`
    absolute
    top-0
    left-0
    pl-10
    pt-9
    pb-9
    mb-0
    text-gray-800
    hover:text-gray-800
`;
