import React, {FC, useEffect} from 'react';
import {Input} from '../../components/input';
import {Select} from '@shared/ui';
import {LinkInfo} from '@shared/api';
import {LinkInfoProps} from './model';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {linkInfoConfigurationSchema} from './schema';
import {ActiveDomainPagesOptions} from '../../components/activeDomainPagesOptions';
import {ActivePageBlocksOptions} from '../../components/activePageBlocksOptions';

export const LinkInfoConfiguration: FC<LinkInfoProps> = ({link, onSubmit}) => {
  const onValidData: SubmitHandler<LinkInfo> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {handleSubmit, getValues, reset, control, watch} = useForm<LinkInfo>({
    mode: 'onChange',
    resolver: yupResolver(linkInfoConfigurationSchema),
    defaultValues: {
      ...link,
    },
  });

  useEffect(() => {
    const subscription = watch((value, info) => {
      handleSubmit(onValidData)();
      if (info.type === 'change' && info.name === 'linkType') {
        reset({...value});
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const formLinkType = getValues('linkType');

  return (
    <>
      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Input
            label="Название пункта"
            onChange={onChange}
            value={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="linkType"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Select
            value={value}
            label="Тип ссылки"
            onChange={onChange}
            invalid={invalid}
            errorMessage={error?.message}
          >
            <option value="ANCHOR"> Якорь</option>
            <option value="INTERNAL"> Внутренняя ссылка</option>
            <option value="EXTERNAL"> Внешняя ссылка</option>
          </Select>
        )}
      />

      {formLinkType === 'EXTERNAL' && (
        <Controller
          name="href"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Input
              label="Адрес ссылки"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {formLinkType === 'ANCHOR' && (
        <Controller
          name="blockId"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Select
              label="Перемещение на блок"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            >
              <ActivePageBlocksOptions />
            </Select>
          )}
        />
      )}
      {formLinkType === 'INTERNAL' && (
        <Controller
          name="pageId"
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <Select
              label="Страница"
              onChange={onChange}
              value={value}
              invalid={invalid}
              errorMessage={error?.message}
            >
              <ActiveDomainPagesOptions />
            </Select>
          )}
        />
      )}
    </>
  );
};
