import tw from 'tailwind-styled-components';

export interface CaptionProps {
  color?: string;
}

export const Caption = tw.div<CaptionProps>`
    ${props => props.color || 'text-gray-500'}
    font-mulish-regular
    text-sm
    w-full
    block
    mb-2
`;
