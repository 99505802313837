import MD5 from 'crypto-js/md5';
import {ImageInfo} from '@shared/api/api';

export const getImageInfo = (hashString: string): File => {
  const imgInfo: Partial<ImageInfo> = {
    boundingRect: {height: 0, width: 0, x: 0, y: 0},
    cropRect: {height: 0, width: 0, x: 0, y: 0},
    hash: MD5(hashString).toString(),
    opacity: '',
  };

  const imageInfoDefaultBlob = new Blob([JSON.stringify(imgInfo)], {type: 'application/json'});
  const imageInfoDefaultFile = new File([imageInfoDefaultBlob], 'imageInfo.json', {
    type: 'application/json',
  });
  return imageInfoDefaultFile;
};
