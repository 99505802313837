import {Tooltip, TooltipProps} from 'flowbite-react';
import {QuestionCircleFilled} from '@ant-design/icons';
import {ReactNode} from 'react';

interface TooltipQuestionProps extends Partial<TooltipProps> {
  tooltip: string | ReactNode;
}

export const TooltipQuestion: React.FC<TooltipQuestionProps> = ({tooltip, placement, ...rest}) => {
  return (
    <Tooltip content={tooltip} placement={placement} {...rest}>
      <div className="w-full h-full flex justify-center items-center text-gray-300 hover:text-gray-400">
        <QuestionCircleFilled />
      </div>
    </Tooltip>
  );
};
