import React, {FC, useEffect, useState} from 'react';
import {fold, fromNullable} from 'fp-ts/Option';
import {FoldILED} from 'iled';

import {UploadPreview} from '@shared/ui/uploadInput/uploadPreview';
import {httpClient} from '@shared/apiClient/lib/axios';
import {ImageBase} from '@shared/api/api';
import {useToggle} from '@shared/hooks';
import {Caption, Spinner, SpinnerContainer} from '@shared/ui';

import {ImageEditor} from './ui';
import {EditedImage, ImageProps} from './model';
import {useLoadImage} from './hooks';
import {AddImage, ImageHandler} from '../addImage';
import {Img} from 'entities/image';
import {getImageInfo} from './utils';
import {Input} from '../../components/input';
import {TooltipQuestion} from 'shared/ui/tooltipQuestion';

export const Image: FC<ImageProps> = ({
  onChange,
  imageBase,
  invalid,
  errorMessage,
  helperText,
  description,
  hasAltText = false,
}) => {
  const imageBaseOption = fromNullable(imageBase);
  const imageFile = useLoadImage(imageBase);

  const [addImageOpen, , setAddImageOpen, setAddImageClose] = useToggle();
  const [editImage, , setEditImageOpen, setEditImageClose] = useToggle();
  const [text, setText] = useState<string | undefined>('');

  useEffect(() => {
    if (imageBase?.altText) {
      setText(imageBase?.altText);
    }
  }, []);

  const handleChangeAltText: any = (textValue: string) => {
    setText(textValue);

    fold(
      () => null,
      (someImageBase: ImageBase) => {
        onChange({id: someImageBase.id, hash: someImageBase.hash, altText: textValue});
      }
    )(imageBaseOption);
  };

  const imageHandler = (foldedImageFile: Img) => (props: EditedImage) => {
    fold(
      () => null,
      (someImageBase: ImageBase) => {
        httpClient.image
          .updateImage(someImageBase.id as string, {
            image: foldedImageFile.image.file as File,
            croppedImage: props.imageFile,
            imageInfo: getImageInfo(props.imageUrl),
          })
          .then(res => {
            if (res._tag === 'Right') {
              onChange({id: res.right.data.id, hash: res.right.data.hash, altText: text});
            }
          });
      }
    )(imageBaseOption);
  };

  const onRemove = () => {
    fold(
      () => null,
      (foldedImageBase: ImageBase) => {
        if (foldedImageBase.id) {
          return httpClient.image.deleteImage(foldedImageBase.id).then(res => {
            if (res._tag === 'Right') {
              onChange(null as unknown as ImageBase);
            }
          });
        }
        if (foldedImageBase.url) {
          onChange(null as unknown as ImageBase);
        }
      }
    )(imageBaseOption);
  };

  const onAddImageHandler: ImageHandler = async image => {
    if (image.dataUrl && image.file) {
      const result = await httpClient.image.createImage({
        croppedImage: image.file,
        image: image.file,
        imageInfo: getImageInfo(image.dataUrl),
      });
      if (result._tag === 'Right') {
        onChange({id: result.right.data.id, hash: result.right.data.hash, altText: text});
        setAddImageClose();
      }
    }
  };
  const onPreviewImageClick = () => {
    setAddImageOpen();
  };

  const onErrorImageClick = () => {
    setAddImageOpen();
  };
  return (
    <>
      <FoldILED
        state={imageFile}
        onData={data =>
          fold(
            () => null,
            (img: Img) => (
              <div>
                <UploadPreview
                  onChangeImage={setAddImageOpen}
                  onCropImage={setEditImageOpen}
                  image={img.croppedImage.url}
                  onRemove={onRemove}
                  onClick={onPreviewImageClick}
                  helperText={helperText}
                  description={description}
                />
                <ImageEditor
                  imageFile={img.image.file}
                  croppedImage={img.croppedImage.file}
                  onChange={imageHandler(img)}
                  onClose={setEditImageClose}
                  open={editImage}
                />
              </div>
            )
          )(data)
        }
        onLoading={() => (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        onInitial={() => null}
        onError={() => (
          <div>
            <UploadPreview
              onChangeImage={setAddImageOpen}
              onCropImage={setEditImageOpen}
              image={undefined}
              onRemove={onRemove}
              onClick={onErrorImageClick}
            />
          </div>
        )}
      />
      {invalid && errorMessage && <Caption color="text-red-500">{errorMessage}</Caption>}

      {hasAltText && (
        <Input
          label={
            <div className="flex gap-2">
              <span className="font-mulish-semibold text-sm mb-2 text-gray-800">
                Альтернативный текст
              </span>
              <TooltipQuestion tooltip="Alt-текст будет показан вместо изображения, если оно не будет загружено" />
            </div>
          }
          onChange={handleChangeAltText}
          value={text}
        />
      )}

      <AddImage open={addImageOpen} onClose={setAddImageClose} onAddImage={onAddImageHandler} />
    </>
  );
};
