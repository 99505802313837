import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {TextElement} from '../../elements/textElement';
import {LogoElement} from '../../elements/logoElement';
import {FormElement} from '../../elements/formElement';
import {NetworksElement} from '../../elements/networksElement';
import {BlockConfigurationContentProps} from '../../model/interface';

import {footerBlockConfigurationSchema} from './schema';

import {FooterBlockConfiguration} from '@shared/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';

export const FooterBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<FooterBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<FooterBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<Partial<FooterBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(footerBlockConfigurationSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <TextElement
        label="Заголовок"
        fieldName="title"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Описание"
        fieldName="description"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Копирайт"
        fieldName="copyright"
        control={control}
        configuration={configuration}
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors?.logo)}
          summary="Логотип"
          details={<LogoElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors?.form)}
          summary="Форма"
          details={<FormElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors?.networks)}
          summary="Ссылки на соцсети и мессенджеры"
          details={<NetworksElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
