import {CardInfo} from '@shared/api';
import {Image} from '../../components/image';
import {Text} from '../../components/text';
import {ButtonInfoConfiguration} from '../buttonInfoConfiguration';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {useEffect} from 'react';
import {cardInfoSchema} from './schema';
import {doNotSaveEmptyStringMiddlewareConfiguration} from '@features/blockConfiguration/utils';

interface CardInfoConfigurationProps {
  item: CardInfo;
  onSubmit: (item: CardInfo) => void;
}

export const CardInfoConfiguration: React.FC<CardInfoConfigurationProps> = ({item, onSubmit}) => {
  const onValidData: SubmitHandler<CardInfo> = data => {
    if (onSubmit) {
      onSubmit(doNotSaveEmptyStringMiddlewareConfiguration(data));
    }
  };

  const {handleSubmit, control, watch} = useForm<CardInfo>({
    mode: 'onChange',
    resolver: yupResolver(cardInfoSchema),
    defaultValues: {
      ...item,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Controller
        name="image"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Image
            onChange={onChange}
            imageBase={value}
            invalid={invalid}
            errorMessage={error?.message}
            hasAltText
          />
        )}
      />

      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Заголовок"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
          <Text
            label="Описание"
            onChange={onChange}
            textValue={value}
            invalid={invalid}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="button"
        control={control}
        render={({field: {onChange, value}}) => (
          <ButtonInfoConfiguration button={value} onSubmit={onChange} />
        )}
      />
    </>
  );
};
