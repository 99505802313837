import React, {FC, useState} from 'react';
import {Button} from '@shared/ui/button';
import {ActiveTab, Tab, Tabs} from '@shared/ui/tabs';
import {Modal} from 'flowbite-react';
import {UploadLocal} from './uploadLocal';
import {ImageHandler} from '../model/image';
import {GenerateImage} from './generateImage';
import {ImageLibrary} from './imageLibrary';
import {H2} from '@shared/ui/typography';

interface AddImageProps {
  open: boolean;
  onClose: () => void;
  onAddImage: ImageHandler;
}

export const AddImage: FC<AddImageProps> = ({open, onClose, onAddImage}) => {
  const [image, setImage] = useState<{dataUrl: string; file: File | null}>({
    dataUrl: '',
    file: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onImageHandler: ImageHandler = props => {
    setImage(props);
    setLoading(false);
  };

  const onAddImageHandler = () => {
    onAddImage(image);
  };

  const activeSelectImage: ActiveTab = {
    name: 'Загрузить с компьютера',
    content: (
      <UploadLocal
        onChange={onImageHandler}
        image={image.dataUrl}
        loading={loading}
        handleLoading={setLoading}
      />
    ),
    order: 1,
    type: 'active',
  };

  const selectImageType: Tab[] = [
    {
      name: 'Сгенерировать с ИИ',
      content: <GenerateImage />,
      order: 2,
      type: 'regular',
    },
    {
      name: 'Искать в библиотеке',
      content: <ImageLibrary />,
      order: 3,
      type: 'regular',
    },
  ];

  return (
    <Modal className="z-[150]" size="5xl" show={open} onClose={onClose}>
      <Modal.Header className="border-b-0 py-0 px-5 pt-2" />
      <Modal.Body>
        <H2 className="text-2xl font-mulish-semibold text-center w-full">Выбор изображения</H2>
        <div className="text-sm font-mulish-regular text-gray-500">
          Вы можете загрузить изображение с компьютера, сгенерировать с помощью искусственного
          интеллекта или поискать в библиотеке
        </div>
        <div className="mt-5">
          <Tabs tabsGroupName="upload-image" other={selectImageType} active={activeSelectImage} />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-t-0">
        <div className="w-full flex justify-center">
          <Button
            onClick={onAddImageHandler}
            color="indigo"
            variant="solid"
            size="default"
            disabled={loading}
          >
            Вставить на сайт
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
