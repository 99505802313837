import React, {FC} from 'react';
import {IconButton} from '../../../shared/ui/iconButton';
import {PencilSquareIcon, Trash3Icon} from '../../../shared/images/icons';
import {InfoProps} from '../model/interface';

export const WebhookInfos: FC<InfoProps> = ({item, onEdit, onRemove}) => {
  return (
    <>
      <div className="mb-2 font-bold">{item.name}</div>
      <div>{item.url}</div>
      <div className="absolute top-3 right-3 flex gap-2">
        <IconButton
          onClick={onEdit}
          size="small"
          variant="ghost"
          color="indigo"
          type="button"
          className="rounded p-1 text-gray-800"
        >
          <PencilSquareIcon />
        </IconButton>
        <IconButton
          onClick={onRemove}
          type="button"
          size="small"
          variant="ghost"
          color="indigo"
          className="rounded p-1 text-gray-800"
        >
          <Trash3Icon />
        </IconButton>
      </div>
    </>
  );
};
