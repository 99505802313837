import {produce} from 'immer';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {ILED} from 'iled';
import {Option, some} from 'fp-ts/Option';
import {dataOf, errorOf, initialOf, loadingOf} from 'iled/dist/constructors';
import {httpClient} from '@shared/apiClient/lib/axios';
import {fromILED, fromOption} from '../../../shared/lib/utils';
import {Img} from 'entities/image';
import {loadSavedImage} from '@entities/blockContentConfiguration';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';
import {Left, Right} from 'fp-ts/Either';
import {
  ResponseData,
  ImageInfo,
  ProfileInfo,
  ResponseError,
  UpdateProfileRequest,
} from '@shared/api';

interface ProfileStore {
  currentUser: ILED<null, null, string, Option<ProfileInfo>>;
  currentUserAvatarImage: Img | null | undefined;
  uploadedAvatar: Img | null | undefined;
  isFormDirty: boolean;
  htmlFeatureEnabled: boolean;
  effects: {
    currentProfile: () => Promise<Left<ResponseError> | Right<ResponseData<ProfileInfo>>>;
    updateProfile: (data: UpdateProfileRequest, img?: Img | null) => Promise<void>;
  };
  actions: {
    setCurrentUser: (user: ProfileInfo) => void;
    setCurrentUserAvatarImage: (image: Img | null | undefined) => void;
    setCurrentUserAvatar: (avatarImage: ImageInfo | undefined) => void;
    setUploadedAvatar: (uploadedAvatar: Img | undefined | null) => void;
    setIsFormDirty: () => void;
  };
}

export const useProfileStoreBase = create<ProfileStore>()(
  devtools(set => ({
    currentUserAvatarImage: null,
    currentUser: initialOf(null),
    uploadedAvatar: null,
    isFormDirty: false,
    htmlFeatureEnabled: false,
    effects: {
      currentProfile: async () => {
        set({currentUser: loadingOf(null)});
        const result = await httpClient.profile.getCurrentProfile();
        if (result._tag === 'Left') {
          set({currentUser: errorOf('Error while receiving current profile')});
          return result;
        }
        const profileImage = result.right.data.avatarImage;
        if (profileImage) {
          const image = await loadSavedImage(profileImage);
          set({currentUserAvatarImage: image});
        }
        set({
          currentUser: dataOf(some(result.right.data)),
          htmlFeatureEnabled: result.right.data.features.includes('HTMLBLOCK'),
        });
        return result;
      },
      updateProfile: async (data: UpdateProfileRequest) => {
        set({currentUser: loadingOf(null)});
        const result = await httpClient.profile.updateProfile(data);
        if (result._tag === 'Left') {
          set({currentUser: errorOf('Error updating the current profile')});
          return;
        }
        set({currentUser: dataOf(some(result.right.data))});
      },
    },
    actions: {
      setCurrentUserAvatarImage: image => {
        set({
          currentUserAvatarImage: image,
        });
      },
      setCurrentUser: user => {
        return set({currentUser: dataOf(some(user))});
      },
      setCurrentUserAvatar: avatarImage =>
        set(
          produce<ProfileStore>(draft => {
            if (
              draft.currentUser &&
              draft.currentUser.type === 'Data' &&
              draft.currentUser.data._tag === 'Some'
            ) {
              draft.currentUser.data.value.avatarImage = avatarImage;
            }
          })
        ),
      setUploadedAvatar: uploadedAvatar =>
        set(() => ({
          uploadedAvatar,
        })),
      setIsFormDirty: () =>
        set(() => ({
          isFormDirty: true,
        })),
    },
  }))
);

export const useProfileStore = createSelectorHooks(useProfileStoreBase);

export const useProfileCardNeedAssign = (state: ProfileStore) => {
  return isProfileNeedCardAssign(state);
};

export const isProfileNeedCardAssign = (state: ProfileStore) => {
  const profile = fromOption(fromILED(state.currentUser));
  if (profile) {
    return (
      profile.paymentMethod === 'NONE' &&
      profile.authenticationType === 'PHONE' &&
      profile.paymentMethodStatus !== 'ACTIVE'
    );
  }
  return false;
};

export const isProfileSelfRegisteredSelector = (state: ProfileStore) => {
  const profile = fromOption(fromILED(state.currentUser));
  if (profile) {
    return profile.authenticationType === 'PHONE';
  }
  return false;
};

export const useProfileSelector = (state: ProfileStore) => {
  return fromOption(fromILED(state.currentUser));
};
