import * as yup from 'yup';
import {FormFieldInfo} from '@shared/api';
import {
  DigitAndLetterAndSymbolsRegExpWithEmail,
  DigitAndLetterAndSymbolsRegExpWithEmailMessage,
} from './utils';

export const formFieldSchema = yup.object({
  fieldName: yup.string().nullable(),
  fieldLabel: yup.string().nullable(),
  fieldType: yup.string<FormFieldInfo['fieldType']>().required(),
  required: yup.boolean().required(),
  placeholder: yup
    .string()
    .nullable()
    .max(50, 'Максимальная длина - 50 символов')
    .matches(
      DigitAndLetterAndSymbolsRegExpWithEmail,
      DigitAndLetterAndSymbolsRegExpWithEmailMessage
    ),
});
