import React, {FC, useState} from 'react';
import {Button, StyledLink, Switch} from '@shared/ui';
import {CheckCircleFillIcon, GearIcon} from '@shared/images/icons';
import {fromILED, fromOption} from '@shared/lib/utils';
import {useWebhookStore} from '../../../webhook';
import {useToggle} from '@shared/hooks';
import {randomString} from 'remeda';
import {Toast, Tooltip} from 'flowbite-react';
import {sendData} from './utils';

interface WebhookSettingsProps {
  formWebhooks: string[] | undefined;
  formFieldNames: string[];
  onNavigateWebhooks: () => void;
  onChange: (webhooks: string[]) => void;
}

export const WebhookSettings: FC<WebhookSettingsProps> = ({
  formWebhooks,
  onNavigateWebhooks,
  onChange,
  formFieldNames,
}) => {
  const webhooks = fromILED(useWebhookStore.useWebhooks());
  const [loading, toggleLoading] = useToggle();
  const [success, toggleSuccess, setSuccess] = useToggle();

  const [testObject] = useState<object>(() => {
    const obj: Record<string, string> = {};

    formFieldNames.forEach(name => {
      obj[name] = randomString(5);
    });
    return obj;
  });

  const onSendTestData = async () => {
    toggleLoading();
    let results: Promise<unknown>[] = [];
    const hooks = fromOption(webhooks);
    if (!hooks) {
      return;
    }

    for (let hook of hooks) {
      if (hook.id && !formWebhooks?.includes(hook.id)) {
        continue;
      }
      results.push(sendData(testObject, hook.url));
    }
    try {
      await Promise.all(results);
    } catch (error) {
      console.log(error);
    }
    toggleLoading();
    setSuccess();
  };

  const onWebhookEnable: (id: string | undefined) => React.ChangeEventHandler<HTMLInputElement> =
    id => e => {
      const enable = e.target.checked;
      if (!id) {
        return;
      }
      let next: string[] = [];
      if (enable) {
        next = formWebhooks ? [...formWebhooks, id] : [id];
      } else {
        next = formWebhooks?.filter(ids => ids !== id) ?? [];
      }
      onChange(next);
    };
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <div className="text-gray-800 text-base font-bold">Отправка заявок в CRM</div>
        <div className="text-gray-500 text-sm">
          Выберите сервисы, в которые нужно отправлять данные с этой формы
        </div>
        <StyledLink
          hasunderline
          target="_blank"
          rel="noopener noreferrer"
          onClick={onNavigateWebhooks}
        >
          <GearIcon />
          Перейти к настройкам Webhook’ов
        </StyledLink>
      </div>
      <div className="flex flex-col gap-3">
        {fromOption(webhooks)?.map(webhook => (
          <div key={webhook.url} className="bg-gray-200 p-3 flex gap-3 rounded">
            <Switch
              value={formWebhooks?.includes(webhook.id as string)}
              color="indigo"
              onChange={onWebhookEnable(webhook.id)}
            />
            {webhook.name ?? webhook.url}
          </div>
        ))}
        <Tooltip content={<div className="bg-emerald-700">TEST</div>} />
        <Button
          disabled={loading}
          variant="soft"
          type="button"
          onClick={onSendTestData}
          color="indigo"
        >
          Отправить тестовые данные
        </Button>
        <Popup open={success} onClose={toggleSuccess} />
        <Tooltip
          content={
            <pre
              dangerouslySetInnerHTML={{
                __html: `JSON <br/>${JSON.stringify(testObject, null, 4)}`,
              }}
            />
          }
        >
          <div className="text-sm text-gray-500 w-fit border-b border-dashed border-gray-500">
            Отправляемые тестовые данные
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const Popup: FC<{
  open: boolean;
  onClose: () => void;
}> = ({open, onClose}) => {
  if (!open) {
    return null;
  }
  return (
    <Toast className="bg-emerald-500 text-white w-full py-2 px-2.5">
      <CheckCircleFillIcon className="h-5 w-5" />
      <div className="pl-4 text-sm font-normal">Данные отправлены</div>
      <Toast.Toggle onDismiss={onClose} className="p-0 w-[unset] h-[unset] mr-1" />
    </Toast>
  );
};
