import React, {useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {SubmitHandler, useForm} from 'react-hook-form';

import {FirstScreenBlockConfiguration} from '@shared/api/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';
import {ButtonElement} from '../../elements/buttonElement';
import {ImageElement} from '../../elements/imageElement';
import {PointsElement} from '../../elements/pointsElement';
import {FormElement} from '../../elements/formElement';
import {SeoElement} from '../../elements/seoElement';

import {firstScreenBlockConfigurationContentSchema} from './schema';
import {BlockConfigurationContentProps} from '../../model/interface';
import {TextElement} from '../../elements/textElement';

export const FirstScreenBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<FirstScreenBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<FirstScreenBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: {errors},
  } = useForm<Partial<FirstScreenBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(firstScreenBlockConfigurationContentSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, configuration.type, reset]);

  return (
    <>
      <TextElement
        configuration={configuration}
        control={control}
        fieldName="title"
        label="Заголовок"
      />
      <TextElement
        configuration={configuration}
        control={control}
        fieldName="description"
        label="Описание"
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors.primaryButton)}
          summary="Кнопка 1"
          details={
            <ButtonElement
              control={control}
              configuration={configuration}
              fieldName="primaryButton"
            />
          }
        />
        <AccordionItem
          invalid={Boolean(errors.secondaryButton)}
          summary="Кнопка 2"
          details={
            <ButtonElement
              control={control}
              configuration={configuration}
              fieldName="secondaryButton"
            />
          }
        />
        <AccordionItem
          invalid={Boolean(errors.image)}
          summary="Изображение"
          details={<ImageElement control={control} configuration={configuration} />}
        />

        <AccordionItem
          invalid={Boolean(errors?.points) || Boolean(errors?.pointsIconStyle)}
          summary="Список пунктов"
          details={<PointsElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.form)}
          summary="Форма"
          details={<FormElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.titleTag)}
          summary="SEO"
          details={<SeoElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
