import * as yup from 'yup';
import {
  DigitAndLetterAndSymbolsRegExp,
  DigitAndLetterAndSymbolsRegExpMessage,
  removeNbsp,
} from '@shared/utils/yup';

export const subtitleSchema = yup
  .string()
  .transform(removeNbsp)
  .textValueMax(20, 'Максимальная длина 20 символов')
  .matchStripHtmlValue(DigitAndLetterAndSymbolsRegExp, DigitAndLetterAndSymbolsRegExpMessage);
