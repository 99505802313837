import {AxiosInstance, AxiosRequestConfig} from 'axios';
import {tryCatch} from 'fp-ts/TaskEither';
import {ResponseData, ResponseError} from '../../../api';

const UNKNOWN_ERROR = {
  status: null,
  error: {message: 'Что-то пошло не так. Попробуйте ещё раз', code: 'Unknown'},
};

const knownErrorFrom = (error: any): ResponseError => {
  const status = 'response' in error && 'status' in error.response ? error.response.status : null;

  if ('error' in error && 'message' in error.error && 'code' in error.error) {
    return {error: error.error, status};
  }

  if (
    error.response?.data &&
    typeof error.response?.data === 'object' &&
    'errorType' in error.response.data
  ) {
    return {
      status,
      error: error.response.data,
    };
  }

  if (error instanceof Error) {
    return {
      status,
      error: {
        message: error.message,
        code: 'Unknown',
      },
    };
  }

  return UNKNOWN_ERROR;
};

export const withTaskEither = (apiClient: AxiosInstance) => {
  return <D>(url: string, options: AxiosRequestConfig) => {
    return tryCatch<ResponseError, ResponseData<D>>(
      () =>
        apiClient.request({...options, url}).then(response => {
          return {status: response.status, data: response.data};
        }),
      knownErrorFrom
    );
  };
};

export type RequestAPI = ReturnType<typeof withTaskEither>;
