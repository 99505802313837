import React, {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Field} from '../../ui/field';
import {ElementWithField} from '../../model/interface';
import {ButtonConfiguration} from '../../configuration/buttonConfiguration';
import {SIDEBAR_BUTTON_ELEMENT} from '@shared/dataTestId';

export const ButtonElement: FC<ElementWithField> = ({control, configuration, fieldName}) => {
  return (
    <Field
      node={
        <Controller
          name={fieldName}
          control={control}
          render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
            <ButtonConfiguration
              data-testid={SIDEBAR_BUTTON_ELEMENT}
              button={value}
              onChange={onChange}
              invalid={invalid}
              error={error?.message}
            />
          )}
        />
      }
      configuration={configuration}
      field={fieldName}
    />
  );
};
