import * as Yup from 'yup';
import {ERROR_MESSAGES} from '@shared/utils/messages';

type UrlOptions = {
  /** @default {false} */
  forceWithProtocol?: boolean;
};

/**
 * Regex got from Yup source-code, changing the protocol to be optional
 * @link {https://github.com/jquense/yup/blob/v1.0.0-beta.8/src/string.ts#L20}
 */
const URL =
  // eslint-disable-next-line no-useless-escape -- no warnings as it's copy/paste.
  /^(((https):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

// Override the original URL validation
Yup.addMethod(Yup.string, 'url', function (message, options: UrlOptions = {}) {
  let validation = this.matches(URL, message);

  if (!options.forceWithProtocol) {
    return validation;
  }

  return validation.matches(/^(https:\/\/)/, message);
});

declare module 'yup' {
  interface StringSchema {
    /** @override */
    url(message: string, options?: UrlOptions): this;
  }
}

Yup.addMethod(Yup.string, 'textValueMax', function (maxLength, errorMessage) {
  return this.test(`text-value-max`, errorMessage, function (value = '') {
    const {path, createError} = this;
    return stripHtml(value).length <= maxLength || createError({path, message: errorMessage});
  });
});

Yup.addMethod(Yup.string, 'matchStripHtmlValue', function (regex: RegExp, errorMessage: string) {
  return this.test(`match-strip-html-value`, errorMessage, function (value = '') {
    const regexp = new RegExp(regex);
    const {path, createError} = this;
    const val = stripHtml(value)?.trim();
    const isMatch = regexp.test(val);
    if (!isMatch) {
      return createError({path, message: errorMessage});
    }
    return true;
  });
});

Yup.addMethod(
  Yup.string,
  'passwordRequirements',
  function (errorMessage: string = ERROR_MESSAGES.passwordRequirement) {
    return this.test('passwordRequirements', errorMessage, function (value) {
      if (!value) {
        return true;
      }
      const {path, createError} = this;

      const hasUpper = /[A-Z]/.test(value);
      const hasLower = /[a-z]/.test(value);
      const hasNumbers = /\d/.test(value);
      const hasSpecial = /[#$%&()-_`|~]{}]/.test(value);

      const result =
        [hasUpper, hasLower, hasNumbers, hasSpecial].filter(item => item === true).length > 2;

      if (!result) {
        return createError({path, message: errorMessage});
      }
      return result;
    });
  }
);

Yup.addMethod(
  Yup.string,
  'validateDomain',
  function (errorMessage: string = ERROR_MESSAGES.domain) {
    return this.test('validateDomain', errorMessage, function (value) {
      if (!value) {
        return true;
      }
      const {path, createError} = this;

      const [firstLevel, ...otherLevels] = value.split('.').reverse();

      if (!ruOrEngDomainRegex.test(value)) {
        return createError({path, message: errorMessage});
      }

      if (!domainFirstLevelRegex.test(firstLevel)) {
        return createError({path, message: errorMessage});
      }

      if (otherLevels.some(level => !domainOtherLevelsRegex.test(level))) {
        return createError({path, message: errorMessage});
      }

      return true;
    });
  }
);

//а-Я, a-Z.
export const LetterRegExp = /^([\sA-Z[a-zЁА-яё-]+|^$)$/gm;
export const LetterRegExpMessage =
  'Допускается использование символов а-Я, a-Z и использование пробелов';

//(!?.,"':+;-) а-Я, a-Z. 0-9 или пустая строка
export const DigitAndLetterAndSymbolsRegExp = /^([\d\s!"'()+,.:;?A-Za-zЁА-яё\--]+|^(?=\s*$))$/gm;
export const EmailRegexp = /^([\w.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,4})|^(?=\s*$)$/;
export const DigitAndLetterAndSymbolsRegExpMessage =
  'Допускается использование символов а-Я, a-Z. 0-9 (!?.,"\':+;-) и использование пробелов.';

//phone
export const PhoneNumberRegExp = /[\d ()+-]+|^$/gm;
export const PhoneNumberRuRegExp = /^\+\d{11}$/gm;
export const PhoneNumberRegExpMessage = 'Корректно введите номер телефона';

export const stripHtml = (value: string | undefined) => value?.replace(/(<([^>]+)>)/gi, '') ?? '';
export const removeNbsp = (value: string | undefined) => {
  return value?.replace(/&nbsp;/gi, ' ');
};

export const domainFirstLevelRegex = /^([ЁА-яё]{2,63}|[A-z]{2,63})$/;
export const domainOtherLevelsRegex =
  /^(([\dA-Za-z][\dA-Za-z-]{0,61}[\dA-Za-z])|([\dЁА-яё][\dЁА-яё-]{0,61}[\dЁА-яё]))$/;

const ruOrEngDomainRegex =
  /^((([\dЁА-яё-]{2,63})(?:\.[\dЁА-яё-]{2,63})+)|(([\dA-Za-z-]{2,63})(?:\.[\dA-Za-z-]{2,63})+))$/;
