import {FC, ReactElement, ReactNode, useLayoutEffect, useRef} from 'react';
import clsx from 'clsx';

interface AccordionItemProps {
  summary: ReactNode | string;
  details: ReactElement;
  invalid?: boolean;
}

export const AccordionItem: FC<AccordionItemProps> = ({summary, details, invalid}) => {
  const ref = useRef<HTMLDivElement | null>();

  useLayoutEffect(() => {
    const hasChildren = ref.current?.querySelectorAll(':scope > details.group > p > *');
    if (hasChildren?.length === 0) {
      ref.current?.setAttribute('hidden', '');
    }
  }, [summary, details, invalid]);

  return (
    <div ref={e => (ref.current = e)} className="py-5">
      <details className="group">
        <summary className="flex justify-between items-center font-mulish-semibold text-base cursor-pointer list-none">
          <span
            className={clsx(!invalid && 'group-open:text-indigo-500', invalid && 'text-red-500')}
          >
            {' '}
            {summary}{' '}
          </span>
          <span className="transition group-open:rotate-180 group-open:text-indigo-500">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <p className="mt-3 group-open:animate-fadeIn">{details}</p>
      </details>
    </div>
  );
};
