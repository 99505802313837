import {useMemo} from 'react';

import {NavContainer} from './styles/navContainer';
import {Nav} from './styles/nav';
import {Button} from './styles/button';
import {Props} from './interface';

export const Tabs: React.FC<Props> = ({tabsGroupName, active, other}) => {
  const items = useMemo(
    () => [active, ...other].sort((a, b) => a.order - b.order),
    [active, other]
  );

  return (
    <div>
      <NavContainer>
        <Nav aria-label="Tabs" role="tablist">
          {items.map(item => (
            <Button
              type="button"
              key={`${tabsGroupName}-tab-name-${item.order}`}
              id={`${tabsGroupName}-item-${item.order}`}
              data-hs-tab={`#${tabsGroupName}-${item.order}`}
              aria-controls={`#${tabsGroupName}-${item.order}`}
              role="tab"
              className={`${item.type === 'active' ? 'active' : ''}`}
            >
              {item.name}
            </Button>
          ))}
        </Nav>
      </NavContainer>
      <div>
        {items.map(item => (
          <div
            key={`${tabsGroupName}-tab-content-${item.order}`}
            id={`${tabsGroupName}-${item.order}`}
            role="tabpanel"
            aria-labelledby={`${tabsGroupName}-item-${item.order}`}
            className={`${item.type === 'active' ? '' : 'hidden'}`}
          >
            {item.content}
          </div>
        ))}
      </div>
    </div>
  );
};
