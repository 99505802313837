import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';

import {TextElement} from '../../elements/textElement';
import {ReviewsElement} from '../../elements/reviewsElement';
import {SeoElement} from '../../elements/seoElement';
import {BlockConfigurationContentProps} from '../../model/interface';
import {reviewsBlockConfigurationSchema} from './schema';

import {ReviewsBlockConfiguration} from '@shared/api';
import {Accordion, AccordionItem} from '@shared/ui/accordion';

export const ReviewsBlockConfigurationContent: React.FC<
  BlockConfigurationContentProps<ReviewsBlockConfiguration>
> = ({configuration, onSubmit}) => {
  const onValidData: SubmitHandler<Partial<ReviewsBlockConfiguration>> = data => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
  } = useForm<Partial<ReviewsBlockConfiguration>>({
    mode: 'onChange',
    resolver: yupResolver(reviewsBlockConfigurationSchema),
    defaultValues: {
      ...configuration,
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <TextElement
        label="Заголовок"
        fieldName="title"
        control={control}
        configuration={configuration}
      />
      <TextElement
        label="Описание"
        fieldName="description"
        control={control}
        configuration={configuration}
      />

      <Accordion>
        <AccordionItem
          invalid={Boolean(errors.reviews)}
          summary="Отзывы"
          details={<ReviewsElement control={control} configuration={configuration} />}
        />
        <AccordionItem
          invalid={Boolean(errors.titleTag)}
          summary="SEO"
          details={<SeoElement control={control} configuration={configuration} />}
        />
      </Accordion>
    </>
  );
};
