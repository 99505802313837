import {ImageInfo} from '@shared/api';

export interface Image {
  url: string;
  file: File | null;
}

export interface Img {
  imageInfo: ImageInfo | null;
  croppedImage: Image;
  image: Image;
}

export const initialState: Img = {
  croppedImage: {file: null, url: ''},
  image: {file: null, url: ''},
  imageInfo: null,
};
