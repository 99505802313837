import {ServiceCardObj} from '../model/serviceCard';
import {ReactComponent as SmileIcons} from '@shared/images/icons/smile.svg';
import {ReactComponent as WalletIcon} from '@shared/images/icons/wallet2.svg';
import {ServiceCard} from './serviceCard';
import {FC} from 'react';

const libraries: ServiceCardObj[] = [
  {
    href: 'https://unsplash.com/',
    textHref: 'unsplash.com',
    imageHref: '/unsplash.png',
    title: 'Unsplash',
    description:
      'Популярная библиотека фотографий в высоком разрешении. Миллионы изображений от фотографов со всего мира',
    payment: (
      <>
        <SmileIcons /> Бесплатно
      </>
    ),
  },
  {
    href: 'https://icons8.ru/',
    textHref: 'icons8.ru',
    imageHref: '/Icons8.png',
    title: 'Icons8',
    description:
      'Авторские фотографии, иллюстрации и иконки. Бесплатно доступны в низком разрешении с обязательным указанием ссылки на ресурс',
    payment: (
      <>
        <WalletIcon /> Бесплатно и Платно
      </>
    ),
  },
];

export const ImageLibrary: FC = () => {
  return (
    <div className="flex justify-center gap-4">
      {libraries.map(lib => (
        <ServiceCard key={lib.title} {...lib} />
      ))}
    </div>
  );
};
