import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {dataOf, errorOf, initialOf} from 'iled/dist/constructors';
import {map, of} from 'fp-ts/NonEmptyArray';
import {none, some} from 'fp-ts/Option';
import {isNonEmpty} from 'fp-ts/Array';
import {createSelectorHooks} from 'auto-zustand-selectors-hook';

import {fromILED, fromOption} from '@shared/lib/utils';
import {WebhookInfo} from '@shared/api';
import {httpClient} from '@shared/apiClient/lib/axios';

import {WebhookState} from '../model/interface';

const emptyWebhook: WebhookInfo = {name: undefined, url: ''};

const useWebhookStoreBase = create<WebhookState>()(
  devtools((set, get) => ({
    webhooks: initialOf(null),
    actions: {
      add: () => {
        const webhooksOption = fromILED(get().webhooks);
        if (webhooksOption?._tag === 'Some') {
          webhooksOption.value.push(emptyWebhook);
          set({
            webhooks: dataOf(webhooksOption),
          });
        } else {
          set({
            webhooks: dataOf(some(of(emptyWebhook))),
          });
        }
      },
    },
    effects: {
      remove: async (domainId, itemId) => {
        if (!domainId) {
          return;
        }
        const result = await httpClient.domain.deleteWebhook(domainId, itemId);
        if (result._tag === 'Left') {
          return;
        }
        const webhooks = fromOption(fromILED(get().webhooks));
        if (webhooks) {
          const nextWebhooks = webhooks.filter(webhook => webhook.id !== itemId);
          if (isNonEmpty(nextWebhooks)) {
            set({
              webhooks: dataOf(some(nextWebhooks)),
            });
          } else {
            set({
              webhooks: dataOf(none),
            });
          }
        }
      },
      create: async (domainId, item) => {
        if (!domainId) {
          return null;
        }
        const result = await httpClient.domain.addWebhook(domainId, {webhook: item});
        if (result._tag === 'Left') {
          return null;
        }
        const webhook = result.right.data;
        const webhooksOption = fromILED(get().webhooks);

        if (webhooksOption?._tag === 'Some') {
          const webhooks = fromOption(webhooksOption);
          if (webhooks) {
            const filtered = webhooks.filter(value => value.id);
            if (isNonEmpty(filtered)) {
              filtered.push(webhook);
              set({
                webhooks: dataOf(some(filtered)),
              });
            }
            return item;
          }
        }
        set({
          webhooks: dataOf(some(of(webhook))),
        });
        return item;
      },
      update: async (domainId, item) => {
        if (!item.id || !domainId) {
          return null;
        }
        const result = await httpClient.domain.updateWebhook(domainId, item.id, {webhook: item});
        if (result._tag === 'Left') {
          return null;
        }
        const webhooks = fromOption(fromILED(get().webhooks));
        if (webhooks) {
          const webhookMapper = map((webhook: WebhookInfo) =>
            webhook.id === item.id ? item : webhook
          );
          const nextWebhooks = webhookMapper(webhooks);
          set({
            webhooks: dataOf(some(nextWebhooks)),
          });
        }
        return item;
      },
      fetch: async domainId => {
        if (!domainId) {
          return [];
        }
        const result = await httpClient.domain.listWebhooks(domainId);
        if (result._tag === 'Left') {
          if (result.left.status === 404) {
            set({
              webhooks: dataOf(none),
            });
            return [];
          }
          set({
            webhooks: errorOf('Error while .fetch webhooks'),
          });
          return [];
        }
        if (isNonEmpty(result.right.data)) {
          set({
            webhooks: dataOf(some(result.right.data)),
          });
          return [];
        }
        set({
          webhooks: dataOf(none),
        });
        return result.right.data;
      },
    },
  }))
);

export const useWebhookStore = createSelectorHooks(useWebhookStoreBase);
