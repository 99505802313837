import * as yup from 'yup';
import {imageElementSchema} from '../imageElement/schema';
import {titleSchema} from '../textElement/titleSchema';
import {removeNbsp} from '@shared/utils/yup';

export const reviewInfoSchema = yup.object({
  title: titleSchema.optional().nullable().default(undefined),
  text: yup
    .string()
    .transform(removeNbsp)
    .textValueMax(350, 'Максимальная длина 350 символов')
    .optional()
    .nullable()
    .default(undefined),
  image: imageElementSchema.optional().nullable().default(undefined),
  authorName: yup
    .string()
    .transform(removeNbsp)
    .textValueMax(80, 'Максимальная длина 80 символов')
    .optional()
    .nullable()
    .default(undefined),
  authorDescription: yup
    .string()
    .transform(removeNbsp)
    .textValueMax(30, 'Максимальная длина 30 символов')
    .optional()
    .nullable()
    .default(undefined),
});

export const reviewsElementSchema = yup
  .array(reviewInfoSchema)
  .max(12, 'Максимальное количество загружаемых изображений - 12')
  .nullable()
  .optional()
  .default(undefined);
