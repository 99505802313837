import React, {FC} from 'react';
import {ElementComponent} from '../../model/interface';
import {Field} from '../../ui/field';
import {Form} from '../../components/form/form';
import {Controller} from 'react-hook-form';

export const FormElement: FC<ElementComponent> = ({control, configuration}) => {
  return (
    <Field
      node={
        <Controller
          name="form"
          control={control}
          render={({field: {onChange, value}}) => (
            <Form
              blockGroup={configuration.group}
              blockType={configuration.type}
              form={value}
              onChange={onChange}
            />
          )}
        />
      }
      configuration={configuration}
      field="form"
    />
  );
};
