import React, {FC, useState} from 'react';
import {SubmitHandler} from 'react-hook-form';

import {BlockEditorPortal} from '../blockEditorPortal';

import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';
import {NetworkItem} from '@shared/api/api';
import {useToggle} from '@shared/hooks';
import {DraggableContainer} from '@shared/draggable';
import {Button, Caption} from '@shared/ui';

import {NetworksProps} from './model';
import {getNetworkLabelByType, saveNetworkHref} from './utils';
import {NetworkConfiguration} from './NetworkConfiguration';

import {DraggableBullet} from '../../ui/item';
import {SettingsContainer} from '../../ui/settingsContainer';

export const Networks: FC<NetworksProps> = ({
  items = [],
  onChange: onChangeHandler,
  invalid,
  error,
}) => {
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const removeHandler = (itemIndex: number) => () =>
    onChangeHandler(items.filter((item, index) => index !== itemIndex));

  const onReorderHandler = (reorderedItems: unknown[]) => {
    onChangeHandler(reorderedItems as NetworkItem[]);
  };
  const onAddMenu = () => {
    onChangeHandler([...items, {href: 'https://t.me/', networkType: 'TELEGRAM', hrefType: 'LINK'}]);
    setActiveItemIndex(items.length);
    toggleSettings();
  };

  const settingsHandler = (item: NetworkItem, index: number) => () => {
    toggleSettings();
    setActiveItemIndex(index);
  };

  const activeItem = items[activeItemIndex] || {};

  const onValidData: SubmitHandler<NetworkItem> = data => {
    const dataWithMatchHref: NetworkItem = {...data, href: saveNetworkHref(data)};
    onChangeHandler(
      items.map((item, index) => {
        if (index === activeItemIndex) {
          return {...dataWithMatchHref};
        }
        return item;
      })
    );
  };

  return (
    <>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
      <DraggableContainer items={items} onChange={onReorderHandler}>
        {items.map((item, index) => (
          <DraggableBullet
            data-testid={`network-bullet-${index}`}
            key={index.toString()}
            active={false}
            main={false}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item, index)}
            onRemove={removeHandler(index)}
          >
            {getNetworkLabelByType(item.networkType)}
          </DraggableBullet>
        ))}
      </DraggableContainer>
      <Button onClick={onAddMenu} className="w-full" color="gray" variant="ghost" size="small">
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить ссылку
      </Button>
      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer
          backLabel="Обратно к ссылкам на соцсети"
          backHandler={() => toggleSettings()}
        >
          <NetworkConfiguration activeItem={activeItem} onSubmit={onValidData} />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
