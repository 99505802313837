import {Dropdown, DropdownContent, DropdownItem} from '../../../shared/ui/dropdown';
import {IconButton} from '../../../shared/ui/iconButton';
import {ReactComponent as VerticalDotsIcon} from '../../../shared/images/icons/vertical-dots.svg';
import React, {FC} from 'react';

interface ActionProps {
  onRemove: () => void;
}

export const Action: FC<ActionProps> = ({onRemove}) => {
  return (
    <Dropdown position="relative">
      <IconButton
        id="hs-dropdown-default"
        className="hs-dropdown-toggle border-0 shadow-none"
        size="small"
        variant="white"
        color="indigo"
      >
        <VerticalDotsIcon width={4} />
      </IconButton>

      <DropdownContent>
        <DropdownItem onClick={onRemove}>
          <div className="text-red-500">Удалить сайт</div>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
