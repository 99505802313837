import {FC} from 'react';
import {ReactComponent as SmileIcons} from '@shared/images/icons/smile.svg';
import {ServiceCard} from './serviceCard';

export const GenerateImage: FC = () => {
  return (
    <ServiceCard
      imageHref="/kandinskiy.png"
      href="https://www.sberbank.com/promo/kandinsky/"
      textHref="sberbank.com/promo/kandinsky/"
      description=" Оцени возможности искусственного интеллекта! Создавай изображения по
                            тексту вместе с нейросетью"
      payment={
        <>
          <SmileIcons /> Бесплатно{' '}
        </>
      }
      title="Kandinsky 2.1"
    />
  );
};
