import * as yup from 'yup';
import {ImageBase} from '@shared/api';
import {imageElementSchema} from '../imageElement/schema';
import {htmlReplaceRegex} from '@shared/lib/utils';

export const logoSchema = yup.object().shape(
  {
    name: yup
      .string()
      .transform(value => value?.replace(htmlReplaceRegex, ''))
      .max(50, 'Максимум 50 символов')
      .when('image', {
        is: (value: ImageBase) => value === undefined || value === null,
        then: schema => schema.required('Заполните одно из полей'),
        otherwise: schema => schema.nullable().optional().default(undefined),
      }),
    image: imageElementSchema.when('name', {
      is: (value: string) => value === undefined || value === null,
      then: schema => schema.required('Заполните одно из полей'),
      otherwise: schema => schema.nullable().optional().default(undefined),
    }),
  },
  [['name', 'image']]
);
