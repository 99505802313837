import {UploadFile} from '@shared/ui/uploadInput/uploadFile';
import {FC} from 'react';
import {ImagePreview} from '@shared/imagePreview/imagePreview';
import {ImageHandler} from '../model/image';
import {SpinnerBlock} from './spinnerBlock';

interface UploadLocalProps {
  onChange: ImageHandler;
  image: string;
  loading: boolean;
  handleLoading: (loading: boolean) => void;
}

export const UploadLocal: FC<UploadLocalProps> = ({onChange, image, loading, handleLoading}) => {
  const onUploadHandler = (file: File[]) => {
    handleLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.addEventListener('load', () => {
      onChange({file: file[0], dataUrl: reader.result as string});
    });
  };
  const onRemoveHandler = () => {
    onChange({file: null, dataUrl: ''});
  };

  let content = image ? (
    <ImagePreview image={image} onRemove={onRemoveHandler} />
  ) : (
    <UploadFile onUpload={onUploadHandler} />
  );

  if (loading) {
    content = <SpinnerBlock />;
  }

  return <div className="mt-4">{content}</div>;
};
