import React, {FC, useEffect, useState} from 'react';
import {TWebhookEditMode, WebhooksProps} from '../model/interface';
import clsx from 'clsx';
import {WebhookInfos} from './webhookInfos';
import {WebhookEditable} from './webhookEditable';

export const Webhook: FC<WebhooksProps> = ({item, onChange, editMode, className, onRemove}) => {
  const [edit, setEdit] = useState<TWebhookEditMode>(editMode ?? null);

  const onEdit = () => {
    setEdit('edit');
  };

  useEffect(() => {
    if (!item.id) {
      setEdit('create');
    }
  }, [item]);

  const onChangeHandler: WebhooksProps['onChange'] = async (webhook, mode) => {
    await onChange(webhook, mode);
    setEdit(null);
  };

  let content;
  if (edit === 'create' || edit === 'edit') {
    content = <WebhookEditable item={item} onChange={onChangeHandler} editMode={edit} />;
  }
  if (edit === null) {
    content = <WebhookInfos item={item} onEdit={onEdit} onRemove={() => onRemove(item.id)} />;
  }

  return <div className={clsx('bg-gray-100 relative p-4 text-sm', className)}>{content}</div>;
};
