import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';

import {InputStyleProps} from '../interface';

export const CustomInput = tw.input<InputStyleProps>`
    ${props => (props.invalid ? 'border-red-500' : 'border-gray-200')}
    ${props => (props.invalid ? 'hover:border-red-600' : 'hover:border-gray-300')}
    ${props => (props.invalid ? 'focus:border-red-600' : 'focus:border-indigo-500')}
    ${props => (props.startIcon ? 'px-4 pl-7' : 'px-4')}
    disabled:text-gray-500
    w-full
    py-2.5
    border
    rounded-sm
    text-sm
    text-gray-800
    font-mulish-medium
    focus:ring-offset-0
    focus:ring-0
    focus:outline-offset-0
    outline-0
`;

export const CustomPhoneInput = styled(PhoneInput)<InputStyleProps>`
  & .PhoneInputCountry {
    display: none;
  }

  & .PhoneInputInput {
    border-color: ${props => (props.invalid ? 'rgb(239 68 68)' : 'rgb(229 231 235)')};
    outline-offset: 0px;
    width: 100%;
    padding: 10px 16px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 20px;
    color: rgb(31 41 55);
  }

  & .PhoneInputInput:focus {
    border-color: ${props => (props.invalid ? 'rgb(220 38 38)' : 'rgb(99 102 241)')};
    --tw-ring-offset-width: 0px;
    box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow);
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
      var(--tw-ring-color);
    outline-offset: 0px;
  }

  & .PhoneInputInput:hover {
    border-color: ${props => (props.invalid ? 'rgb(220 38 38)' : 'rgb(209 213 219)')};
  }
`;
