import * as yup from 'yup';
import {ButtonInfo} from '@shared/api';
import {buttonTitleSchema} from '../textElement/buttonTitleSchema';

export const buttonInfoConfigurationSchema = yup.object({
  buttonClass: yup.string<ButtonInfo['buttonClass']>().required(),
  title: buttonTitleSchema.required('Поле обязательно для заполнения'),
  href: yup.string().when('linkType', {
    is: 'EXTERNAL',
    // eslint-disable-next-line unicorn/no-thenable
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  blockId: yup.string().when('linkType', {
    is: 'ANCHOR',
    // eslint-disable-next-line unicorn/no-thenable
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  pageId: yup.string().when('linkType', {
    is: 'INTERNAL',
    // eslint-disable-next-line unicorn/no-thenable
    then: schema => schema.required('Поле обязательно для заполнения'),
    otherwise: schema => schema.nullable(),
  }),
  linkType: yup.string<ButtonInfo['linkType']>().required(),
});
