import {BlockInfo} from '../../../shared/api';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {useEffect} from 'react';
import * as yup from 'yup';

interface useBlockContentFormProps {
  onSubmitSuccess: (data: Partial<BlockInfo['configuration']>) => void;
  onSubmitInvalid?: () => void;
  defaultValues: Partial<BlockInfo['configuration']>;
  schema: yup.ObjectSchema<any>;
}

export const useBlockContentForm = (props: useBlockContentFormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<Partial<BlockInfo['configuration']>>({
    defaultValues: {
      ...props.defaultValues,
    },
    mode: 'onChange',
    resolver: yupResolver(props.schema),
  });

  const onValidData: SubmitHandler<Partial<BlockInfo['configuration']>> = data => {
    props.onSubmitSuccess(data);
  };

  const onInvalid: SubmitErrorHandler<Partial<BlockInfo['configuration']>> = () => {
    if (props.onSubmitInvalid) {
      props.onSubmitInvalid();
    }
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onValidData, onInvalid)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return {control, errors};
};
