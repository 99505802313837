import React, {useState} from 'react';

import {BlockEditorPortal} from '../blockEditorPortal';

import {DraggableContainer} from '@shared/draggable';
import {Caption, Button} from '@shared/ui';
import {ReactComponent as PlusIcon} from '@shared/images/icons/thin-plus.svg';
import {useToggle} from '@shared/hooks';
import {DropDownItem} from '@shared/api';

import {FaqInfoConfiguration} from '../../configuration/faqInfoConfiguration/faqInfoConfiguration';
import {SettingsContainer} from '../../ui/settingsContainer';
import {DraggableBullet} from '../../ui/item';
import {MAX_COUNT_DROPDOWN_ITEMS} from '@entities/blockContentConfiguration/content/textBlockConfigurationContent/schema';

interface FaqProps {
  items: DropDownItem[] | undefined;
  onChange: (items: DropDownItem[]) => void;
  invalid: boolean;
  error?: string;
}

export const Faq: React.FC<FaqProps> = ({items = [], onChange, error, invalid}) => {
  const [isSettingsOpen, toggleSettings] = useToggle();
  const [activeFaqIndex, setActiveFaqIndex] = useState<number>(0);

  const removeHandler = (itemIndex: number) => () =>
    onChange(items.filter((item, index) => index !== itemIndex));

  const onReorderHandler = (reorderedItems: unknown[]) => {
    onChange(reorderedItems as DropDownItem[]);
  };
  const onAddFaq = () => {
    onChange([
      ...items,
      {
        value: undefined,
        title: undefined,
      },
    ]);
    setActiveFaqIndex(items.length);
    toggleSettings();
  };

  const settingsHandler = (item: DropDownItem, index: number) => () => {
    toggleSettings();
    setActiveFaqIndex(index);
  };

  const onChangeHandler = (link: DropDownItem) => {
    onChange(
      items.map((item, index) => {
        if (index === activeFaqIndex) {
          return link;
        }
        return item;
      })
    );
  };

  const activeFaq = items[activeFaqIndex] || {};

  return (
    <>
      {invalid && error && <Caption color="text-red-500">{error}</Caption>}
      <DraggableContainer items={items} onChange={onReorderHandler}>
        {items.map((item, index) => (
          <DraggableBullet
            data-testid={`faq-bullet-${index}`}
            key={index.toString()}
            active={false}
            main={false}
            item={item}
            onClick={() => null}
            onSettings={settingsHandler(item, index)}
            onRemove={removeHandler(index)}
          >
            {item.title}
          </DraggableBullet>
        ))}
      </DraggableContainer>
      <Button
        disabled={items.length > MAX_COUNT_DROPDOWN_ITEMS - 1}
        onClick={onAddFaq}
        className="w-full"
        color="gray"
        variant="ghost"
        size="small"
      >
        <PlusIcon width="16" height="16" className="mr-2" />
        Добавить пункт
      </Button>
      <BlockEditorPortal open={isSettingsOpen}>
        <SettingsContainer
          backLabel="Обратно к настройкам блока"
          backHandler={() => toggleSettings()}
        >
          <FaqInfoConfiguration item={activeFaq} onSubmit={onChangeHandler} />
        </SettingsContainer>
      </BlockEditorPortal>
    </>
  );
};
