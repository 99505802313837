import {FC} from 'react';
import {useSnackbarStore} from '@entities/snackbar/store/snackbar';
import {Snack} from './snack';
import clsx from 'clsx';

export const Snackbar: FC<{autoHideDuration?: number}> = ({autoHideDuration}) => {
  const messages = useSnackbarStore.useQueue();
  const dequeue = useSnackbarStore.useDequeue();

  const onClose = (id: string) => () => dequeue(id);
  return (
    <div className={clsx('fixed bottom-5 p-2 w-screen z-[100]', messages.length <= 0 && 'hidden')}>
      {messages.map(message => (
        <Snack
          autoHide={autoHideDuration}
          key={message.id}
          variant={message.variant}
          message={message.message}
          id={message.id}
          onClose={onClose(message.id)}
        />
      ))}
    </div>
  );
};
