import {FC, ReactNode} from 'react';
import {StyledNavPill} from './styledNavPill';
import {
  Content,
  EndIconContainer,
  StartIconContainer,
  TextContainer,
} from '@entities/blockContentConfiguration/ui/item/ui';

interface NavPillProps {
  active?: boolean;
  onClick?: () => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
}

export const NavPill: FC<NavPillProps> = ({
  onClick,
  active,
  startIcon,
  endIcon,
  children,
  disabled,
}) => {
  return (
    <StyledNavPill
      disabled={disabled}
      className="my-1 mx-0 w-full"
      onClick={onClick}
      active={active}
    >
      <Content>
        <StartIconContainer>{startIcon}</StartIconContainer>
        <TextContainer>{children}</TextContainer>
        <EndIconContainer>{endIcon}</EndIconContainer>
      </Content>
    </StyledNavPill>
  );
};
